import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const AddTechnicianModal = ({ isVisible, onClose, onAdd, form, stateList }) => {
  const [addfileAadhar, setAddFileAadhar] = useState(null);
  const [addfilePan, setAddFilePan] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const handleCancel = () => {
    form.resetFields();
    setAddFilePan(null);
    setAddFileAadhar(null);
    onClose();
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
  };

  const handleFinish = (values) => {
    onAdd(values, addfileAadhar, addfilePan, selectedState);
    // console.log("values", values, addfileAadhar, addfilePan);
    form.resetFields(); // Clear the form after a successful submission
  };

  // Check if the button should be disabled based on form validation

  return (
    <Modal
      title="Add Technician"
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
    >
      <p>NOTE: Fill all require fields then click on Add Technician.</p>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item
          name="technicianName"
          label="Technician Name(Always unique from others Technician)"
          rules={[{ required: true, min: 3 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mobileNo"
          label="Mobile No.(enter with out country-code if indian)"
          rules={[{ required: true, min: 10, max: 12 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="state"
          label="State"
          rules={[{ required: true, message: "Please select a state" }]}
        >
          <Select
            placeholder="Select State"
            style={{ width: 200, marginRight: 10 }}
            onChange={handleStateChange}
            value={selectedState || undefined} // Set value to undefined if no state is selected
            showSearch // Enable the search functionality
            filterOption={
              (input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase()) // Filter options by the input value
            }
          >
            <Select.Option key="no-filter" value="">
              ---Select State---
            </Select.Option>
            {stateList.map((state) => (
              <Select.Option key={state} value={state}>
                {state}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="city"
          label="City(Please Enter Right Spelling Of City)"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="address" label="Address" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="pincode" label="Pincode" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Aadhar Card"
          name="aadharcard"
          rules={[{ required: false }]}
        >
          <Upload
            beforeUpload={() => false}
            onChange={(info) => setAddFileAadhar(info.file)}
          >
            <Button icon={<UploadOutlined />}>Upload Aadhar Card</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="PAN Card"
          name="pancard"
          rules={[{ required: false }]}
        >
          <Upload
            beforeUpload={() => false}
            onChange={(info) => setAddFilePan(info.file)}
          >
            <Button icon={<UploadOutlined />}>Upload PAN Card</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            style={{
              backgroundColor: "rgb(22 78 99)",
              color: "white",
              borderColor: "rgb(59 130 246 / 50%)",
              marginRight: "20px",
            }}
            htmlType="submit"
          >
            Add Technician
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTechnicianModal;
