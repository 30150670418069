import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsDownload } from "react-icons/bs";
import { TbNotes } from "react-icons/tb";
import { SiPolywork } from "react-icons/si";
import { CSVLink } from "react-csv";
import ComplaintAnalysis from "./ComplaintAnalysis";
import { RiProductHuntLine, RiCollageLine } from "react-icons/ri";
import { MdOutlineDateRange, MdOutlineAnalytics } from "react-icons/md";
import { GrStatusPlaceholder } from "react-icons/gr";

const SideBar = (props) => {
  const [dashboard, setDashboard] = useState("");
  const [product, setProduct] = useState("");
  const [performance, setPerformance] = useState(" ");
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [complaintAnalysis, setComplaintAnalysis] = useState(false);
  const history = useNavigate();

  const addSerialNo = (toConvertData) => {
    var data = toConvertData.map((item) => ({
      ...item,
      plumbingNo: `"${item.plumbingNo}"`, // convert value to string and add quotes
    }));
    let finalData = [];
    let count = 1;
    data.map((item) => {
      finalData.push(Object.assign(item, { srNo: count++ }));
    });
    return finalData;
  };

  let headers = [
    { label: "NO", key: "srNo" },
    { label: "DATE", key: "date" },
    { label: "CUSTOMER NAME", key: "partyName" },
    { label: "ADDRESS", key: "address" },
    { label: "PINCODE", key: "pincode" },
    { label: "STATE", key: "state" },
    { label: "CITY", key: "city" },
    { label: "MOBILE NO", key: "mobileNo" },
    { label: "BRAND NAME", key: "brandName" },
    { label: "TEHCHNICIAN NAME", key: "plumberName" },
    { label: "ENTRY REMARK", key: "entryRemark" },
    { label: "FORWARDING REMARK", key: "remark" },
    { label: "OFFICE NO", key: "plumbingNo" },
    { label: "STATUS", key: "workDone" },
    { label: "SYPHONE COLOR", key: "syphoneColor" },
    { label: "CODE", key: "code" },
    { label: "PAYMENT", key: "payment" },
    { label: "TOTAL PRODUCT", key: "problem" },
    { label: "TEHCHNICIAN WORK", key: "solution" },
    { label: "PART PENDING DETAILS", key: "partPendingDetails" },
    { label: "COURIER", key: "courier" },
    { label: "REPEAT", key: "repeat" },
    { label: "CLOSING DATE", key: "closingDate" },
  ];

  useEffect(() => {
    if (window.location.pathname === "/ideal-admin") {
      setDashboard("bg-white");
      setProduct("text-[#5C697B] hover:bg-white");
      setPerformance(" text-[#5C697B] hover:bg-white");
      setVisibleStatus(false);
      setComplaintAnalysis(true);
      props.setIsDashboard(true);
    } else if (window.location.pathname === "/view-complaints") {
      setDashboard("text-[#5C697B] hover:bg-white");
      setProduct("bg-white");
      setPerformance(" text-[#5C697B] hover:bg-white");
      setVisibleStatus(true);
      setComplaintAnalysis(false);
      props.setIsDashboard(false);
    } else if (
      window.location.pathname === "/users" ||
      window.location.pathname === "/users/addMember"
    ) {
      setDashboard("text-[#5C697B] hover:bg-white");
      setProduct("text-[#5C697B] hover:bg-white");
      setPerformance("bg-white");
      setComplaintAnalysis(false);
      setVisibleStatus(false);
      props.setIsDashboard(false);
    }
  }, [history]);

  return (
    <div>
      {/* Sidebar starts */}
      <div
        className="absolute overflow-y-scroll  overflow-hidden lg:relative  min-h-screen shadow bg-[#E2E8F0] hidden lg:block "
        style={{
          maxHeight: "580px",
          width:"280px",
          maxWidth: "300px",
          scrollbarWidth: "thin",
        }}
      >
        <ul
          aria-orientation="vertical"
          className="pt-2 -mt-2"
          style={{ maxHeight: "580px" }}
        >
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${dashboard} transition`}
          >
            <Link to={"/ideal-admin"}>
              <div className="flex items-center  ">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-grid"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <rect x={4} y={4} width={6} height={6} rx={1} />
                    <rect x={14} y={4} width={6} height={6} rx={1} />
                    <rect x={4} y={14} width={6} height={6} rx={1} />
                    <rect x={14} y={14} width={6} height={6} rx={1} />
                  </svg>
                </div>
                <span className="ml-2">Dashboard</span>
              </div>
            </Link>
          </li>
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${product} transition`}
          >
            <Link to={"/view-complaints"}>
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-puzzle"
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1" />
                </svg>
                <span className="ml-2">View Complaints</span>
              </div>
            </Link>
          </li>
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${performance} transition`}
          >
            <Link to={"/ideal-admin/analytics"} className="flex items-center">
              <MdOutlineAnalytics size={20} />
              <span className="ml-2">Analytics</span>
            </Link>
          </li>
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${performance} transition`}
          >
            <Link to={"/users"} className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-compass"
                width={20}
                height={20}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="8 16 10 10 16 8 14 14 8 16" />
                <circle cx={12} cy={12} r={9} />
              </svg>
              <span className="ml-2">Users</span>
            </Link>
          </li>
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${performance} transition`}
          >
            <Link
              to={"/ideal-admin/dateWiseComplaint"}
              className="flex items-center"
            >
              <MdOutlineDateRange size={20} />
              <span className="ml-2">Date Wise Complaint</span>
            </Link>
          </li>
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${performance} transition`}
          >
            <Link to={"/ideal-admin/state"} className="flex items-center">
              <RiCollageLine size={20} />
              <span className="ml-2">State</span>
            </Link>
          </li>
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${performance} transition`}
          >
            <Link to={"/ideal-admin/workStatus"} className="flex items-center">
              <GrStatusPlaceholder size={20} />
              <span className="ml-2">Work Status</span>
            </Link>
          </li>
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${performance} transition`}
          >
            <Link to={"/ideal-admin/product"} className="flex items-center">
              <RiProductHuntLine size={20} />
              <span className="ml-2">Product</span>
            </Link>
          </li>
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${performance} transition`}
          >
            <Link to={"/ideal-admin/remark"} className="flex items-center">
              <TbNotes size={20} />
              <span className="ml-2">Remark</span>
            </Link>
          </li>
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${performance} transition`}
          >
            <Link
              to={"/ideal-admin/technicianWork"}
              className="flex items-center"
            >
              <SiPolywork size={16} />
              <span className="ml-2">Technician Work</span>
            </Link>
          </li>
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${performance} transition`}
          >
            <Link
              to={"/ideal-admin/technicianDetails&transaction"}
              className="flex items-center"
            >
              <SiPolywork size={16} />
              <span className="ml-2">Technician Details & Transaction</span>
            </Link>
          </li>
        </ul>

        {/* {complaintAnalysis && (
          <div className="ml-3">
            <ComplaintAnalysis />
          </div>
        )} */}
        {visibleStatus && (
          <div
            className="relative mt-4 overflow-x-auto overflow-y-scroll w-64  text-white shadow-md sm:rounded-lg"
            style={{
              maxHeight: "300px",
              maxWidth: "280px",
              width: "270px",
              scrollbarWidth: "thin",
            }}
          >
            <table className=" table-fixed w-full">
              <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Count
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Export
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Open
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    {props.open}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        props.setOpenComplaints(
                          addSerialNo(props.openComplaints)
                        );
                      }}
                    >
                      <CSVLink data={props.openComplaints} headers={headers}>
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Visit Ok
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right ">
                    {props.visitOk}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        props.setVisitOkComplaintsFD(
                          addSerialNo(props.visitOkComplaintsFD)
                        );
                      }}
                    >
                      <CSVLink
                        data={props.visitOkComplaintsFD}
                        headers={headers}
                      >
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Ceramic Issue
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right ">
                    {props.ceramicIssue}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        props.setCeramicIssueComplaintsFD(
                          addSerialNo(props.ceramicIssueComplaintsFD)
                        );
                      }}
                    >
                      <CSVLink
                        data={props.ceramicIssueComplaintsFD}
                        headers={headers}
                      >
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Other Issue
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right ">
                    {props.otherIssue}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        props.setOtherIssueComplaintsFD(
                          addSerialNo(props.otherIssueComplaintsFD)
                        );
                      }}
                    >
                      <CSVLink
                        data={props.otherIssueComplaintsFD}
                        headers={headers}
                      >
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Part Pending
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right ">
                    {props.partPendingFD}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        props.setPartPendingComplaintsFD(
                          addSerialNo(props.partPendingComplaintsFD)
                        );
                      }}
                    >
                      <CSVLink
                        data={props.partPendingComplaintsFD}
                        headers={headers}
                      >
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Seat Change
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    {props.seatChangeFD}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        props.setSeatChangeComplaintsFD(
                          addSerialNo(props.seatChangeComplaintsFD)
                        );
                      }}
                    >
                      <CSVLink
                        data={props.seatChangeComplaintsFD}
                        headers={headers}
                      >
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Courier
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    {props.courierByCompanyCount}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        props.setSeatChangeComplaintsFD(
                          addSerialNo(props.courierByCompany)
                        );
                      }}
                    >
                      <CSVLink data={props.courierByCompany} headers={headers}>
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Closed
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    {props.closed}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        props.setClosedComplaintsFD(
                          addSerialNo(props.closedComplaintsFD)
                        );
                      }}
                    >
                      <CSVLink
                        data={props.closedComplaintsFD}
                        headers={headers}
                      >
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Repeat
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    {props.repeat}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        props.setRepeatComplaintsFD(
                          addSerialNo(props.repeatComplaintsFD)
                        );
                      }}
                    >
                      <CSVLink
                        data={props.repeatComplaintsFD}
                        headers={headers}
                      >
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>

                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Canceled
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    {props.cancel}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        props.setCancelComplaintsFD(
                          addSerialNo(props.cancelComplaintsFD)
                        );
                      }}
                    >
                      <CSVLink
                        data={props.cancelComplaintsFD}
                        headers={headers}
                      >
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
