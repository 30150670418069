import React, { useState } from "react";
import { Form, Input, Button, Modal, DatePicker, Select } from "antd";
import moment from "moment";

const { Option } = Select;

const AddTransactionModal = ({
  isVisible,
  onCancel,
  onFinish,
  technicians,
}) => {
  const [form] = Form.useForm();
  const [selectedTechnician, setSelectedTechnician] = useState(null);

  // Handle form submission
  const handleFinish = (values) => {
    onFinish({
      ...values,
      // date: moment(values.date).format("DD-MM-YYYY"),
      technicianId: selectedTechnician ? selectedTechnician._id : null,
      technician: selectedTechnician ? selectedTechnician.technicianName : null,
    });
    form.resetFields(); // Reset fields on successful submission
    setSelectedTechnician(null); // Reset selected technician
  };

  // Handle technician selection
  const handleTechnicianChange = (value) => {
    const tech = technicians.find((tech) => tech._id === value);
    setSelectedTechnician(tech);
  };

  // Reset form fields when modal is closed
  const handleCancel = () => {
    form.resetFields();
    setSelectedTechnician(null); // Clear selected technician
    onCancel();
  };

  return (
    <Modal
      title="Add Payment"
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      onOk={handleCancel} // Reset form when X button is clicked
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="technicianId"
          label="Technician"
          rules={[{ required: true, message: "Please select a technician" }]}
        >
          <Select
            className="custom-select font-size"
            placeholder="Select a technician"
            onChange={handleTechnicianChange}
          >
            {technicians.map((tech) => (
              <Option key={tech._id} value={tech._id}>
                {tech.technicianName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name="date"
          label="Date"
          rules={[{ required: true, message: "Please select a date" }]}
        >
          <DatePicker
            className="custom-datepicker"
            placeholder="DD-MM-YYYY"
            format="DD-MM-YYYY"
          />
        </Form.Item> */}
        <Form.Item
          name="amount"
          label="payment"
          rules={[{ required: true, message: "Please enter the amount" }]}
        >
          <Input
            type="number"
            className="font-size custom-input"
            placeholder="Add amount"
          />
        </Form.Item>
        <Form.Item name="remarks" className="font-size" label="Remarks">
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            style={{
              backgroundColor: "rgb(22 78 99)",
              color: "white",
              borderColor: "rgb(59 130 246 / 50%)",
            }}
            className="font-size"
            htmlType="submit"
          >
            Add Transaction
          </Button>
        </Form.Item>
      </Form>
      <style>
        {`
        .font-size{
          font-size:17px
        
        }
          .custom-select .ant-select-selector {
            color: #4a4a4a;
          }

          .custom-datepicker .ant-picker-input > input {
            color: #4a4a4a;
          }

          .custom-input::-webkit-input-placeholder {
            color: #4a4a4a; /* Dark grey color for placeholder */
          }

          .custom-input::-moz-placeholder {
            color: #4a4a4a; /* Dark grey color for placeholder */
          }

          .custom-input:-ms-input-placeholder {
            color: #4a4a4a; /* Dark grey color for placeholder */
          }

          .custom-input:-moz-placeholder {
            color: #4a4a4a; /* Dark grey color for placeholder */
          }

          .custom-input {
            -moz-appearance: textfield; /* Remove increment/decrement arrows in Firefox */
            -webkit-appearance: none; /* Remove increment/decrement arrows in Chrome, Safari, Edge */
            appearance: none; /* Remove increment/decrement arrows in other browsers */
          }
        `}
      </style>
    </Modal>
  );
};

export default AddTransactionModal;
