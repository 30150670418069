import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import ViewComplaintInDetail from "./ViewComplaintDetails";
import { Modal } from "antd";
import { ComplaintContext } from "../context/complaintContext";

const ComplaintList = (props) => {
  const commonClass =
    "px-3 py-4 font-semibold text-sm bg-white text-black whitespace-nowrap";
  const [complaints, setComplaints] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const complaintContext = useContext(ComplaintContext);
  const { inViewComplaint, setInViewComplaint } = complaintContext;

  const handleSearchResult = async (e) => {
    if (props.isSearch === true) {
      setComplaints(props.searchResult);
    } else {
      setComplaints([]);
    }
  };

  const handleOkCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    handleSearchResult();
  }, [props.searchResult]);

  let statusColorArr = [
    "orange",
    "green",
    "violet",
    "red",
    "gray",
    "cyan",
    "fuchsia",
    "stone",
    "lime",
    "rose",
    "teal",
    "blue",
    "indigo",
    "purple",
    "pink",
  ];
  let status, statusColor;
  const handleStatus = (complaint) => {
    let wd = complaint.workDone;

    if (wd === "Yes" || wd === "Work Done") {
      status = "Closed";
      statusColor = statusColorArr[0];
    } else if (wd === "No") {
      status = "Open";
      statusColor = statusColorArr[1];
    } else if (wd === "Visit Ok") {
      status = "Visit Ok";
      statusColor = statusColorArr[2];
    } else if (wd === "Cancel") {
      status = "Cancel";
      statusColor = statusColorArr[3];
    } else if (wd === "Ceramic Issue") {
      status = "Ceramic Issue";
      statusColor = statusColorArr[4];
    } else if (wd === "Other Issue") {
      status = "Other Issue";
      statusColor = statusColorArr[5];
    } else if (wd === "Part Pending") {
      status = "Part Pending";
      statusColor = statusColorArr[6];
    } else if (wd === "Seat Change") {
      status = "Seat Change";
      statusColor = statusColorArr[7];
    } else if (wd === "Courier By Company") {
      status = "Courier By Company";
      statusColor = statusColorArr[8];
    }
  };

  return (
    <div>
      <div className="w-full">
        <div className="w-full">
          <Modal
            open={isOpen}
            onOk={handleOkCancel}
            onCancel={handleOkCancel}
            okButtonProps={{
              style: { backgroundColor: "blue", color: "white" },
            }}
            width="80%"
            footer={null}
          >
            <ViewComplaintInDetail complaintInfo={inViewComplaint} />
          </Modal>
        </div>
        <div className="sm:rounded-lg mt-5 overflow-x-auto">
          <table className="w-full text-left text-gray-500">
            <thead className="text-sm text-black uppercase bg-[#F1F5F9]">
              <tr className="">
                <th scope="col" className="px-3 py-4">
                  Complaint No.
                </th>
                <th scope="col" className="px-3 py-4">
                  Customer Name
                </th>
                <th scope="col" className="px-3 py-3">
                  Brand Name
                </th>
                <th scope="col" className="px-3 py-3">
                  District
                </th>
                <th scope="col" className="px-3 py-3">
                  Status
                </th>
                <th scope="col" className="px-3 py-3">
                  Phone No
                </th>
                <th scope="col" className="px-3 py-3">
                  Technician Name
                </th>
                <th scope="col" className="px-3 py-3">
                  Date
                </th>
                <th scope="col" className="px-3 pl-16 py-3">
                  Action
                </th>
              </tr>
              <div className="mb-5" />
            </thead>
            {complaints.length === 0 ? (
              <div>
                <h1 className="ml-4 text-2xl">Please Select State</h1>
              </div>
            ) : (
              <tbody>
                {complaints.map((complaint, index) => (
                  <>
                    {handleStatus(complaint)}
                    <tr key={index} className="border-b  shadow-sm">
                      <td className={`${commonClass}  `}>
                        {complaint.complaintId}
                      </td>
                      <th scope="row" className={`${commonClass}`}>
                        {complaint.partyName?.substring(0, 15)}
                        {complaint.partyName?.length > 15 ? "..." : ""}
                      </th>
                      <td className={`${commonClass}  `}>
                        {complaint.brandName}
                      </td>
                      <td className={`${commonClass}`}>{complaint.city}</td>

                      <td className={`${commonClass} text-${statusColor}-500`}>
                        {status}
                      </td>

                      <td className={`${commonClass}`}>{complaint.mobileNo}</td>
                      <td className={`${commonClass}  `}>
                        {complaint.plumberName?.substring(0, 15)}
                        {complaint.plumberName?.length > 15 ? "..." : ""}
                      </td>
                      <td className={`${commonClass}`}>{complaint.date}</td>
                      <td className={`${commonClass} flex space-x-4`}>
                        {props.isSearch === true ? (
                          <>
                            <div
                              onClick={() => {
                                setInViewComplaint(complaint);
                                setIsOpen(true);
                              }}
                              className="flex items-center cursor-pointer"
                            >
                              <HiOutlineViewGridAdd
                                className="text-[#4bacc7]"
                                size={22}
                              />
                              <span className="text-[#4bacc7] ml-1 font-semibold">
                                View
                              </span>
                            </div>
                            <Link
                              to={`/user-admin/updateComplaint/${complaint._id}`}
                              className="flex items-center cursor-pointer"
                            >
                              <TbEdit className="text-gray-500" size={19} />
                              <span className="text-gray-500 ml-1 font-semibold">
                                Edit
                              </span>
                            </Link>
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() => {
                                setInViewComplaint(complaint);
                                setIsOpen(true);
                              }}
                              className="flex items-center cursor-pointer"
                            >
                              <HiOutlineViewGridAdd
                                className="text-[#4bacc7]"
                                size={22}
                              />
                              <span className="text-[#4bacc7] ml-1 font-semibold">
                                View
                              </span>
                            </div>
                            <Link
                              to={`/user-admin/updateComplaint/${complaint.id}`}
                              className="flex items-center cursor-pointer"
                            >
                              <TbEdit className="text-gray-500" size={19} />
                              <span className="text-gray-500 ml-1 font-semibold">
                                Edit
                              </span>
                            </Link>
                          </>
                        )}
                      </td>
                    </tr>
                    <div className="mb-5" />
                  </>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default ComplaintList;
