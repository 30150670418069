import React, { useState } from "react";
import { Card } from "antd";
import { Link } from "react-router-dom"; // Import Link
import TopComplaints from "./Analytics/TopComplaints";
import ComplaintsByUser from "./Analytics/ComplaintsByUser";
import CompanyWiseAnalysis from "./CompanyWiseAnalysis";
import GlobleState from "../adminComponents/GlobleState";
const Analytics = ({ month, year }) => {
  const [activeTabKey1, setActiveTabKey1] = useState("tab1");

  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };

  const tabList = [
    {
      key: "Top 50 Complaints",
      tab: "Top 50 Complaints",
    },
    {
      key: "ID",
      tab: "ID",
    },
    {
      key: "Global State",
      tab: "Global State", // Added navigation tab for GlobleState
    },
    // {
    //   key: "Company Level",
    //   tab: "Company Level", // Added navigation tab for GlobleState
    // },
  ];

  const contentList = {
    "Top 50 Complaints": <TopComplaints />,
    ID: <ComplaintsByUser month={month} year={year} />,
    // Link to GlobleState page
    "Global State": <GlobleState />,
    // "Company Level": <CompanyWiseAnalysis />
  };

  return (
    <div
      className="w-[85vw] px-5 mt-4 nunito-font h-full bg-[#F1F5F9]"
      style={{ height: "740px" }}
    >
      <Card
        style={{
          width: "100%",
        }}
        title="Analytics"
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={onTab1Change}
      >
        {contentList[activeTabKey1]}
      </Card>
    </div>
  );
};

export default Analytics;
