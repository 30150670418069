import React, { useEffect, useState } from "react";
import { searchByState } from "../api/complaint";
import BadgeData from "./State/BadgeData";
import DataCard from "./State/DataCard";
import LineChart from "./State/Graphs/LineChart";
import PieChart from "./State/Graphs/PieChart";
import Heading from "./State/Heading";
import FetchList from "./State/FetchList";
import { Alert, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const stateList = [
  "ANDHRA PRADESH",
  "ARUNACHAL PRADESH",
  "ASSAM",
  "BIHAR",
  "CHATTISGARH",
  "CHANDIGARH",
  "DELHI",
  "GOA",
  "GUJARAT",
  "HARYANA",
  "HIMACHAL PRADESH",
  "JAMMU & KASHMIR",
  "JHARKHAND",
  "KARNATAKA",
  "KERALA",
  "MADHYA PRADESH",
  "MAHARASHTRA",
  "MANIPUR",
  "MEGHALAYA",
  "MIZORAM",
  "ODISHA",
  "PUNJAB",
  "PONDICHERRY",
  "RAJASTHAN",
  "SIKKIM",
  "TAMIL NADU",
  "TELANGANA",
  "TRIPURA",
  "UTTARAKHAND",
  "UTTAR PRADESH",
  "WEST BENGAL",
  "ANDAMAN & NICOBAR ISLANDS",
];

const State = (props) => {
  const { month, year } = props;
  const [state, setState] = useState("DELHI");
  const [complaintData, setComplaintData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const chartHandler = async (newStateValue) => {
    setIsLoading(true);
    setState(newStateValue);
    setIsLoading(false);
  };

  useEffect(() => {
    const handleStateComplaints = async () => {
      setIsLoading(true);
      setComplaintData([]);
      const data = await searchByState({ state, month, year });
      setComplaintData(data);
      setIsLoading(false);
    };
    console.log("complaits data",complaintData)

    handleStateComplaints();
  }, [state, month, year]);

  if (isLoading)
    return (
      <div className="flex w-full">
        <Spin
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    );

  return (
    <div className="w-full p-2 flex ">
      <div className="App w-full px-10">
        <div className="mx-auto mt-3 px-4">
          <div className="flex justify-between align-middle p-1">
            <Heading
              className="text-2xl font-semibold text-gray-700 my-2 w-fit"
              isDivider={false}
            >
              {state} Analytics
            </Heading>
            <div className="w-48">
              <FetchList
                list={stateList}
                placeholder="State"
                state={state}
                OnChangeHandler={chartHandler}
              />
            </div>
          </div>
          <div className="p-2 w-fit">
            <BadgeData
              closeComplaints={complaintData.closeComplaints}
              totalComplaints={complaintData.totalComplaints}
              pendingComplaints={complaintData.pendingComplaints}
              totalPayments={complaintData.totalPayments}
              averagePayments={complaintData.averagePayments}
            />
          </div>
        </div>
        <div className="w-full mx-auto p-4 h-fit">
          {complaintData.districtList.length > 0 && (
            <>
              <Heading className="text-2xl font-semibold text-gray-700 my-2">
                District wise complaints
              </Heading>
              <LineChart
                districtList={complaintData.districtList}
                totalComplaintList={complaintData.totalComplaintList}
                pendingComplaintList={complaintData.pendingComplaintList}
                closeComplaintList={complaintData.closeComplaintList}
                className="w-11/12 mx-auto"
              />
            </>
          )}
          {complaintData.districtList.length <= 0 && (
            <Alert
              type="info"
              showIcon={true}
              message={"Payments are not done yet. (of at least one)"}
              className="text-md"
            />
          )}
        </div>
        <div className="w-full mx-auto flex justify-between align-middle mt-20 ">
          <div
            className="w-[60%] p-4 rounded h-fit bg-white"
            style={{ boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px" }}
          >
            {complaintData.districtList.length > 0 &&
            !complaintData.totalPaymentList.every((v) => v === 0) ? (
              <>
                <Heading className="text-2xl font-semibold text-gray-700 my-2">
                  District wise Payments
                </Heading>

                <PieChart
                  districtList={complaintData.districtList}
                  totalPaymentList={complaintData.totalPaymentList}
                  averagePaymentList={complaintData.averagePaymentList}
                  className="w-full rounded"
                />
              </>
            ) : (
              <Alert
                type="info"
                showIcon={true}
                message={"Paymnens are not done yet. (of at least one)"}
                className="text-md"
              />
            )}
          </div>
          <div
            className="w-1/3 p-4 rounded h-fit bg-white"
            style={{ boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px" }}
          >
            {complaintData.districtList.length > 0 ? (
              <>
                <Heading className="text-2xl font-semibold text-gray-700 my-2">
                  District Analytics
                </Heading>

                <DataCard
                  districtList={complaintData.districtList}
                  extractData={complaintData.extractData}
                />
              </>
            ) : (
              <Alert
                type="info"
                showIcon={true}
                message={"Districts are not fetch"}
                className="text-md"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default State;
