import member from "./member";

export const fetchAllProducts = async () => {
  try {
    const { data } = await member(`/products/fetchAllProducts`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const deleteProduct = async (productId) => {
  try {
    const { data } = await member.delete(
      `/products/deleteProduct/${productId}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
