import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BsDownload } from "react-icons/bs";
import { CSVLink } from "react-csv";
import {
  getMember,
  getfetchCountForForwTodays,
  fetchCountForCloseToday,
} from "../api/complaint";

const SideBar = (props) => {
  const [dashboard, setDashboard] = useState("");
  const [product, setProduct] = useState("");
  const [performance, setPerformance] = useState(" ");
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [todaysComplaints, setTodaysComplaint] = useState([]);
  const [todaysCloseComplaintsCount, setTodaysCloseComplaintsCount] =
    useState(0);
  const [todaysCloseComplaints, setTodaysCloseComplaints] = useState([]);
  const history = useNavigate();
  const memberId = localStorage.getItem("memberId");

  const email = localStorage.getItem("email");
  const state = localStorage.getItem("search");
  const getMemberRole = () => {
    const role = localStorage.getItem("role");
    return role;
  };
  var role = getMemberRole();

  const getMemberById = async (memberId) => {
    const { data } = await getMember(memberId);
  };

  const fetchCountForForwTodays = async () => {
    const { complaints } = await getfetchCountForForwTodays(
      localStorage.getItem("search")
    );
    setTodaysComplaint(complaints);
  };

  const handleTodayCloseComplaint = async () => {
    const data = await fetchCountForCloseToday(email, state);
    setTodaysCloseComplaintsCount(data.lenTotal);
    setTodaysCloseComplaints(data.complaint);
  };

  const addSerialNo = (toConvertData) => {
    var data = toConvertData.map((item) => ({
      ...item,
      plumbingNo: `"${item.plumbingNo}"`, // convert value to string and add quotes
    }));
    let finalData = [];
    let count = 1;
    data.map((item) => {
      finalData.push(Object.assign(item, { srNo: count++ }));
    });
    return finalData;
  };

  useEffect(() => {
    fetchCountForForwTodays();
    handleTodayCloseComplaint();
  }, [localStorage.getItem("search")]);

  useEffect(() => {
    getMemberById(memberId);
  }, [memberId]);

  useEffect(() => {
    if (window.location.pathname === "/user-admin/view-complaints") {
      setDashboard("text-[#5C697B] hover:bg-white");
      setProduct("bg-white");
      setPerformance(" text-[#5C697B] hover:bg-white");
      setVisibleStatus(true);
      fetchCountForForwTodays();
    } else if (window.location.pathname === "/user-admin") {
      setDashboard("bg-white");
      setProduct("text-[#5C697B] hover:bg-white");
      setPerformance(" text-[#5C697B] hover:bg-white");
      setVisibleStatus(false);
    }
  }, [history]);

  let headerFinal = [
    { label: "NO", key: "srNo" },
    { label: "COMPLAIT NO", key: "complaintId" },
    { label: "DATE", key: "date" },
    { label: "CUSTOMER NAME", key: "partyName" },
    { label: "ADDRESS", key: "address" },
    { label: "PINCODE", key: "pincode" },
    { label: "STATE", key: "state" },
    { label: "CITY", key: "city" },
    { label: "MOBILE NO", key: "mobileNo" },
    { label: "OFFICE NO", key: "plumbingNo" },
    { label: "BRAND NAME", key: "brandName" },
    { label: "TEHCHNICIAN NAME", key: "plumberName" },
    { label: "STATUS", key: "workDone" },
    { label: "PART PENDING DETAILS", key: "partPendingDetails" },
    { label: "REPEAT", key: "repeat" },
    { label: "ENTRY REMARK", key: "entryRemark" },
    { label: "FORWARDING REMARK", key: "remark" },
    { label: "CODE", key: "code" },
    { label: "NOTE", key: "note" },
    { label: "STICKER", key: "sticker" },
    { label: "TOTAL PRODUCTS", key: "problem" },
    { label: "TEHCHNICIAN WORK", key: "solution" },
    { label: "COURIER", key: "courier" },
    { label: "CLOSING DATE", key: "closingDate" },
  ];

  return (
    <div>
      {/* Sidebar starts */}
      <div
        className="absolute overflow-y-scroll  overflow-hidden lg:relative  min-h-screen shadow bg-[#E2E8F0] hidden lg:block "
        style={{
          height: "400px",
          maxHeight: "600px",
          width: "269px",
          maxWidth: "300px",
          scrollbarWidth: "thin",
        }}
      >
        <ul aria-orientation="vertical" className="pt-2 -mt-2">
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${dashboard} transition`}
          >
            <Link to={role == "forwarding" ? "/user-admin" : "/signin"}>
              <div className="flex items-center  ">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-grid"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <rect x={4} y={4} width={6} height={6} rx={1} />
                    <rect x={14} y={4} width={6} height={6} rx={1} />
                    <rect x={4} y={14} width={6} height={6} rx={1} />
                    <rect x={14} y={14} width={6} height={6} rx={1} />
                  </svg>
                </div>
                <span className="ml-2">Dashboard</span>
              </div>
            </Link>
          </li>
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${product} transition`}
          >
            <Link
              to={
                role == "forwarding" ? "/user-admin/view-complaints" : "/signin"
              }
            >
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-puzzle"
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1" />
                </svg>
                <span className="ml-2">View Complaints</span>
              </div>
            </Link>
          </li>
          {/* <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${product} transition`}
          >
            <Link
              to={
                role == "forwarding" ? "/user-admin/add-technician" : "/signin"
              }
            >
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width={20}
                  height={20}
                >
                  <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" />
                </svg>

                <span className="ml-2">Add Technician</span>
              </div>
            </Link>
          </li> */}
          <li
            className={`p-4 cursor-pointer  text-base leading-3 tracking-normal  m-3 rounded-xl ${product} transition`}
          >
            <Link
              to={
                role == "forwarding" ? "/user-admin/add-transection" : "/signin"
              }
            >
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width={20}
                  height={20}
                  color="rgb(214 212 202)"
                >
                  <path d="M438.6 150.6c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.7 96 32 96C14.3 96 0 110.3 0 128s14.3 32 32 32l306.7 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l96-96zm-333.3 352c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 416 416 416c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96z" />
                </svg>

                <span className="ml-2">Payments</span>
              </div>
            </Link>
          </li>

          {/* status table */}
          {visibleStatus && (
            <>
              <div className="relative overflow-x-auto mt-7  w-64  text-white shadow-md sm:rounded-lg">
                <table className=" table-fixed w-full">
                  <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="pl-3 py-3 ">
                        Complaints
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Count
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Export
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                      <th
                        scope="row"
                        className="px-6 py-4  font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        Total
                      </th>
                      <td className=" text-black ml-10 dark:text-white px-6 py-3 text-right">
                        {props.total}
                      </td>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        <button
                          onClick={() => {
                            props.setTotalComplaintsFD(
                              addSerialNo(props.totalComplaintsFD)
                            );
                          }}
                        >
                          <CSVLink
                            data={props.totalComplaintsFD}
                            headers={headerFinal}
                          >
                            <BsDownload />
                          </CSVLink>
                        </button>
                      </td>
                    </tr>
                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                      <th
                        scope="row"
                        className="px-6 py-4  font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        Today
                      </th>
                      <td className=" text-black ml-10 dark:text-white px-6 py-3 text-right">
                        {todaysComplaints.length}
                      </td>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        <button
                          onClick={() => {
                            setTodaysComplaint(addSerialNo(todaysComplaints));
                          }}
                        >
                          <CSVLink
                            data={todaysComplaints}
                            headers={headerFinal}
                          >
                            <BsDownload />
                          </CSVLink>
                        </button>
                      </td>
                    </tr>
                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                      <th
                        scope="row"
                        className="px-6 py-4  font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        Today's Close
                      </th>
                      <td className=" text-black ml-10 dark:text-white px-6 py-3 text-right">
                        {todaysCloseComplaintsCount}
                      </td>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        <button
                          onClick={() => {
                            setTodaysComplaint(
                              addSerialNo(todaysCloseComplaints)
                            );
                          }}
                        >
                          <CSVLink
                            data={todaysCloseComplaints}
                            headers={headerFinal}
                          >
                            <BsDownload />
                          </CSVLink>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="relative mt-4 overflow-x-auto w-64  text-white shadow-md sm:rounded-lg">
                <table className=" table-fixed w-full">
                  <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Count
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Export
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        Open
                      </th>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        {props.open}
                      </td>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        <button
                          onClick={() => {
                            props.setOpenComplaints(
                              addSerialNo(props.openComplaints)
                            );
                          }}
                        >
                          <CSVLink
                            data={props.openComplaints}
                            headers={headerFinal}
                          >
                            <BsDownload />
                          </CSVLink>
                        </button>
                      </td>
                    </tr>
                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        Ceramic Issue
                      </th>
                      <td className="px-6 py-3 text-black dark:text-white text-right ">
                        {props.ceramicIssue}
                      </td>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        <button
                          onClick={() => {
                            props.setCeramicIssueComplaintsFD(
                              addSerialNo(props.ceramicIssueComplaintsFD)
                            );
                          }}
                        >
                          <CSVLink
                            data={props.ceramicIssueComplaintsFD}
                            headers={headerFinal}
                          >
                            <BsDownload />
                          </CSVLink>
                        </button>
                      </td>
                    </tr>
                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        Other Issue
                      </th>
                      <td className="px-6 py-3 text-black dark:text-white text-right ">
                        {props.otherIssue}
                      </td>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        <button
                          onClick={() => {
                            props.setOtherIssueComplaintsFD(
                              addSerialNo(props.otherIssueComplaintsFD)
                            );
                          }}
                        >
                          <CSVLink
                            data={props.otherIssueComplaintsFD}
                            headers={headerFinal}
                          >
                            <BsDownload />
                          </CSVLink>
                        </button>
                      </td>
                    </tr>
                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        Part Pending
                      </th>
                      <td className="px-6 py-3 text-black dark:text-white text-right ">
                        {props.partPendingFD}
                      </td>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        <button
                          onClick={() => {
                            props.setPartPendingComplaintsFD(
                              addSerialNo(props.partPendingComplaintsFD)
                            );
                          }}
                        >
                          <CSVLink
                            data={props.partPendingComplaintsFD}
                            headers={headerFinal}
                          >
                            <BsDownload />
                          </CSVLink>
                        </button>
                      </td>
                    </tr>
                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        Seat Change
                      </th>
                      <td className="px-6 py-3 text-black dark:text-white text-right ">
                        {props.seatChangeFD}
                      </td>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        <button
                          onClick={() => {
                            props.setSeatChangeComplaintsFD(
                              addSerialNo(props.seatChangeComplaintsFD)
                            );
                          }}
                        >
                          <CSVLink
                            data={props.seatChangeComplaintsFD}
                            headers={headerFinal}
                          >
                            <BsDownload />
                          </CSVLink>
                        </button>
                      </td>
                    </tr>
                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        Closed
                      </th>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        {props.closed}
                      </td>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        <button
                          onClick={() => {
                            props.setClosedComplaintsFD(
                              addSerialNo(props.closedComplaintsFD)
                            );
                          }}
                        >
                          <CSVLink
                            data={props.closedComplaintsFD}
                            headers={headerFinal}
                          >
                            <BsDownload />
                          </CSVLink>
                        </button>
                      </td>
                    </tr>

                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        Canceled
                      </th>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        {props.cancel}
                      </td>
                      <td className="px-6 py-3 text-black dark:text-white text-right">
                        <button
                          onClick={() => {
                            props.setCancelComplaintsFD(
                              addSerialNo(props.cancelComplaintsFD)
                            );
                          }}
                        >
                          <CSVLink
                            data={props.cancelComplaintsFD}
                            headers={headerFinal}
                          >
                            <BsDownload />
                          </CSVLink>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </ul>
      </div>

      {/* Sidebar ends */}
    </div>
  );
};

export default SideBar;
