import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  Table,
  Button,
  Flex,
  Dropdown,
  Menu,
  notification,
} from "antd";
import member from "../api/member";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { DownloadOutlined } from "@ant-design/icons";

const TechnicianViewModal = ({
  visible,
  onClose,
  technician,
  transactions,
}) => {
  const [newtransaction, SetnewTransaction] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const columns = [
    {
      title: "Sr. No",
      key: "srNo",
      width: "80px",
      render: (text, record, index) => index + 1, // Serial number
      align: "center",
    },
    {
      title: "Technician",
      dataIndex: "technician",
      key: "technician",
      width: "130px",
      align: "center", // Center-align text in the column
    },
    {
      title: "Pay Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Modified Date",
      dataIndex: "modified",
      key: "modified",
      render: (date) => new Date(date).toLocaleDateString(),
      align: "center",
    },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Remarks", dataIndex: "remarks", key: "remarks" },
    { title: "Added By", dataIndex: ["addedBy", "name"], key: "addedBy" },
    {
      title: "Status",
      key: "paymentStatus",
      align: "center", // Center-align text in the column
      render: (text, record) => {
        const paymentDate = new Date(record.date).toLocaleDateString();
        const currentDate = new Date().toLocaleDateString();
        return paymentDate === currentDate ? (
          <span style={{ color: "green", fontWeight: "bold" }}>Done</span>
        ) : (
          <span style={{ color: "red", fontWeight: "bold" }}>Pending</span>
        );
      },
    },
  ];

  const mainonclose = () => {
    onClose();
    SetnewTransaction([]);
  };

  //set the transections
  useEffect(() => {
    SetnewTransaction(transactions);
    // console.log("model transection", transactions);
  });
  useEffect(() => {
    if (newtransaction && newtransaction.length > 0) {
      const totalAmount = newtransaction.reduce(
        (sum, tran) => sum + tran.amount,
        0
      );
      setTotalAmount(totalAmount);
    } else {
      setTotalAmount(0); // Set to 0 if no transactions are available
    }
  }, [newtransaction]);

  const handleDownloadCSV = () => {
    if (transactions.length > 0) {
      const csv = Papa.unparse(
        transactions.map(({ _id, __v, technicianId, addedBy, ...rest }) => ({
          ...rest,
          addedBy: addedBy.name, // Include only the name from the addedBy field
        }))
      );
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `transactions_${moment().format("DDMMYYYY")}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      notification.warning({
        message: "No Data",
        description: "No transactions available for download.",
      });
    }
  };

  const handleDownloadPDF = () => {
    if (transactions.length > 0) {
      const doc = new jsPDF();

      doc.text(
        `Technician: ${technician?.technicianName},Total Transactions: ${totalAmount} `,
        0,
        20
      );

      doc.autoTable({
        startY: 25,
        margin: { left: 5 },
        head: [
          [
            "Sr. No",
            "Technician",
            "Created Date",
            "Modified",
            "Payment Date",
            "Amount",
            "Remarks",
            "Added By",
            "Payment Status",
          ],
        ],
        body: transactions.map((transaction, index) => [
          index + 1,
          transaction.technician,
          new Date(transaction.createdAt).toLocaleDateString(),
          new Date(transaction.modified).toLocaleDateString(),
          new Date(transaction.date).toLocaleDateString(),
          transaction.amount,
          transaction.remarks,
          transaction.addedBy.name,
          new Date(transaction.date).toLocaleDateString() ===
          new Date().toLocaleDateString()
            ? "Done"
            : "Pending",
        ]),
        margin: { left: 0 },
        styles: {
          fontSize: 10,
          marginLeft: 0,
          cellPadding: 2,
          headHeight: 10,
          lineColor: [0, 0, 0], // Cell border color (black)
          lineWidth: 0.1,
        },

        columnStyles: {
          0: {
            halign: "center",
            valign: "center",
            cellWidth: 10,
            // cellWidth: "wrap",
            fillColor: [232, 252, 245],
          },
          1: {
            halign: "center",
            valign: "center",
            cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          2: {
            halign: "center",
            valign: "center",
            // cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          3: {
            halign: "center",
            valign: "center",
            // cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          4: {
            halign: "center",
            valign: "center",
            // cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          5: {
            halign: "center",
            valign: "center",
            cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          6: { halign: "left", cellWidth: 30, fillColor: [232, 252, 245] },
          7: {
            halign: "center",
            valign: "center",
            cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          8: {
            halign: "center",
            valign: "center",
            cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
        },
      });

      doc.save(`transactions_${moment().format("YYYYMMDD")}.pdf`);
    } else {
      notification.warning({
        message: "No Data",
        description: "No transactions available for download.",
      });
    }
  };

  const downloadMenu = (
    <Menu>
      <Menu.Item key="pdf" onClick={handleDownloadPDF}>
        Download PDF
      </Menu.Item>
      <Menu.Item key="csv" onClick={handleDownloadCSV}>
        Download CSV
      </Menu.Item>
    </Menu>
  );

  return (
    <Modal
      title="Technician Details and Transactions"
      visible={visible}
      onCancel={mainonclose}
      footer={null}
      width={1500}
      style={{ marginTop: "-60px", display: "flex", justifyContent: "center" }}
    >
      <Row gutter={16}>
        <Col span={100}>
          <div
            style={{
              padding: "20px",
              borderRight: "1px solid #ddd",
              textWrap: "wrap",
              width: "310px",
            }}
          >
            <h3>Technician Details</h3>
            <p>
              <strong>Name:</strong> {technician?.technicianName}
            </p>
            <p>
              <strong>Mobile No:</strong> {technician?.mobileNo}
            </p>
            <p>
              <strong>State:</strong> {technician?.state}
            </p>
            <p>
              <strong>City:</strong> {technician?.city}
            </p>
            <p>
              <strong style={{ textWrap: "wrap" }}>Address:</strong>{" "}
              {technician?.address}
            </p>
            <p>
              <strong>Pincode:</strong> {technician?.pincode}
            </p>
            <p>
              <strong>Date Created:</strong>
              {moment(technician?.createdAt).format("DD-MM-YYYY")}
            </p>
            <p>
              <strong>Aadhar Card:</strong> {technician?.aadharcard.uid}
            </p>
            <p>
              <strong>PAN Card:</strong> {technician?.pancard.uid}
            </p>
          </div>
        </Col>
        <Col span={50}>
          <div style={{ padding: "0px", width: "1100px", maxWidth: "1200px" }}>
            <div style={{ display: "flex" }}>
              <h3>Transactions</h3>
              <Dropdown overlay={downloadMenu} placement="bottomLeft">
                <Button
                  type="default"
                  icon={<DownloadOutlined />}
                  style={{
                    marginLeft: "15px",
                    backgroundColor: "rgb(22 78 99 )",
                    color: "white",
                  }}
                  className="mr-8 mb-1"
                >
                  Download
                </Button>
              </Dropdown>
            </div>
            <Table
              dataSource={newtransaction}
              columns={columns}
              rowKey="_id"
              pagination={false}
              sticky
              scroll={{
                x: "max-content",
              }}
              style={{
                marginTop: "1px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                overflow: "hidden",
                height: "500px",
                maxHeight: "600px",
                // overflowX: "scroll",
                overflowY: "scroll",
                scrollbarWidth: "thin",
              }}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default TechnicianViewModal;
