import React, { createContext, useState } from "react";

const ComplaintContext = createContext();

const ComplaintProvider = ({ children }) => {
  const [inViewComplaint, setInViewComplaint] = useState({});
  const contextValues = { inViewComplaint, setInViewComplaint };

  return (
    <ComplaintContext.Provider value={contextValues}>
      {children}
    </ComplaintContext.Provider>
  );
};

export { ComplaintContext, ComplaintProvider };
