import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { getRandomLightColors, listOfTypesOfAmounts } from "../Helper/Helper";
import FetchList from "../FetchList";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const PieChart = (props) => {
  const { districtList, totalPaymentList, averagePaymentList } = props;
  const classes = props.className;

  const [isLoading, setIsLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(totalPaymentList);
    setLabels(districtList);
    setIsLoading(false);
  }, [isLoading]);

  if (isLoading)
    return (
      <div className="text-center mt-10">
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    );

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Amount",
        data: chartData,
        backgroundColor: getRandomLightColors(chartData.length),
        fill: "start",
      },
    ],
  };

  const chartDataHandler = (value) => {
    if (value === "total amount") setChartData(totalPaymentList);
    else if (value === "average amount") setChartData(averagePaymentList);
  };

  return (
    <div className={classes}>
      <div className="w-40 ml-auto my-2">
        <FetchList
          list={listOfTypesOfAmounts}
          placeholder="amount type"
          OnChangeHandler={chartDataHandler}
        />
      </div>

      <Doughnut data={data} />
    </div>
  );
};

export default PieChart;
