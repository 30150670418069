import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Radio } from "antd";

const StateLineChart = ({ data, xLabel, yLabel, stateList }) => {
  const [option, setOption] = useState("totalComplaints");

  useEffect(() => {
    console.log("Chart Data:", data);
  }, [data, option]);

  const renderLineChart = () => {
    if (!data || Object.keys(data).length === 0) {
      return <div>No data available</div>;
    }

    let key;
    let yAxisLabel;
    switch (option) {
      case "totalPayments":
        key = "totalPayments";
        yAxisLabel = "Total Payments(₹)";
        break;
      case "averagePayments":
        key = "avgPayment";
        yAxisLabel = "Average Payments(₹)";
        break;
      case "totalPendingComplaints":
        key = "totalPendingComplaints";
        yAxisLabel = "Pending Complaints";
        break;
      case "totalClosedComplaints":
        key = "totalClosedComplaints";
        yAxisLabel = "Closed Complaints";
        break;
      default:
        key = "totalComplaints";
        yAxisLabel = "Total Complaints";
    }

    const dataArray = stateList.map((state) => ({
      name: state,
      [option]: data[state] ? data[state][key] : 0,
    }));

    return (
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          data={dataArray}
          margin={{ top: 50, right: 30, left: 50, bottom: 100 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            angle={-45}
            textAnchor="end"
            fontSize={10}
            interval={0}
            height={100}
          />
          <YAxis
            label={{ value: yAxisLabel, angle: -90, position: "insideLeft",dx: -20 }}
            tickFormatter={(tick) => {
              if (Math.abs(tick) >= 1e9) {
                return `${(tick / 1e9).toFixed(1)}B`;
              }
              if (Math.abs(tick) >= 1e6) {
                return `${(tick / 1e6).toFixed(1)}M`;
              }
              if (Math.abs(tick) >= 1e3) {
                return `${(tick / 1e3).toFixed(1)}K`;
              }
              return tick;
            }}
          />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey={option}
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };

  return (
    <div className="w-full mx-auto">
      <div className="flex justify-center mb-4">
        <Radio.Group onChange={handleOptionChange} value={option}>
          <Radio.Button value="totalComplaints">Total Complaints</Radio.Button>
          <Radio.Button value="totalPendingComplaints">Pending Complaints</Radio.Button>
          <Radio.Button value="totalClosedComplaints">Closed Complaints</Radio.Button>
          <Radio.Button value="totalPayments">Total Payments(₹)</Radio.Button>
          <Radio.Button value="averagePayments">Average Payments(₹)</Radio.Button>
        </Radio.Group>
      </div>
      {renderLineChart()}
    </div>
  );
};

export default StateLineChart;
