import React, { useState } from "react";
import member from "../api/member";

const AddMember = () => {
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    phone: 0,
    role: "",
    states: [],
    password: "",
  });
  const [role, setRole] = useState("");
  const [states, setStates] = useState("");
  const [enteredStates, setEnteredStates] = useState([]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && states.trim() !== " ") {
      setEnteredStates((prevStates) => [...prevStates, states.trim()]);
      setStates("");
    }
  };

  const removeState = (index) => {
    setEnteredStates((prevStates) => prevStates.filter((_, i) => i !== index));
  };

  const onChange = (e) => {
    if (e.target.name === "role" && e.target.value === "forwarding") {
      setRole("forwarding");
    } else if (e.target.name === "role") {
      setRole("");
    }
    if (e.target.name === "states") {
      setStates(e.target.value);
    }
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data } = await member.post("/auth/createmember", {
      name: credentials.name,
      email: credentials.email,
      role: credentials.role,
      states: enteredStates,
      phone: credentials.phone,
      password: credentials.password,
    });

    if (data.success) {
      alert("User Added successfully");
    } else {
      alert(data.errors);
    }

    setCredentials({
      name: "",
      email: "",
      phone: 0,
      role: "",
      states: [],
      password: "",
    });
  };

  const commonClass =
    "w-full rounded-xl border border-gray-300 focus:border-[#717984] focus:bg-white focus:ring-1 focus:ring-[#717984] text-lg outline-none text-gray-700  py-2 px-4 leading-8 transition-colors duration-200 ease-in-out";
  return (
    <div className="w-[80vw] nunito-font  h-full bg-[#F1F5F9]">
      <div className="w-full">
        <section className="text-gray-600  body-font relative">
          <div className="container px-5 py-4 mx-auto">
            <div className="flex flex-col   mb-6">
              <h1 className="text-xl font-medium">Add Member</h1>
            </div>
          </div>
          <form className="lg:w-2/3 md:w-2/3 mx-auto">
            <div>
              <div className="p-2 ">
                <div className="relative">
                  <label
                    htmlFor="name"
                    className="leading-7 text-base text-gray-600"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    onChange={onChange}
                    required
                    id="name"
                    name="name"
                    className={`${commonClass}`}
                    placeholder="Ex. Mr Sarariya "
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="p-2 ">
                <div className="relative">
                  <label
                    htmlFor="email"
                    className="leading-7 text-base text-gray-600"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    onChange={onChange}
                    required
                    id="partyName"
                    name="email"
                    className={`${commonClass}`}
                    placeholder="Ex.  abc@xyz.com "
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="p-2 ">
                <div className="relative">
                  <label
                    htmlFor="phone"
                    className="leading-7 text-base text-gray-600"
                  >
                    Phone No.
                  </label>
                  <input
                    type="text"
                    onChange={onChange}
                    required
                    id="phone"
                    name="phone"
                    className={`${commonClass}`}
                    placeholder="Ex.  654971497 "
                  />
                </div>
              </div>
            </div>
            <div className="mb-3 w-1/3 ml-2 right-0">
              <label className="leading-7 text-base text-gray-600">Role</label>
              <select
                name="role"
                onChange={onChange}
                required
                className="w-full appearance-none rounded-xl border border-gray-300 focus:border-[#717984] focus:bg-white focus:ring-1 focus:ring-[#717984] text-lg outline-none text-gray-700  py-2 px-4 leading-8 transition-colors duration-200 ease-in-out"
                aria-label=".form-select-lg example"
              >
                <option value="admin">Admin</option>
                <option value="entry">Entry Member</option>
                <option value="forwarding">Forwarding Member</option>
              </select>
            </div>
            {role === "forwarding" ? (
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    htmlFor="states"
                    className="leading-7 text-base text-gray-600"
                  >
                    Enter States
                  </label>
                  <textarea
                    onKeyDown={handleKeyPress}
                    name="states"
                    value={states}
                    onChange={onChange}
                    required
                    className="w-full appearance-none rounded-xl border border-gray-300 focus:border-[#717984] focus:bg-white focus:ring-1 focus:ring-[#717984] text-lg  h-15 outline-none text-gray-700  py-1 px-3 leading-8 transition-colors duration-200 ease-in-out resize-none"
                    placeholder="Enter state names and press Enter"
                  />
                  <div className="flex flex-wrap mt-3">
                    {enteredStates.map((state, index) => (
                      <div
                        key={index}
                        className="bg-gray-500 text-white py-1 px-3 rounded-full mr-2 mb-2 flex items-center"
                      >
                        {state}
                        <span
                          className="ml-2 cursor-pointer text-sm font-bold"
                          onClick={() => removeState(index)}
                        >
                          &times;
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div>
              <div className="p-2 ">
                <div className="relative">
                  <label
                    htmlFor="password"
                    className="leading-7 text-base text-gray-600"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    onChange={onChange}
                    required
                    id="password"
                    name="password"
                    className={`${commonClass}`}
                    placeholder="Ex.  asd97!#@sd56 "
                  />
                </div>
              </div>
              <div className="p-2 mt-5  w-full">
                <button
                  onClick={handleSubmit}
                  className="flex  text-white bg-[#717984] border-0 py-2 px-8 focus:outline-none hover:bg-[#5c646f] rounded text-lg"
                >
                  Add Member
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default AddMember;
