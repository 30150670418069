const makeDropDownList = (list) => {
  const dropiDownList = list.map((item, index) => {
    return { value: item, label: item.toUpperCase() };
  });

  return dropiDownList;
};

function getRandomLightColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
function getRandomLightColors(count) {
  const colors = [];
  for (let i = 0; i < count; i++) {
    colors.push(getRandomLightColor());
  }
  return colors;
}

const listOfTypesOfAmounts = ["total amount", "average amount"];
const listOfTypesOfComplaints = ["total", "pending", "close"];

export {
  makeDropDownList,
  getRandomLightColors,
  listOfTypesOfAmounts,
  listOfTypesOfComplaints,
};
