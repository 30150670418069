import React, { useEffect } from "react";
import { TbEdit } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ViewComplaintDetails = ({ complaintInfo }) => {
  let navigate = useNavigate();

  const infoStyle = `text-base rounded-md border border-gray-300  p-2 mt-1 bg-white w-80`;
  const detailClass = `flex flex-col`;
  const gridStyle = `grid grid-cols-1 md:grid-cols-3 gap-4 mx-auto`;

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/signin");
    }
  }, []);

  return (
    <div className="w-full nunito-font  h-full bg-[#F1F5F9] font-bold">
      <div className="min-w-screen p-5 mx-10">
        <div className="flex justify-between">
          <h2 className="mb-10 text-xl font-semibold">Complaint Details</h2>
          <div className="mb-10 flex text-lg space-x-6">
            <Link
              to={`/user-admin/updateComplaint/${complaintInfo._id}`}
              className="flex items-center  cursor-pointer"
            >
              <TbEdit className="text-gray-500" size={19} />
              <span className="text-gray-500 ml-1 font-semibold">Edit</span>
            </Link>
          </div>
        </div>
        <div className={gridStyle}>
          <div className={detailClass}>
            <span className="text-sm">Complaint Id</span>
            <span className={infoStyle}>
              {complaintInfo.complaintId || "-"}
            </span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Customer Name</span>
            <span className={infoStyle}>{complaintInfo.partyName || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Brand Name</span>
            <span className={infoStyle}>{complaintInfo.brandName || "-"}</span>
          </div>
          {complaintInfo.satisfactionCode && (
            <div className={detailClass}>
              <span className="text-sm">Satisfaction Code</span>
              <span className={infoStyle}>
                {complaintInfo.satisfactionCode || "-"}
              </span>
            </div>
          )}
          <div className={detailClass}>
            <span className="text-sm">State</span>
            <span className={infoStyle}>{complaintInfo.state || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">City</span>
            <span className={infoStyle}>{complaintInfo.city || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Address</span>
            <span className={infoStyle}>{complaintInfo.address || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Pincode</span>
            <span className={infoStyle}>{complaintInfo.pincode || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Mobile No.</span>
            <span className={infoStyle}>{complaintInfo.mobileNo || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Office No.</span>
            <span className={infoStyle}>{complaintInfo.plumbingNo || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Syphone Color</span>
            <span className={infoStyle}>
              {complaintInfo.syphoneColor || "-"}
            </span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Code</span>
            <span className={infoStyle}>{complaintInfo.code || "-"}</span>
          </div>

          <div className={detailClass}>
            <span className="text-sm">Payment</span>
            <span className={infoStyle}>{complaintInfo.payment || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Status</span>
            <span className={infoStyle}>{complaintInfo.workDone || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Part Pending Details</span>
            <span className={infoStyle}>
              {complaintInfo.partPendingDetails || "-"}
            </span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Repeat</span>
            <span className={infoStyle}>{complaintInfo.repeat || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Sticker</span>
            <span className={infoStyle}>{complaintInfo.sticker || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Entry Remark</span>
            <span className={infoStyle}>
              {complaintInfo.entryRemark || "-"}
            </span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Forwarding Remark</span>
            <span className={infoStyle}>{complaintInfo.remark || "-"}</span>
          </div>

          <div className={detailClass}>
            <span className="text-sm">Product</span>
            <span className={infoStyle}>{complaintInfo.product || "-"}</span>
          </div>

          <div className={detailClass}>
            <span className="text-sm">Total Products</span>
            <span className={infoStyle}>{complaintInfo.problem || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Technician Work</span>
            <span className={infoStyle}>{complaintInfo.solution || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Purchase Time</span>
            <span className={infoStyle}>
              {complaintInfo?.purchaseTime || "-"}
            </span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Service Type</span>
            <span className={infoStyle}>
              {complaintInfo?.serviceType || "-"}
            </span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Note</span>
            <span className={infoStyle}>{complaintInfo?.note || "-"}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Technician Name</span>
            <span className={infoStyle}>
              {complaintInfo.plumberName || "-"}
            </span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Closing Date</span>
            <span className={infoStyle}>
              {complaintInfo.closingDate || "-"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewComplaintDetails;
