import React, { useEffect, useState } from "react";
import { fetchComplaintsByUser, getMembers } from "../../api/complaint";
import { Badge, Space, Alert, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Heading from "../State/Heading";
import LineChart from "./Graphs/LineChart";
import FetchList from "../State/FetchList";

const ComplaintsByUser = (props) => {
  const [complaintData, setComplaintData] = useState([]);
  const [email, setEmail] = useState("one@ideal.com");
  const [isLoading, setIsLoading] = useState(true);
  const [memberList, setMeberList] = useState([]);
  const { month, year } = props;

  function getDaysInMonth(year, month) {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysArray = Array.from(
      { length: daysInMonth },
      (_, index) => index + 1
    );
    return daysArray;
  }

  const chartHandler = async (newStateValue) => {
    setIsLoading(true);
    setEmail(newStateValue);
    setIsLoading(false);
  };

  const commonCSS = {
    padding: 14,
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
  };

  useEffect(() => {
    const getMembersList = async () => {
      const data = await getMembers();
      setMeberList(data);
    };

    getMembersList();
  }, []);

  useEffect(() => {
    const handleComplaintsByUser = async () => {
      setIsLoading(true);
      setComplaintData([]);
      const data = await fetchComplaintsByUser(email, month, year);
      setComplaintData(data);
      setIsLoading(false);
    };

    handleComplaintsByUser();
  }, [email, month, year]);

  if (isLoading)
    return (
      <div className="flex w-full">
        <Spin
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    );

  return (
    <div>
      <div className="mx-auto mt-3 px-4">
        <div className="flex justify-between align-middle p-1">
          <Heading
            className="text-2xl font-semibold text-gray-700 my-2 w-fit"
            isDivider={false}
          >
            {email} Analytics
          </Heading>
          <div className="w-48">
            <FetchList
              list={memberList}
              placeholder="Member"
              email={email}
              OnChangeHandler={chartHandler}
            />
          </div>
        </div>
        <div className="p-2 w-fit">
          <Space>
            <Badge
              style={commonCSS}
              count={`Total : ${
                complaintData.statusWise.pending.count +
                complaintData.statusWise.close.count +
                complaintData.statusWise.cancel.count
              }`}
              color={"blue"}
            />
            <Badge
              style={commonCSS}
              count={`Pending : ${complaintData.statusWise.pending.count}`}
              color={"orange"}
            />
            <Badge
              style={commonCSS}
              count={`Close : ${complaintData.statusWise.close.count}`}
              color={"green"}
            />
            <Badge
              style={commonCSS}
              count={`Cancel : ${complaintData.statusWise.cancel.count}`}
              color={"volcano"}
            />
          </Space>
        </div>
      </div>
      <div className="w-full mx-auto p-4 h-fit">
        {complaintData.total > 0 && (
          <>
            <Heading className="text-2xl font-semibold text-gray-700 my-2">
              Day wise complaints
            </Heading>
            <LineChart
              complaintList={complaintData.statusWise}
              memberList={memberList}
              dayWiseList={getDaysInMonth(month, year)}
              cancelComplaintList={complaintData.dayWise.cancel}
              pendingComplaintList={complaintData.dayWise.pending}
              closeComplaintList={complaintData.dayWise.close}
              allComplaintList={complaintData.dayWise.all}
              className="w-11/12 mx-auto"
            />
          </>
        )}
        {complaintData.total <= 0 && (
          <Alert
            type="info"
            showIcon={true}
            message={
              "There are no complaint added by this user. Please try again later."
            }
            className="text-md"
          />
        )}
      </div>
    </div>
  );
};

export default ComplaintsByUser;
