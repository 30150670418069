import member from "./member";
import moment from "moment";
export const fetchRecentcomplaints = async (pageNo, limit) => {
  try {
    const { data } = await member(
      `/complaint/fetchComplaints?pageNo=${pageNo}&limit=${limit}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const fetchAllcomplaints = async (month, year) => {
  try {
    const { data } = await member(
      `/complaint/fetchallcomplaints?month=${month}&year=${year}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const fetchDateWiseComplaints = async (month, year) => {
  try {
    const { data } = await member(
      `/complaint/fetchDateWiseComplaints?month=${month}&year=${year}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const fetchComplaintsByUser = async (email, month, year) => {
  try {
    const formattedMonth = month < 10 ? `0${month}` : month;
    const { data } = await member(
      `/complaint/fetchComplaintsByUser/${email}?month=${formattedMonth}&year=${year}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const fetchComplaintsForStatus = async (month, year) => {
  try {
    const { data } = await member(
      `/complaint/fetchComplaintsForStatus?month=${month}&year=${year}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getfetchCountForForwTodays = async (state) => {
  try {
    const { data } = await member(
      `/complaint/fetchCountForForwTodays?state=${state}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const updateComplaint = async (complaintId, fromData) => {
  try {
    const { data } = await member.put(
      `/complaint/updatecomplaint/${complaintId}`,
      fromData
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getComplaint = async (cid) => {
  try {
    const { data } = await member(`/complaint/searchById/${cid}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const deleteComplaint = async (complaintId) => {
  try {
    const { data } = await member.delete(
      `/complaint/deletecomplaint/${complaintId}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const searchComplaint = async (query) => {
  try {
    const { data } = await member(`/post/search?title=${query}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const searchByState = async (obj) => {
  try {
    const { data } = await member.post("/complaint/state", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const searchByGlobleState = async ({
  stateList,
  startDate,
  endDate,
  status,
}) => {
  console.log("Search");
  try {
    const { data } = await member.post("/complaint/globlestate", {
      stateList,
      startDate,
      endDate,
      status,
    });

    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const searchByPhoneNo = async (query) => {
  try {
    const { data } = await member(
      `/complaint/searchByPhoneNo?mobileNo=${query}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const searchByMobileNoEntry = async (query, month) => {
  try {
    const { data } = await member(
      `/complaint/searchByMobileNoEntry?mobileNo=${query}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const searchByCompany = async (query) => {
  try {
    const { data } = await member(
      `/complaint/searchByCompany?brandName=${query}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const search = async (query, month, year) => {
  try {
    const { data } = await member(
      `/complaint/search?query=${query}&month=${month}&year=${year}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const searchByEntry = async (query, month, year) => {
  try {
    const { data } = await member(
      `/complaint/searchByEntry?query=${query}&month=${month}&year=${year}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const fetchComplaintByLastHours = async (query) => {
  try {
    const { data } = await member(
      `/complaint/fetchComplaintsLastHours?hours=${query}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const fetchCountForCloseToday = async (email, state) => {
  try {
    const { data } = await member(
      `/complaint/fetchCountForCloseToday?email=${email}&state=${state}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const fetchComplaintsDayWise = async () => {
  try {
    const { data } = await member(`/complaint/fetchComplaintsDayWise`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const deleteMember = async (memberId) => {
  try {
    const { data } = await member.delete(`/auth/deleteMember/${memberId}`);

    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const fetchTopComplaints = async () => {
  try {
    const { data } = await member(`/complaint/top-complaints`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const fetchTopComplaintsBrandWise = async ({ dateRange }) => {
  try {
    const fromDate = moment(dateRange[0].$d).format("DD-MM-YYYY");
    const toDate = moment(dateRange[1].$d).format("DD-MM-YYYY");

    const { data } = await member(
      `/complaint/top-50-brandwise-complaints?fromDate=${fromDate}&toDate=${toDate}`
    );
    return data;
  } catch (error) {
    console.error("Error fetching top complaints:", error);
    throw error;
  }
};

export const fetchTotalComplaintsByMobile = async (mobileNo) => {
  try {
    const { data } = await member.get(
      `/complaint/fetchTotalComplaintsByMobile/${mobileNo}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getMembers = async () => {
  try {
    const { data } = await member(`/auth/getmembers`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getMember = async (mid) => {
  try {
    const { data } = await member(`/auth/getmember/${mid}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getNumberByState = async (query) => {
  try {
    const number = await member(`/auth/getNumberByState?state=${query}`);
    return number;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getNumberForwarding = async (query) => {
  try {
    const data = await member(`/auth/getNumberForwarding`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getMemberByEmail = async (query) => {
  try {
    const { data } = await member(`/auth/getMemberByEmail?email=${query}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const updateMember = async (memberId, fromData) => {
  try {
    const { data } = await member.put(
      `/auth/updateMember/${memberId}`,
      fromData
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

// api/complaint.js

export const fetchCompanyList = async () => {
  try {
    const response = await member.get("/api/getallCompanies");
   return response.data
  } catch (error) {
    console.error("Error fetching company list:", error);
  }
};
export const getCompanyData = (companyName, startDate, endDate) => {
  return member.post('/api/getCompanyData', {
    companyName,
    startDate,
    endDate,
  });
};




