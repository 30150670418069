import member from "./member";

export const fetchAllTechnician = async () => {
  try {
    const response = await member.get("/technician/getAllTechnicians");
    return response.data;
  } catch (error) {
    console.log("error to find all technician: ", error);
  }
};

export const fetchCitiesAsPerState = async (state) => {
  try {
    // Send state as a query parameter
    const response = await member.get(
      `/technician/fetchcities?state=${encodeURIComponent(state)}`
    );

    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("error fetching cities: ", error);
    return [];
  }
};

export const fetchMember = async () => {
  try {
    const response = await member.get("/auth/fetchMember");
    return response.data;
  } catch (error) {
    console.log("error fetching members: ", error);
    return [];
  }
};
//fatching transection by technician id perticular one
export const fetchTransactions = async (technicianId) => {
  try {
    const response = await member.get(
      "techniciantransection/getTechnicianTransections/:technicianId"
    );
    return response.data;
  } catch (error) {
    console.log("error fetching transection: ", error);
    return [];
  }
};
