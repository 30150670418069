import React, { useEffect, useState } from "react";
import ComplaintList from "./ComplaintList";
import {
  search,
  fetchComplaintByLastHours,
  fetchAllcomplaints,
  searchByPhoneNo,
} from "../api/complaint";
import { CSVLink } from "react-csv";
import { AiOutlineSearch } from "react-icons/ai";
import loadingImg from "../images/loading.gif";
import state from "./stateData";
import defaultLabels from "./excelFileds/Default";
import headerPending from "./excelFileds/Pending";
import { Link } from "react-router-dom";

export const defaultComplaint = {
  partyName: "",
  address: "",
  pincode: "",
  state: "",
  city: "",
  mobileNo: "",
  plumbingNo: "",
  brandName: "",
  workDone: "",
  repeat: "",
  syphoneColor: "",
};

const ViewComplaint = (props) => {
  const [query, setQuery] = useState("");
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleQuerySearchMobileNo = async (e) => {
    setLoading(true);
    const data = await searchByPhoneNo(query);
    setComplaints(data);
    setLoading(false);
  };

  const handleSearch = async (e) => {
    setLoading(true);
    const data = await search(query, props.month, props.year);
    setComplaints(data);
    setLoading(false);
    // localStorage.setItem("query", query);
  };

  const handleSearchForAll = (e) => {
    if (!isNaN(query)) {
      handleQuerySearchMobileNo();
    } else {
      handleSearch();
    }
  };

  const handleComplaintByLastHours = async (e) => {
    setLoading(true);
    const { value } = e.target;
    const data = await fetchComplaintByLastHours(value);
    setComplaints(data.complaints);
    setLoading(false);
  };

  const getComplaints = async () => {
    setLoading(true);
    const data = await fetchAllcomplaints(props.month, props.year);
    setComplaints(data);
    setLoading(false);
  };

  const getComplaintStatus = async () => {
    let opeX = 0,
      cloX = 0,
      visX = 0,
      canX = 0,
      repX = 0,
      cerX = 0,
      othX = 0,
      ppX = 0,
      scX = 0,
      sidebarOpen = 0,
      cbc = 0;
    props.setPendingComplaintsFD([]);
    props.setOpenComplaints([]);
    props.setClosedComplaintsFD([]);
    props.setVisitOkComplaintsFD([]);
    props.setCancelComplaintsFD([]);
    props.setRepeatComplaintsFD([]);
    props.setCeramicIssueComplaintsFD([]);
    props.setOtherIssueComplaintsFD([]);
    props.setPartPendingComplaintsFD([]);
    props.setSeatChangeComplaintsFD([]);
    props.setCourierByCompany([]);
    props.setVisitOk(0);
    props.setCancel(0);
    props.setClosed(0);
    props.setRepeat(0);
    props.setPending(0);
    props.setOpen(0);
    props.setCourierByCompanyCount(0);
    props.setCeramicIssue(0);
    props.setSeatChangeFD(0);
    props.setOtherIssue(0);
    props.setPartPendingFD(0);
    complaints.map((complaint) => {
      if (complaint.workDone === "Yes" || complaint.workDone === "Work Done") {
        cloX++;
        props.setClosed(cloX);
        props.setClosedComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.repeat === "Yes" && complaint.workDone === "No") {
        repX++;
        props.setRepeat(repX);
        props.setRepeatComplaintsFD((prev) => [...prev, complaint]);
        opeX++;
        props.setPending(opeX);
        props.setPendingComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "No") {
        opeX++;
        props.setPending(opeX);
        props.setPendingComplaintsFD((prev) => [...prev, complaint]);
        sidebarOpen++;
        props.setOpen(sidebarOpen);
        props.setOpenComplaints((prev) => [...prev, complaint]);
      }
      if (complaint.workDone === "Visit Ok") {
        visX++;
        props.setVisitOk(visX);
        props.setVisitOkComplaintsFD((prev) => [...prev, complaint]);
        opeX++;
        props.setPending(opeX);
        props.setPendingComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Cancel") {
        canX++;
        props.setCancel(canX);
        props.setCancelComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Ceramic Issue") {
        cerX++;
        props.setCeramicIssue(cerX);
        props.setCeramicIssueComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Other Issue") {
        othX++;
        props.setOtherIssue(othX);
        props.setOtherIssueComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Part Pending") {
        ppX++;
        props.setPartPendingFD(ppX);
        props.setPartPendingComplaintsFD((prev) => [...prev, complaint]);
        opeX++;
        props.setPending(opeX);
        props.setPendingComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Seat Change") {
        scX++;
        props.setSeatChangeFD(scX);
        props.setSeatChangeComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Courier By Company") {
        cbc++;
        props.setCourierByCompanyCount(cbc);
        props.setCourierByCompany((prev) => [...prev, complaint]);
      }
    });
  };

  const addSerialNo = (toConvertData) => {
    var data = toConvertData.map((item) => ({
      ...item,
      plumbingNo: `"${item.plumbingNo}"`, // convert value to string and add quotes
    }));
    let finalData = [];
    let count = 1;
    data.map((item) => {
      finalData.push(Object.assign(item, { srNo: count++ }));
    });
    return finalData;
  };

  useEffect(() => {
    getComplaints();
  }, []);

  useEffect(() => {
    getComplaintStatus();
  }, [complaints]);

  useEffect(() => {
    handleSearch();
  }, [props.month, props.year]);

  return (
    <div
      className="w-[80vw] nunito-font h-full bg-[#f1f5f9]"
      style={{ height: "100%" }}
    >
      <div className="ml-8">
        <div className="flex justify-between  items-center">
          <h1 className="text-xl font-medium mt-8  border-b-2 border-gray-300">
            Complaints
          </h1>

        
          <div className="flex  items-center">
            <button
              onClick={() => {
                props.setPendingComplaintsFD(
                  addSerialNo(props.pendingComplaintsFD)
                );
              }}
              className="mr-5"
            >
              <CSVLink
                data={props.pendingComplaintsFD}
                headers={headerPending}
                className="bg-[#a6df6cb5] p-2 rounded-xl"
              >
                Pending
              </CSVLink>
            </button>
            <button
              onClick={() => {
                setComplaints(addSerialNo(complaints));
              }}
            >
              <CSVLink
                data={complaints}
                headers={defaultLabels}
                className="bg-[#a6df6cb5] p-2 rounded-xl"
              >
                Export to CSV
              </CSVLink>
            </button>
            <div className="rounded-xl flex ml-5  ">
              <input
                onChange={(e) => setQuery(e.target.value)}
                className="border border-gray-100 rounded-l-xl focus:outline-none focus:border-indigo-700  w-full text-base text-gray-500 bg-white-100 pl-2 py-2 "
                value={query}
                type="text"
                placeholder="Search here..."
              />
              <button
                className="bg-slate-300 p-2 rounded-r-xl"
                onClick={handleSearchForAll}
              >
                <AiOutlineSearch size={20} />
              </button>
            </div>

            <select
              id="timeline"
              className=" appearance-none rounded-xl border border-gray-300 focus:border-[#717984] focus:bg-white focus:ring-1 focus:ring-[#717984] text-base outline-none text-gray-700  p-2 transition-colors duration-200 ease-in-out mx-2"
              onChange={handleComplaintByLastHours}
            >
              <option value="Default">Choose Time Period</option>
              <option value="24">24 Hours</option>
              <option value="48">48 Hours</option>
              <option value="72">72 Hours</option>
            </select>

            <select
              id="timeline"
              className="appearance-none rounded-xl border border-gray-300 focus:border-[#717984] focus:bg-white focus:ring-1 focus:ring-[#717984] text-base outline-none text-gray-700  p-2 transition-colors duration-200 ease-in-out"
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            >
              <option value="Default">Choose State</option>
              {state.map((item) => (
                <option value={item.toUpperCase()}>{item.toUpperCase()}</option>
              ))}
            </select>
          </div>
        </div>
        <div
          className="overflow-hidden overflow-y-scroll  overflow-x-scroll"
          style={{ maxHeight: "580px", scrollbarWidth: "thin" }}
        >
          {!loading && <ComplaintList complaints={complaints} />}
        </div>
        {loading && (
          <div className="flex mt-40  justify-center">
            <img className="w-[400px]" src={loadingImg} alt="loading..." />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewComplaint;
