import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { MdOutlineDelete, MdOutlineDoDisturbOn } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { deleteMember } from "../api/complaint";
import member from "../api/member";

const Users = ({ socket }) => {
  let navigate = useNavigate();

  const [members, setMembers] = useState([{}]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const fetchMembers = async () => {
    const { data } = await member.get("/auth/getMembersDetails");
    setMembers(data);
  };

  let doj;
  const handleDateFormate = (date) => {
    const newDate = new Date(date);
    const dateString = newDate.toLocaleDateString("en-us", {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
    });
    doj = dateString;
  };

  const handleDelete = async (memberId) => {
    const confirmed = window.confirm("Are you sure!");
    if (!confirmed) return;
    await deleteMember(memberId);

    const newMembers = members.filter((p) => p._id !== memberId);
    setMembers(newMembers);
  };

  // Function to check if a member is online
  const isMemberOnline = (memberId) => {
    return onlineUsers[memberId] ? onlineUsers[memberId].loginCount : 0;
  };

  // const handleLogout = (userId) => {
  //   const confirmed = window.confirm(
  //     "Are you sure you want to log out this user?"
  //   );
  //   if (confirmed) {
  //     // Emit a socket event to log out the user
  //     socket.emit("admin_logout", { userId });
  //   }
  // };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/signin");
    }
    fetchMembers();

    socket.emit("admin_join");
    // Listen for updates to get real-time information about user status
    socket.on("online_users_update", (onlineUserIds) => {
      setOnlineUsers(onlineUserIds);
    });
  }, []);

  const commonClass =
    "px-4 py-4 font-medium text-lg bg-white text-black  whitespace-nowrap ";

  return (
    <div className="w-[80vw] nunito-font  h-full bg-[#F1F5F9]" style={{height:"740px"}}>
      <section className="px-5 w-full pt-2">
        <div className="flex justify-between ">
          <h2 className="text-xl ">Users Details</h2>
          <Link
            to={"/users/addMember"}
            className="flex bg-[#164D62] p-2 rounded-xl text-white"
          >
            <AiOutlinePlus className="text-2xl" />
            <span>Add New Member</span>
          </Link>
        </div>
        <div className=" sm:rounded-lg mt-5">
          <table className="w-[1200px] text-sm text-left text-gray-500 ">
            <thead className="text-sm text-black uppercase bg-[#F1F5F9] ">
              <tr className="">
                <th scope="col" className="px-4 py-4">
                  Name
                </th>
                <th scope="col" className="px-4 py-3">
                  Email
                </th>
                <th scope="col" className="px-4 py-3">
                  Role
                </th>
                <th scope="col" className="px-4 py-3">
                  Phone No.
                </th>
                <th scope="col" className="px-4 py-3">
                  DOJ
                </th>
                <th scope="col" className="px-4 py-3">
                  Status
                </th>
                <th scope="col" className="px-4 pl-16 py-3">
                  Action
                </th>
              </tr>
              <div className="mb-5" />
            </thead>

            <tbody>
              {members.map((member, index) => (
                <>
                  {handleDateFormate(member.date)}
                  <tr
                    key={index}
                    className=" border-b  overflow-x-auto shadow-sm"
                  >
                    <th
                      scope="row"
                      className={`${commonClass} text-transform: capitalize`}
                    >
                      {member.name}
                    </th>
                    <td className={`${commonClass} `}>{member.email}</td>
                    <td className={`${commonClass} text-transform: capitalize`}>
                      {member?.role}
                    </td>
                    <td className={`${commonClass} `}>{member.phone}</td>
                    <td className={`${commonClass}`}>{doj}</td>
                    <td className={`${commonClass}`}>
                      {isMemberOnline(member._id) > 0 ? (
                        <div className="flex items-center space-x-2">
                          <span className="text-green-600">
                            Online ({isMemberOnline(member._id)})
                          </span>
                          {/* <MdOutlineDoDisturbOn
                            color="red"
                            className=" cursor-pointer"
                            onClick={() => handleLogout(member._id)}
                          /> */}
                        </div>
                      ) : (
                        <span className="text-gray-600">Offline</span>
                      )}
                    </td>
                    <td className={`${commonClass} flex space-x-4`}>
                      <Link
                        to={`/users/updateMember/${member._id}`}
                        className="flex items-center cursor-pointer "
                      >
                        <TbEdit className="text-gray-500" size={19} />
                        <span className="text-gray-500 ml-1 font-semibold">
                          Edit
                        </span>
                      </Link>
                      <button
                        onClick={() => handleDelete(member._id)}
                        className="flex  items-center cursor-pointer"
                      >
                        <MdOutlineDelete className="text-red-500" size={20} />
                        <span className="text-red-500 ml-1 font-semibold">
                          Delete
                        </span>
                      </button>
                    </td>
                  </tr>
                  <div className="mb-5" />
                </>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default Users;
