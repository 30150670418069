import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./components/Home";
import SignIn from "./components/SignIn";
import AdminHome from "./adminComponents/AdminHome";
import UpdateComplaint from "./adminComponents/UpdateComplaint";
import ViewComplaint from "./adminComponents/ViewComplaints";
import ViewComplaintDetails from "./adminComponents/ViewComplaintDetails";
import ViewComplaintDetailsByForwardinMember from "./userComponents/ViewComplaintDetails";
import Users from "./adminComponents/Users";
import AddMember from "./adminComponents/AddMember";
import UpdateMember from "./adminComponents/UpdateMember";
import UserAdminHome from "./userComponents/UserAdminHome";
import SlideBar from "../src/userComponents/SlideBar";
import Navbar from "./adminComponents/Navbar";
import ViewByStates from "./userComponents/ViewByStates";
import ViewComplaints from "./userComponents/ViewComplaints";
import UpdateComplaintByUser from "./userComponents/updateComplaint";
import UserNavbar from "./components/Navbar";
import ViewComplaintInDetailByUser from "./components/ViewComplaintInDetailByUser";
import SlidebarAdmin from "../src/adminComponents/SideBar";
import moment from "moment";
import State from "./adminComponents/State";
import Product from "./adminComponents/Product";
import Remark from "./adminComponents/Remark";
import TechnicianWork from "./adminComponents/TechnicianWork";
import DateWiseComplaint from "./adminComponents/DateWiseComplaint";
import WorkStatus from "./adminComponents/WorkStatus";
import io from "socket.io-client";
import Analytics from "./adminComponents/Analytics";
import { ComplaintProvider } from "./context/complaintContext";
import { MessageProvider } from "./context/messageContext";
import GlobleState from "./adminComponents/GlobleState";
import CompanyWiseAnalysis from "./adminComponents/CompanyWiseAnalysis";
import AddTechnician from "./userComponents/AddTechnician";
import AddTransection from "./userComponents/AddTransection";
import TechnicianHome from "./adminComponents/TechnicianHome";

function App() {
  // For Forwarding member status
  const [closed, setClosed] = useState(0);
  const [visitOk, setVisitOk] = useState(0);
  const [pending, setPending] = useState(0);
  const [cancel, setCancel] = useState(0);
  const [total, setTotal] = useState(0);
  const [repeat, setRepeat] = useState(0);
  const [open, setOpen] = useState(0);
  const [partPendingFD, setPartPendingFD] = useState(0);
  const [ceramicIssue, setCeramicIssue] = useState(0);
  const [otherIssue, setOtherIssue] = useState(0);
  const [seatChangeFD, setSeatChangeFD] = useState(0);
  const [courierByCompanyCount, setCourierByCompanyCount] = useState(0);
  const [ceramicIssueComplaintsFD, setCeramicIssueComplaintsFD] = useState([]);
  const [courierByCompany, setCourierByCompany] = useState([]);
  const [partPendingComplaintsFD, setPartPendingComplaintsFD] = useState([]);
  const [otherIssueComplaintsFD, setOtherIssueComplaintsFD] = useState([]);
  const [seatChangeComplaintsFD, setSeatChangeComplaintsFD] = useState([]);
  const [closedComplaintsFD, setClosedComplaintsFD] = useState([]);
  const [visitOkComplaintsFD, setVisitOkComplaintsFD] = useState([]);
  const [pendingComplaintsFD, setPendingComplaintsFD] = useState([]);
  const [openComplaints, setOpenComplaints] = useState([]);
  const [cancelComplaintsFD, setCancelComplaintsFD] = useState([]);
  const [repeatComplaintsFD, setRepeatComplaintsFD] = useState([]);
  const [totalComplaintsFD, setTotalComplaintsFD] = useState([]);

  // for selecting month so anyone can fetch complaint of that month
  const [month, setMonth] = useState(moment().month() + 1);
  const [year, setYear] = useState(moment().year());

  // for admin dashboard count
  const [isDashboard, setIsDashboard] = useState(false);

  // for entry of complaint
  const [searchResultOfMobileNo, setSearchResultOfMobileNo] = useState([]);

  // let socket = io("http://localhost:5001");
  let socket = io("https://ideal-yb257.ondigitalocean.app");
  var role = localStorage.getItem("role");
  return (
    <ComplaintProvider>
      <MessageProvider>
        <Router>
          <Routes>
            {/* Entry Routes */}
            <Route
              element={
                <>
                  <div className="w-full nunito-font h-full bg-[#F1F5F9]">
                    <UserNavbar
                      setMonth={setMonth}
                      setYear={setYear}
                      month={month}
                      year={year}
                      searchResultOfMobileNo={searchResultOfMobileNo}
                      socket={socket}
                    />
                    <Outlet />
                  </div>
                </>
              }
            >
              <Route path="/signin" element={<SignIn socket={socket} />} />
              <Route
                path="/"
                element={
                  <Home
                    month={month}
                    year={year}
                    setSearchResultOfMobileNo={setSearchResultOfMobileNo}
                  />
                }
              />
              <Route
                path="/viewComplaintByUser/:cid"
                element={<ViewComplaintInDetailByUser />}
              />
            </Route>

            {/* Admin */}

            <Route
              element={
                <>
                  <div className="w-full nunito-font h-full bg-[#F1F5F9]">
                    <Navbar
                      setMonth={setMonth}
                      year={year}
                      setYear={setYear}
                      month={month}
                    />
                    <div className="flex flex-no-wrap">
                      <SlidebarAdmin
                        closed={closed}
                        pending={pending}
                        visitOk={visitOk}
                        cancel={cancel}
                        repeat={repeat}
                        open={open}
                        ceramicIssue={ceramicIssue}
                        otherIssue={otherIssue}
                        partPendingFD={partPendingFD}
                        seatChangeFD={seatChangeFD}
                        closedComplaintsFD={closedComplaintsFD}
                        pendingComplaintsFD={pendingComplaintsFD}
                        visitOkComplaintsFD={visitOkComplaintsFD}
                        repeatComplaintsFD={repeatComplaintsFD}
                        cancelComplaintsFD={cancelComplaintsFD}
                        openComplaints={openComplaints}
                        partPendingComplaintsFD={partPendingComplaintsFD}
                        ceramicIssueComplaintsFD={ceramicIssueComplaintsFD}
                        seatChangeComplaintsFD={seatChangeComplaintsFD}
                        otherIssueComplaintsFD={otherIssueComplaintsFD}
                        setIsDashboard={setIsDashboard}
                        setOpenComplaints={setOpenComplaints}
                        setVisitOkComplaintsFD={setVisitOkComplaintsFD}
                        setCeramicIssueComplaintsFD={
                          setCeramicIssueComplaintsFD
                        }
                        setOtherIssueComplaintsFD={setOtherIssueComplaintsFD}
                        setPartPendingComplaintsFD={setPartPendingComplaintsFD}
                        setSeatChangeComplaintsFD={setSeatChangeComplaintsFD}
                        setClosedComplaintsFD={setClosedComplaintsFD}
                        setRepeatComplaintsFD={setRepeatComplaintsFD}
                        setCancelComplaintsFD={setCancelComplaintsFD}
                        setCourierByCompanyCount={setCourierByCompanyCount}
                        setCourierByCompany={setCourierByCompany}
                        courierByCompany={courierByCompany}
                        courierByCompanyCount={courierByCompanyCount}
                      />
                      <Outlet />
                    </div>
                  </div>
                </>
              }
            >
              <Route
                path="/ideal-admin"
                element={
                  <AdminHome
                    month={month}
                    year={year}
                    isDashboard={isDashboard}
                  />
                }
              />
              <Route path="/ideal-admin/workStatus" element={<WorkStatus />} />
              <Route
                path="/view-complaints"
                element={
                  <ViewComplaint
                    setCancel={setCancel}
                    setClosed={setClosed}
                    setPending={setPending}
                    setRepeat={setRepeat}
                    setVisitOk={setVisitOk}
                    setOpen={setOpen}
                    setCeramicIssue={setCeramicIssue}
                    setSeatChangeFD={setSeatChangeFD}
                    setOtherIssue={setOtherIssue}
                    setCourierByCompanyCount={setCourierByCompanyCount}
                    setPartPendingFD={setPartPendingFD}
                    setCancelComplaintsFD={setCancelComplaintsFD}
                    setClosedComplaintsFD={setClosedComplaintsFD}
                    setPendingComplaintsFD={setPendingComplaintsFD}
                    setOpenComplaints={setOpenComplaints}
                    setVisitOkComplaintsFD={setVisitOkComplaintsFD}
                    setSeatChangeComplaintsFD={setSeatChangeComplaintsFD}
                    setRepeatComplaintsFD={setRepeatComplaintsFD}
                    setCeramicIssueComplaintsFD={setCeramicIssueComplaintsFD}
                    setOtherIssueComplaintsFD={setOtherIssueComplaintsFD}
                    setPartPendingComplaintsFD={setPartPendingComplaintsFD}
                    pendingComplaintsFD={pendingComplaintsFD}
                    setCourierByCompany={setCourierByCompany}
                    month={month}
                    year={year}
                  />
                }
              />
              <Route
                path="/view-details-complaint/:cid"
                element={<ViewComplaintDetails />}
              />
              <Route
                path="/update-complaint/:cid"
                element={<UpdateComplaint />}
              />
              <Route path="/ideal-admin/product" element={<Product />} />
              <Route path="/ideal-admin/remark" element={<Remark />} />
              <Route
                path="/ideal-admin/technicianWork"
                element={<TechnicianWork />}
              />
              <Route
                path="/ideal-admin/state"
                element={<State month={month} year={year} />}
              />
              <Route
                path="/ideal-admin/globlestate"
                element={<GlobleState />}
              />
              <Route
                path="/ideal-admin/companywisedata"
                element={<CompanyWiseAnalysis />}
              />

              <Route
                path="/ideal-admin/analytics"
                element={<Analytics month={month} year={year} />}
              />
              <Route
                path="/ideal-admin/dateWiseComplaint"
                element={<DateWiseComplaint month={month} year={year} />}
              />
              <Route
                path="/ideal-admin/technicianDetails&transaction"
                element={<TechnicianHome />}
              />

              {/* users */}
              <Route path="/users" element={<Users socket={socket} />} />
              <Route path="/users/addMember" element={<AddMember />} />
              <Route
                path="/users/updateMember/:mid"
                element={<UpdateMember />}
              />
            </Route>

            {/* Forwarding Member Routes */}
            <Route
              element={
                <>
                  <div className="w-full nunito-font h-full bg-[#F1F5F9]">
                    <Navbar
                      year={year}
                      setYear={setYear}
                      setMonth={setMonth}
                      month={month}
                    />
                    <div className="flex flex-no-wrap">
                      <SlideBar
                        closed={closed}
                        pending={pending}
                        ceramicIssue={ceramicIssue}
                        otherIssue={otherIssue}
                        cancel={cancel}
                        total={total}
                        open={open}
                        partPendingFD={partPendingFD}
                        seatChangeFD={seatChangeFD}
                        closedComplaintsFD={closedComplaintsFD}
                        pendingComplaintsFD={pendingComplaintsFD}
                        ceramicIssueComplaintsFD={ceramicIssueComplaintsFD}
                        otherIssueComplaintsFD={otherIssueComplaintsFD}
                        seatChangeComplaintsFD={seatChangeComplaintsFD}
                        partPendingComplaintsFD={partPendingComplaintsFD}
                        openComplaints={openComplaints}
                        cancelComplaintsFD={cancelComplaintsFD}
                        totalComplaintsFD={totalComplaintsFD}
                        setTotalComplaintsFD={setTotalComplaintsFD}
                        setOpenComplaints={setOpenComplaints}
                        setCeramicIssueComplaintsFD={
                          setCeramicIssueComplaintsFD
                        }
                        setOtherIssueComplaintsFD={setOtherIssueComplaintsFD}
                        setPartPendingComplaintsFD={setPartPendingComplaintsFD}
                        setSeatChangeComplaintsFD={setSeatChangeComplaintsFD}
                        setClosedComplaintsFD={setClosedComplaintsFD}
                        setCancelComplaintsFD={setCancelComplaintsFD}
                      />
                      <Outlet />
                    </div>
                  </div>
                </>
              }
            >
              <Route path="/user-admin" element={<UserAdminHome />} />
              <Route
                path="/user-admin/view-complaints"
                element={
                  <ViewComplaints
                    setCancel={setCancel}
                    setClosed={setClosed}
                    setPending={setPending}
                    setCeramicIssue={setCeramicIssue}
                    setOtherIssue={setOtherIssue}
                    setTotal={setTotal}
                    setOpen={setOpen}
                    setPartPendingFD={setPartPendingFD}
                    setSeatChangeFD={setSeatChangeFD}
                    closedComplaintsFD={closedComplaintsFD}
                    pendingComplaintsFD={pendingComplaintsFD}
                    ceramicIssueComplaintsFD={ceramicIssueComplaintsFD}
                    otherIssueComplaintsFD={otherIssueComplaintsFD}
                    cancelComplaintsFD={cancelComplaintsFD}
                    totalComplaintsFD={totalComplaintsFD}
                    setTotalComplaintsFD={setTotalComplaintsFD}
                    setCancelComplaintsFD={setCancelComplaintsFD}
                    setClosedComplaintsFD={setClosedComplaintsFD}
                    setPendingComplaintsFD={setPendingComplaintsFD}
                    setSeatChangeComplaintsFD={setSeatChangeComplaintsFD}
                    setOpenComplaints={setOpenComplaints}
                    setCeramicIssueComplaintsFD={setCeramicIssueComplaintsFD}
                    setOtherIssueComplaintsFD={setOtherIssueComplaintsFD}
                    setPartPendingComplaintsFD={setPartPendingComplaintsFD}
                    month={month}
                    year={year}
                  />
                }
              />
              <Route
                path="/user-admin/viewByStates"
                element={<ViewByStates />}
              />
              <Route
                path="/user-admin/add-technician"
                element={<AddTechnician />}
              />
              <Route
                path="/user-admin/add-transection"
                element={<AddTransection />}
              />

              <Route
                path="/user-admin/updateComplaint/:cid"
                element={<UpdateComplaintByUser />}
              />
              <Route
                path="/user-admin/view-details-complaint/:cid"
                element={<ViewComplaintDetailsByForwardinMember />}
              />
            </Route>
          </Routes>
        </Router>
      </MessageProvider>
    </ComplaintProvider>
  );
}

export default App;
