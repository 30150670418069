import React, { createContext } from "react";

const MessageContext = createContext();

const MessageProvider = ({ children }) => {
  const sendWhatsappMsg = async (grpId, msg) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("token", "sopq0skd7jj8c233");
    urlencoded.append("to", grpId);
    urlencoded.append("body", msg);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://api.ultramsg.com/instance42164/messages/chat",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  const contextValues = { sendWhatsappMsg };

  return (
    <MessageContext.Provider value={contextValues}>
      {children}
    </MessageContext.Provider>
  );
};

export { MessageContext, MessageProvider };
