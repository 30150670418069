import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Select } from "antd";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const { Option } = Select;

const EditTransactionModal = ({
  isVisible,
  onCancel,
  onFinish,
  editingTransaction,
  technicians,
}) => {
  const [form] = Form.useForm();
  // const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    // console.log("editmodel console", editingTransaction);

    if (editingTransaction && technicians) {
      console.log("editing", moment(editingTransaction.date, "DD-MM-YYYY"));
      // Extract technician ID if it’s an object
      const technicianId =
        editingTransaction.technicianId?._id || editingTransaction.technicianId;

      // Find technician based on the extracted ID
      const technician = technicians.find((tech) => tech._id === technicianId);
      console.log("available technicians:", technicians);
      console.log("editing technician", technician);

      form.setFieldsValue({
        // date: editingTransaction.date
        //   ? moment(editingTransaction.date, "DD-MM-YYYY").toDate()
        //   : null,
        technicianId: technician ? technician._id : null,
        technician: technician ? editingTransaction.technician : null, // Adjust this if necessary
        amount: editingTransaction.amount,
        status: editingTransaction.status,
        remarks: editingTransaction.remarks,
      });

      console.log("form edit value", form.getFieldsValue());
    }
  }, [editingTransaction, form, technicians]);

  const handleFinish = (values) => {
    // Ensure date is in the proper format for your backend
    const formattedDate = values.date;

    onFinish({
      ...values,
      date: formattedDate,
    });
    form.setFieldsValue({
      // date: editingTransaction.date
      //   ? moment(editingTransaction.date, "DD-MM-YYYY")
      //   : null,
      technicianId: editingTransaction ? editingTransaction._id : null,
      technician: editingTransaction ? editingTransaction.technician : null, // Adjust this if necessary
      amount: editingTransaction.amount,
      status: editingTransaction.status,
      remarks: editingTransaction.remarks,
    }); // Clear the form fields after submission
  };

  const handleCancel = () => {
    onCancel();
    form.setFieldsValue({
      // date: editingTransaction.date
      //   ? moment(editingTransaction.date, "DD-MM-YYYY")
      //   : null,
      technicianId: editingTransaction ? editingTransaction._id : null,
      technician: editingTransaction ? editingTransaction.technician : null, // Adjust this if necessary
      amount: editingTransaction.amount,
      status: editingTransaction.status,
      remarks: editingTransaction.remarks,
    }); // Clear the form fields when the modal is closed
  };

  return (
    <Modal
      title="Edit Transaction"
      visible={isVisible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        className="font-size"
        layout="vertical"
        name="edit_transaction_form"
        onFinish={handleFinish}
      >
        {/* <Form.Item
          name="date"
          label="Date"
          rules={[{ required: true, message: "Please select the date!" }]}
        >
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd-MM-yyyy"
            placeholderText="Select Date"
            className="ant-input date-style"
          />
        </Form.Item> */}

        <Form.Item
          name="technician"
          label="Technician"
          rules={[{ required: true, message: "Please select a technician!" }]}
        >
          <Select
            placeholder="Select a technician"
            className="font-size"
            onChange={(value) => {
              const tech = technicians.find((tech) => tech._id === value);
              form.setFieldsValue({
                technician: tech ? tech.technicianName : "",
              });
            }}
          >
            {technicians.map((tech) => (
              <Option key={tech._id} value={tech._id}>
                {tech.technicianName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="amount"
          label="Amount"
          rules={[{ required: true, message: "Please input the amount!" }]}
        >
          <Input type="number" className="font-size" />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea className="font-size" />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: "Please select a status!" }]}
        >
          <Select className="font-size">
            <Option value="Done">Done</Option>
            <Option value="Pending">Pending</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            className="font-size"
            type="primary"
            style={{
              backgroundColor: "rgb(22 78 99 )",
              color: "white",
              borderColor: "rgb(59 130 246 / 50%)",
            }}
            htmlType="submit"
          >
            Edit Transaction
          </Button>
        </Form.Item>
      </Form>
      <style jsx>{`
        .font-size {
          font-size: 17px;
        }
        .date-style {
          width: 200px;
          height: 20px;
          backgroundcolor: "#4a4a4a";
        }
      `}</style>
    </Modal>
  );
};

export default EditTransactionModal;
