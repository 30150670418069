import React, { useEffect, useState, useContext } from "react";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { BsDownload } from "react-icons/bs";
import { CSVLink } from "react-csv";
import { ComplaintContext } from "../context/complaintContext";
import { Modal } from "antd";
import ViewComplaintInDetailByUser from "./ViewComplaintInDetailByUser";

const ComplaintListForUser = (props) => {
  const [complaints, setComplaints] = useState([]);
  const [closedComplaints, setClosedComplaints] = useState([]);
  const [pendingComplaints, setPendingComplaints] = useState([]);
  const [cancelComplaints, setCancelComplaints] = useState([]);
  const [ceramicIssueComplaints, setCeramicIssueComplaints] = useState([]);
  const [otherIssueComplaints, setOtherIssueComplaints] = useState([]);
  const [partPendingComplaints, setPartPendingComplaints] = useState([]);
  const [seatChangeComplaints, setSeatChangeComplaints] = useState([]);
  const [ceramicIssue, setCeramicIssue] = useState(0);
  const [otherIssue, setOtherIssue] = useState(0);
  const [closed, setClosed] = useState(0);
  const [seatChange, setSeatChange] = useState(0);
  const [partPending, setPartPending] = useState(0);
  const [pending, setPending] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [cancel, setCancel] = useState(0);
  const complaintContext = useContext(ComplaintContext);
  const { inViewComplaint, setInViewComplaint } = complaintContext;

  const handleTotalComplaint = async () => {
    let ope = 0,
      clo = 0,
      oth = 0,
      can = 0,
      cer = 0,
      pp = 0,
      sc = 0;
    setPendingComplaints([]);
    setClosedComplaints([]);
    setCeramicIssueComplaints([]);
    setCancelComplaints([]);
    setOtherIssueComplaints([]);
    setPartPendingComplaints([]);
    setSeatChangeComplaints([]);
    props.setPendingComplaintsEntry([]);
    setClosed(0);
    setPartPending(0);
    setPending(0);
    setOtherIssue(0);
    setCancel(0);
    setCeramicIssue(0);
    setSeatChange(0);

    complaints.map((complaint) => {
      if (complaint.workDone === "Yes" || complaint.workDone === "Work Done") {
        clo++;
        setClosed(clo);
        setClosedComplaints((c) => [...c, complaint]);
      } else if (
        (complaint.repeat === "Yes" && complaint.workDone === "No") ||
        complaint.workDone === "No" ||
        complaint.workDone === "Visit Ok" ||
        complaint.workDone === "Part Pending"
      ) {
        ope++;
        setPending(ope);
        setPendingComplaints((c) => [...c, complaint]);
        props.setPendingComplaintsEntry((c) => [...c, complaint]);
      }
      if (complaint.workDone === "Cancel") {
        can++;
        setCancel(can);
        setCancelComplaints((c) => [...c, complaint]);
      } else if (complaint.workDone === "Ceramic Issue") {
        cer++;
        setCeramicIssue(cer);
        setCeramicIssueComplaints((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Other Issue") {
        oth++;
        setOtherIssue(oth);
        setOtherIssueComplaints((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Part Pending") {
        pp++;
        setPartPending(pp);
        setPartPendingComplaints((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Seat Change") {
        pp++;
        setSeatChange(sc);
        setSeatChangeComplaints((prev) => [...prev, complaint]);
      }
    });
  };

  const addSerialNo = (toConvertData) => {
    var data = toConvertData.map((item) => ({
      ...item,
      plumbingNo: `"${item.plumbingNo}"`, // convert value to string and add quotes
    }));
    let finalData = [];
    let count = 1;
    data.map((item) => {
      finalData.push(Object.assign(item, { srNo: count++ }));
    });
    return finalData;
  };

  const handleOkCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (props.isSearch === true) {
      setComplaints(props.searchResult);
    }
    handleTotalComplaint();
  }, [props.searchResult]);

  useEffect(() => {
    if (props.isSearch === true) {
      setComplaints(props.searchResult);
    }
    handleTotalComplaint();
  }, [complaints]);

  const commonClass =
    "px-6 py-4 font-semibold text-base bg-white text-black  whitespace-nowrap";
  let statusColorArr = [
    "orange",
    "green",
    "violet",
    "red",
    "gray",
    "cyan",
    "fuchsia",
    "stone",
    "lime",
    "rose",
    "teal",
    "blue",
    "indigo",
    "purple",
    "pink",
  ];

  let status,
    statusColor = statusColorArr[9];
  const handleStatus = (complaint) => {
    let wd = complaint.workDone;

    if (wd === "Yes" || wd === "Work Done") {
      status = "Closed";
      statusColor = statusColorArr[0];
    } else if (wd === "No") {
      status = "Open";
      statusColor = statusColorArr[1];
    } else if (wd === "Visit Ok") {
      status = "Visit Ok";
      statusColor = statusColorArr[2];
    } else if (wd === "Cancel") {
      status = "Cancel";
      statusColor = statusColorArr[3];
    } else if (wd === "Ceramic Issue") {
      status = "Ceramic Issue";
      statusColor = statusColorArr[4];
    } else if (wd === "Other Issue") {
      status = "Other Issue";
      statusColor = statusColorArr[5];
    } else if (wd === "Part Pending") {
      status = "Part Pending";
      statusColor = statusColorArr[6];
    } else if (wd === "Seat Change") {
      status = "Seat Change";
      statusColor = statusColorArr[7];
    } else if (wd === "Courier By Company") {
      status = "Courier By Company";
      statusColor = statusColorArr[8];
    }
  };

  let headers = [
    { label: "NO", key: "srNo" },
    { label: "DATE", key: "date" },
    { label: "CUSTOMER NAME", key: "partyName" },
    { label: "ADDRESS", key: "address" },
    { label: "PINCODE", key: "pincode" },
    { label: "STATE", key: "state" },
    { label: "CITY", key: "city" },
    { label: "MOBILE NO", key: "mobileNo" },
    { label: "Office No", key: "plumbingNo" },
    { label: "BRAND NAME", key: "brandName" },
    { label: "ENTRY REMARK", key: "entryRemark" },
    { label: "FORWARDING REMARK", key: "remark" },
    { label: "REPEAT", key: "repeat" },
    { label: "STATUS", key: "workDone" },
    { label: "TOTAL PRODUCTS", key: "problem" },
    { label: "TEHCHNICIAN WORK", key: "solution" },
    { label: "PART PENDING DETAILS", key: "partPendingDetails" },
    { label: "CODE", key: "code" },
    { label: "COURIER", key: "courier" },
    { label: "SYPHONE COLOR", key: "syphoneColor" },
    { label: "CLOSING DATE", key: "closingDate" },
  ];

  return (
    <div className="w-full p-5">
      <div className="w-full">
        <Modal
          open={isOpen}
          onOk={handleOkCancel}
          onCancel={handleOkCancel}
          okButtonProps={{
            style: { backgroundColor: "blue", color: "white" },
          }}
          width="80%"
          footer={null}
        >
          <ViewComplaintInDetailByUser complaintInfo={inViewComplaint} />
        </Modal>
      </div>
      <div className=" sm:rounded-lg flex">
        <div className="flex flex-col">
          <div className="relative overflow-x-auto w-64 mb-5  text-white shadow-md sm:rounded-lg mr-5">
            <div className="relative overflow-x-auto  text-white shadow-md sm:rounded-lg">
              <table className=" table-fixed w-full">
                <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="pl-3 py-3 ">
                      Complaints
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Count
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Export
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                    <th
                      scope="row"
                      className="px-6 py-4  font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      Total
                    </th>
                    <td className=" text-black ml-10 dark:text-white px-6 py-3 text-right">
                      {complaints.length}
                    </td>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      <button
                        onClick={() => {
                          setComplaints(addSerialNo(complaints));
                        }}
                      >
                        <CSVLink data={complaints} headers={headers}>
                          <BsDownload />
                        </CSVLink>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="relative  overflow-x-auto w-64   text-white shadow-md sm:rounded-lg mr-5">
            <table className="table-fixed w-full">
              <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Count
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Export
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Open
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    {pending}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        setPendingComplaints(addSerialNo(pendingComplaints));
                      }}
                    >
                      <CSVLink data={pendingComplaints} headers={headers}>
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Ceramic Issue
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    {ceramicIssue}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        setCeramicIssueComplaints(
                          addSerialNo(ceramicIssueComplaints)
                        );
                      }}
                    >
                      <CSVLink data={ceramicIssueComplaints} headers={headers}>
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Other Issue
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right ">
                    {otherIssue}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        setOtherIssueComplaints(
                          addSerialNo(otherIssueComplaints)
                        );
                      }}
                    >
                      <CSVLink data={otherIssueComplaints} headers={headers}>
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Part Pending
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right ">
                    {partPending}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        setPartPendingComplaints(
                          addSerialNo(partPendingComplaints)
                        );
                      }}
                    >
                      <CSVLink data={partPendingComplaints} headers={headers}>
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Seat Change
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    {seatChange}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        setSeatChangeComplaints(
                          addSerialNo(seatChangeComplaints)
                        );
                      }}
                    >
                      <CSVLink data={seatChangeComplaints} headers={headers}>
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Closed
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    {closed}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        setClosedComplaints(addSerialNo(closedComplaints));
                      }}
                    >
                      <CSVLink data={closedComplaints} headers={headers}>
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>

                <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Canceled
                  </th>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    {cancel}
                  </td>
                  <td className="px-6 py-3 text-black dark:text-white text-right">
                    <button
                      onClick={() => {
                        setCancelComplaints(addSerialNo(cancelComplaints));
                      }}
                    >
                      <CSVLink data={cancelComplaints} headers={headers}>
                        <BsDownload />
                      </CSVLink>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-3/4">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-sm text-black uppercase bg-[#F1F5F9] ">
              <tr className="">
                <th scope="col" className="px-6 py-4">
                  Complaint No.
                </th>
                <th scope="col" className="px-6 py-4">
                  Party Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Brand Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Mobile No.
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 pl-16 py-3">
                  Action
                </th>
              </tr>
              <div className="mb-5" />
            </thead>

            <tbody>
              {complaints.map((complaint, index) => (
                <>
                  {handleStatus(complaint)}
                  <tr
                    key={index}
                    className=" border-b  overflow-x-auto shadow-sm"
                  >
                    <td className={`${commonClass}  `}>
                      {complaint.complaintId}
                    </td>
                    <th scope="row" className={`${commonClass}`}>
                      {complaint.partyName?.substring(0, 15)}
                      {complaint.partyName?.length > 15 ? "..." : ""}
                    </th>
                    <td className={`${commonClass}  `}>
                      {complaint.brandName}
                    </td>
                    <td className={`${commonClass} text-${statusColor}-500`}>
                      {status}
                    </td>
                    <td className={`${commonClass}  `}>{complaint.mobileNo}</td>
                    <td className={`${commonClass}`}>{complaint.date}</td>
                    <td className={`${commonClass} flex space-x-4`}>
                      <div
                        onClick={() => {
                          setInViewComplaint(complaint);
                          setIsOpen(true);
                        }}
                        className="flex items-center cursor-pointer"
                      >
                        <HiOutlineViewGridAdd
                          className="text-[#4bacc7]"
                          size={22}
                        />
                        <span className="text-[#4bacc7] ml-1 font-semibold">
                          View
                        </span>
                      </div>
                    </td>
                  </tr>
                  <div className="mb-5" />
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ComplaintListForUser;
