const defaultLabels = [
  { label: "NO", key: "srNo" },
  { label: "COMPLAINT NO", key: "complaintId" },
  { label: "DATE", key: "date" },
  { label: "CUSTOMER NAME", key: "partyName" },
  { label: "ADDRESS", key: "address" },
  { label: "PINCODE", key: "pincode" },
  { label: "STATE", key: "state" },
  { label: "CITY", key: "city" },
  { label: "MOBILE NO", key: "mobileNo" },
  { label: "BRAND NAME", key: "brandName" },
  { label: "TEHCHNICIAN NAME", key: "plumberName" },
  { label: "ENTRY REMARK", key: "entryRemark" },
  { label: "FORWARDING REMARK", key: "remark" },
  { label: "OFFICE NO", key: "plumbingNo" },
  { label: "STATUS", key: "workDone" },
  { label: "SYPHONE COLOR", key: "syphoneColor" },
  { label: "CODE", key: "code" },
  { label: "NOTE", key: "note" },
  { label: "STICKER", key: "sticker" },
  { label: "PAYMENT", key: "payment" },
  { label: "TOTAL PRODUCT", key: "problem" },
  { label: "TEHCHNICIAN WORK", key: "solution" },
  { label: "PART PENDING DETAILS", key: "partPendingDetails" },
  { label: "COURIER", key: "courier" },
  { label: "REPEAT", key: "repeat" },
  { label: "CLOSING DATE", key: "closingDate" },
];

export default defaultLabels;
