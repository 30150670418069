import member from "./member";

export const fetchAllWorkStatus = async () => {
  try {
    const { data } = await member(`/workStatus/fetchAllWorkStatus`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const deleteWorkStatus = async (workStatusId) => {
  try {
    const { data } = await member.delete(
      `/workStatus/deleteWorkStatus/${workStatusId}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
