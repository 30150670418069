import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getRandomLightColors } from "../../State/Helper/Helper";
import FetchList from "../../State/FetchList";
import DataTableModal from "../../../GlobalComponents/DataTableModal";
import { CSVLink } from "react-csv";

const LineChart = (props) => {
  const {
    dayWiseList,
    cancelComplaintList,
    pendingComplaintList,
    closeComplaintList,
    allComplaintList,
    complaintList,
  } = props;

  const classes = props.className;

  const [visible, setVisible] = useState(false);
  const [smooth, setSmooth] = useState(true);
  const [labels, setLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState();

  const options = {
    plugins: {
      filler: {
        propagate: false,
      },
      title: {
        display: true,
        text: (ctx) => "Fill: " + ctx.chart.data.datasets[0].fill,
      },
    },
    interaction: {
      intersect: false,
    },
    elements: {
      line: {
        tension: smooth ? 0.4 : 0,
      },
    },
    plugins: {
      title: {
        display: true,
        text: (ctx) => "Point Style: " + ctx.chart.data.datasets[0].pointStyle,
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "No. of Complaints",
        data: chartData,
        borderColor: getRandomLightColors(1),
        fill: "start",
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };

  const chartDataHandler = (value) => {
    if (value === "cancel") {
      setChartData(cancelComplaintList);
      setDataSource(complaintList.cancel.complaints);
    } else if (value === "pending") {
      setChartData(pendingComplaintList);
      setDataSource(complaintList.pending.complaints);
    } else if (value === "close") {
      setChartData(closeComplaintList);
      setDataSource(complaintList.close.complaints);
    } else if (value === "all") {
      setChartData(allComplaintList);
      setDataSource(complaintList.all.complaints);
    }
  };

  const showModal = (record) => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const defaultLabels = [
    { label: "NO", key: "srNo" },
    { label: "COMPLAINT NO", key: "complaintId" },
    { label: "CUSTOMER NAME", key: "partyName" },
    { label: "MOBILE NO", key: "mobileNo" },
    { label: "BRAND NAME", key: "brandName" },
    { label: "STATE", key: "state" },
    { label: "CITY", key: "city" },
    { label: "STATUS", key: "workDone" },
    { label: "PAYMENT", key: "payment" },
    { label: "DATE", key: "date" },
    { label: "CLOSING DATE", key: "closingDate" },
  ];

  useEffect(() => {
    setIsLoading(false);
    setLabels(dayWiseList);
    setChartData(closeComplaintList);
    setDataSource(complaintList.close.complaints);
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="text-center mt-10">
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    );
  }

  const addSerialNo = (toConvertData) => {
    var data = toConvertData.map((item) => ({
      ...item,
      plumbingNo: `"${item.plumbingNo}"`, // convert value to string and add quotes
    }));
    let finalData = [];
    let count = 1;
    data.map((item) => {
      finalData.push(Object.assign(item, { srNo: count++ }));
    });
    return finalData;
  };

  return (
    <div className={classes}>
      <div className="flex justify-end gap-x-2 p-1">
        <button
          className="text-orange-900 cursor-pointer bg-orange-50 border-none font-semibold "
          onClick={() => {
            setDataSource(addSerialNo(dataSource));
          }}
        >
          <CSVLink className="px-5" data={dataSource} headers={defaultLabels}>
            Download
          </CSVLink>
        </button>

        <Button
          onClick={() => showModal(dataSource)}
          className="text-green-900 bg-green-50 border-none font-semibold px-5"
        >
          View
        </Button>
        <Button
          onClick={() => setSmooth((prevState) => !prevState)}
          className="text-blue-900 bg-blue-50 border-none font-semibold px-5"
        >
          {smooth ? "Linear" : "Smooth"}
        </Button>
        <div className="w-36">
          <FetchList
            list={["close", "pending", "cancel", "all"]}
            placeholder="Status"
            OnChangeHandler={chartDataHandler}
          />
        </div>
      </div>
      <Line data={data} options={options} />

      <DataTableModal
        title={`View Complaints`}
        visible={visible}
        onCancel={handleCancel}
        dataSource={dataSource || []}
      />
    </div>
  );
};

export default LineChart;
