import React, { useState, useEffect } from "react";
import { searchByGlobleState } from "../api/complaint";
import BadgeData from "./State/BadgeData";
import StateLineChart from "./State/Graphs/StateLineChart";
import Heading from "./State/Heading";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const stateList = [
  "ANDHRA PRADESH",
  "ARUNACHAL PRADESH",
  "ASSAM",
  "BIHAR",
  "CHATTISGARH",
  "CHANDIGARH",
  "DELHI",
  "GOA",
  "GUJARAT",
  "HARYANA",
  "HIMACHAL PRADESH",
  "JAMMU & KASHMIR",
  "JHARKHAND",
  "KARNATAKA",
  "KERALA",
  "MADHYA PRADESH",
  "MAHARASHTRA",
  "MANIPUR",
  "MEGHALAYA",
  "MIZORAM",
  "ODISHA",
  "PUNJAB",
  "PONDICHERRY",
  "RAJASTHAN",
  "SIKKIM",
  "TAMIL NADU",
  "TELANGANA",
  "TRIPURA",
  "UTTARAKHAND",
  "UTTAR PRADESH",
  "WEST BENGAL",
  "ANDAMAN & NICOBAR ISLANDS",
];

const GlobleState = () => {
  const defaultStartDate = moment().startOf("month");
  const defaultEndDate = moment().endOf("month");
  const [state, setState] = useState(stateList);
  const [complaintData, setComplaintData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [status, setStatus] = useState("totalComplaints");

  const fetchData = async ({ state, startDate, endDate, status }) => {
    setIsLoading(true);
    try {
      const data = await searchByGlobleState({
        state,
        startDate,
        endDate,
        status,
      });
      const processedData = processComplaintData(data);
      setComplaintData(processedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setComplaintData(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSetDateRange();
  }, []);

  const processComplaintData = (response) => {
    const { result, summary } = response;

    if (!result || !summary) {
      console.error("Invalid data structure:", response);
      return {};
    }

    const resultData = {
      stateWiseData: {},
      totalComplaintList: [],
      pendingComplaintList: [],
      closeComplaintList: [],
      totalPayments: [],
      averagePayments: [],
    };

    Object.keys(result).forEach((stateName) => {
      resultData.stateWiseData[stateName] = {
        totalComplaints: result[stateName].totalComplaints || 0,
        totalPendingComplaints: result[stateName].totalPendingComplaints || 0,
        totalClosedComplaints: result[stateName].totalClosedComplaints || 0,
        totalPayments: result[stateName].totalPayments || 0,
        avgPayment: result[stateName].avgPayment || 0,
      };
    });

    resultData.totalComplaintList = prepareComplaintList(
      resultData.stateWiseData,
      "totalComplaints"
    );
    resultData.pendingComplaintList = prepareComplaintList(
      resultData.stateWiseData,
      "totalPendingComplaints"
    );
    resultData.closeComplaintList = prepareComplaintList(
      resultData.stateWiseData,
      "totalClosedComplaints"
    );
    resultData.totalPayments = prepareComplaintList(
      resultData.stateWiseData,
      "totalPayments"
    );
    resultData.averagePayments = prepareComplaintList(
      resultData.stateWiseData,
      "avgPayment"
    );

    return resultData;
  };

  const prepareComplaintList = (stateWiseData, key) => {
    return Object.keys(stateWiseData).map((state) => ({
      state,
      value: stateWiseData[state][key] || 0,
    }));
  };

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleSetDateRange = () => {
    if (dateRange[0] && dateRange[1]) {
      fetchData({
        state,
        startDate: dateRange[0].format("DD-MM-YYYY"),
        endDate: dateRange[1].format("DD-MM-YYYY"),
        status,
      });
    }
  };

  return (
    <div className="w-full p-2 flex">
      <div className="App w-full px-10">
        <div className="mx-auto mt-3 px-4">
          <div className="flex justify-between align-middle p-1">
            <Heading className="text-2xl font-semibold text-gray-700 my-2 w-fit">
              All State Analytics
            </Heading>
          </div>
          <div className="p-2 w-fit">
            <BadgeData
              totalComplaints={
                complaintData
                  ? complaintData.totalComplaintList.reduce(
                      (acc, curr) => acc + curr.value,
                      0
                    )
                  : 0
              }
              pendingComplaints={
                complaintData
                  ? complaintData.pendingComplaintList.reduce(
                      (acc, curr) => acc + curr.value,
                      0
                    )
                  : 0
              }
              closeComplaints={
                complaintData
                  ? complaintData.closeComplaintList.reduce(
                      (acc, curr) => acc + curr.value,
                      0
                    )
                  : 0
              }
              totalPayments={
                complaintData
                  ? complaintData.totalPayments.reduce(
                      (acc, curr) => acc + curr.value,
                      0
                    )
                  : 0
              }
              averagePayments={
                complaintData && complaintData.averagePayments.length > 0
                  ? complaintData.averagePayments[0].value
                  : 0
              }
            />
          </div>
          <div className="w-full flex justify-end items-center mb-4">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateRangePicker
                startText="Start Date"
                endText="End Date"
                value={dateRange}
                onChange={handleDateChange}
                renderInput={(startProps, endProps) => (
                  <>
                    <input
                      {...startProps.inputProps}
                      placeholder="DD-MM-YYYY"
                      className="MuiInputBase-input"
                      value={startProps.inputProps.value || ""}
                      onChange={startProps.inputProps.onChange}
                    />
                    <input
                      {...endProps.inputProps}
                      placeholder="DD-MM-YYYY"
                      className="MuiInputBase-input"
                      value={endProps.inputProps.value || ""}
                      onChange={endProps.inputProps.onChange}
                    />
                  </>
                )}
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>
            <button
              className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleSetDateRange}
            >
              Set Date Range
            </button>
          </div>
          <div className="mb-8">
            {isLoading ? (
              <div className="flex justify-center items-center">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
                />
              </div>
            ) : (
              <StateLineChart
                data={complaintData ? complaintData.stateWiseData : {}}
                xLabel="State"
                yLabel="Count"
                stateList={stateList}
              />
            )}
          </div>
        </div>
        <style jsx>{`
          .MuiInputBase-input {
            height: 38px !important;
            padding: 4px;
            width: 120px;
            text-align: center;
            box-sizing: border-box;
          }
        `}</style>
      </div>
    </div>
  );
};

export default GlobleState;
