import React, { useEffect, useState } from "react";
import { getMember, searchByPhoneNo, search } from "../api/complaint";
import { AiOutlineSearch } from "react-icons/ai";
import { CSVLink } from "react-csv";
import ComplaintList from "./ComplaintList";
import { Link } from "react-router-dom";

export const defaultPost = {
  partyName: "",
  address: "",
  pincode: "",
  state: "",
  city: "",
  mobileNo: "",
  plumbingNo: "",
  brandName: "",
  workDone: "",
  sticker: "",
  repeat: "",
  syphoneColor: "",
  remark: "",
  problem: "",
  solution: "",
  plumberName: "",
  closingDate: "",
  entryRemark: "",
};
const getMemberRole = () => {
  const role = localStorage.getItem("role");
  return role;
};
var role = getMemberRole();
const ViewComplaints = (props) => {
  const [stateView, setStateView] = useState(true);
  const [query, setQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [openingDate, setOpeningDate] = useState("");
  const memberId = localStorage.getItem("memberId");

  const getMemberById = async (memberId) => {
    const { data } = await getMember(memberId);
    setStateList(data.states);
  };

  const getComplaintByState = async (state) => {
    const data = await search(state, props.month, props.year);
    setSearchResult(data);
    setIsSearch(true);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "Default") {
      setStateView(true);
      setSearchResult([]);
    } else {
      setStateView(false);
      getComplaintByState(value);
    }
    localStorage.setItem("search", value);
  };

  const handleTotalComplaint = async () => {
    let ope = 0,
      clo = 0,
      cer = 0,
      can = 0,
      oth = 0,
      pp = 0,
      sc = 0,
      sidebarOpen = 0;
    props.setPendingComplaintsFD([]);
    props.setOpenComplaints([]);
    props.setClosedComplaintsFD([]);
    props.setCeramicIssueComplaintsFD([]);
    props.setCancelComplaintsFD([]);
    props.setOtherIssueComplaintsFD([]);
    props.setPartPendingComplaintsFD([]);
    props.setSeatChangeComplaintsFD([]);
    props.setTotalComplaintsFD(searchResult);
    props.setTotal(searchResult.length);
    props.setClosed(0);
    props.setPending(0);
    props.setOpen(0);
    props.setSeatChangeFD(0);
    props.setCeramicIssue(0);
    props.setCancel(0);
    props.setOtherIssue(0);
    props.setPartPendingFD(0);
    searchResult.map((complaint) => {
      if (complaint.workDone === "No") {
        sidebarOpen++;
        props.setOpen(sidebarOpen);
        props.setOpenComplaints((prev) => [...prev, complaint]);
      }
      if (complaint.workDone === "Yes" || complaint.workDone === "Work Done") {
        clo++;
        props.setClosed(clo);
        props.setClosedComplaintsFD((prev) => [...prev, complaint]);
      } else if (
        (complaint.repeat === "Yes" && complaint.workDone === "No") ||
        complaint.workDone === "No" ||
        complaint.workDone === "Visit Ok" ||
        complaint.workDone === "Part Pending"
      ) {
        ope++;
        props.setPending(ope);
        props.setPendingComplaintsFD((prev) => [...prev, complaint]);
      }
      if (complaint.workDone === "Cancel") {
        can++;
        props.setCancel(can);
        props.setCancelComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Ceramic Issue") {
        cer++;
        props.setCeramicIssue(cer);
        props.setCeramicIssueComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Other Issue") {
        oth++;
        props.setOtherIssue(oth);
        props.setOtherIssueComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Part Pending") {
        pp++;
        props.setPartPendingFD(pp);
        props.setPartPendingComplaintsFD((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Seat Change") {
        sc++;
        props.setSeatChangeFD(sc);
        props.setSeatChangeComplaintsFD((prev) => [...prev, complaint]);
      }
    });
  };

  const handleQuerySearch = async () => {
    const data = await searchByPhoneNo(query);
    setIsSearch(true);
    setSearchResult(data);
  };

  const handleString = async (e) => {
    if (stateList.includes(query)) {
      const data = await search(query, props.month, props.year);
      setIsSearch(true);
      setSearchResult(data);
    } else {
      alert("Please select the state that has been assigned to you.");
    }
  };

  const handleSearchForAll = (e) => {
    if (!isNaN(query)) {
      handleQuerySearch();
    } else {
      handleString();
    }
    if (query.length >= 1) {
      localStorage.setItem("search", query);
    }
  };

  const addSerialNo = (toConvertData) => {
    var data = toConvertData.map((item) => ({
      ...item,
      plumbingNo: `"${item.plumbingNo}"`,
    }));
    let finalData = [];
    let count = 1;
    data.map((item) => {
      finalData.push(Object.assign(item, { srNo: count++ }));
    });
    return finalData;
  };

  useEffect(() => {
    handleTotalComplaint();
  }, [searchResult]);

  useEffect(() => {
    getMemberById(memberId);
  }, [memberId]);

  useEffect(() => {
    let search = localStorage.getItem("search");
    if (search) {
      setQuery(search);
      getComplaintByState(search);
    }
  }, [window.location.pathname]);

  let headerPending = [
    { label: "NO", key: "srNo" },
    { label: "DATE", key: "date" },
    { label: "CUSTOMER NAME", key: "partyName" },
    { label: "ADDRESS", key: "address" },
    { label: "PINCODE", key: "pincode" },
    { label: "CITY", key: "city" },
    { label: "MOBILE NO", key: "mobileNo" },
    { label: "OFFICE NO", key: "plumbingNo" },
    { label: "BRAND NAME", key: "brandName" },
    { label: "TEHCHNICIAN NAME", key: "plumberName" },
    { label: "PART PENDING DETAILS", key: "partPendingDetails" },
    { label: "STATUS", key: "workDone" },
    { label: "REPEAT", key: "repeat" },
    { label: "ENTRY REMARK", key: "entryRemark" },
    { label: "FORWARDING REMARK", key: "remark" },
    { label: "CODE", key: "code" },
    { label: "TOTAL PRODUCT", key: "problem" },
    { label: "TEHCHNICIAN WORK", key: "solution" },
    { label: "COURIER", key: "courier" },
  ];

  return (
    <div className="ml-8">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h1 className="text-xl w-44 font-medium mt-8 border-b-2 border-gray-300">
          Complaints
        </h1>
        <div className="flex items-center mt-4 md:mt-0">
          <button
            style={{
              marginRight: "15px",
              padding: "8px",
              borderRadius: "10px",
              backgroundColor: "#a6df6cb5",
            }}
          >
            <Link to={"/user-admin/add-technician"}>
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width={17}
                  height={17}
                >
                  <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" />
                </svg>

                <span className="ml-2">Add Technician</span>
              </div>
            </Link>
          </button>
          <button
            onClick={() => {
              props.setPendingComplaintsFD(
                addSerialNo(props.pendingComplaintsFD)
              );
            }}
            className="mr-5"
          >
            <CSVLink
              data={props.pendingComplaintsFD}
              headers={headerPending}
              className="bg-[#a6df6cb5] p-2 rounded-xl"
            >
              Pending
            </CSVLink>
          </button>
          {stateView && (
            <>
              <div className="rounded-xl flex ml-5  ">
                <input
                  onChange={(e) => setQuery(e.target.value)}
                  className="border border-gray-100 rounded-l-xl focus:outline-none focus:border-indigo-700  w-full text-base text-gray-500 bg-white-100 pl-2 py-2 "
                  type="text"
                  value={query}
                  placeholder="Search here "
                  onSubmit={handleSearchForAll}
                />
              </div>
              <button
                className="bg-slate-300 p-2 rounded-r-xl"
                onClick={handleSearchForAll}
              >
                <AiOutlineSearch size={20} />
              </button>
            </>
          )}
          <label
            htmlFor="sortBy"
            className="leading-7 text-lg text-gray-600 mr-3 ml-8"
          >
            Choose State
          </label>

          <select
            id="sortBy"
            onChange={handleChange}
            className="appearance-none rounded-xl border border-gray-300 focus:border-[#717984] focus:bg-white focus:ring-1 focus:ring-[#717984] text-base outline-none text-gray-700  p-2 transition-colors duration-200 ease-in-out"
          >
            <option value="Default">Default</option>
            {stateList.map((state) => (
              <option value={state}>{state}</option>
            ))}
          </select>
        </div>
      </div>
      <ComplaintList
        isSearch={isSearch}
        searchResult={searchResult}
        setOpeningDate={setOpeningDate}
        openingDate={openingDate}
      />
    </div>
  );
};

export default ViewComplaints;
