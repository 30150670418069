import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { searchByPhoneNo, searchByEntry } from "../api/complaint";
import ComplaintListForUser from "./ComplaintListForUser";
import { AiOutlineSearch } from "react-icons/ai";
import { CSVLink } from "react-csv";
import { MessageContext } from "../context/messageContext";

const Navbar = (props) => {
  const [searchResult, setSearchResult] = useState([]);
  const [pendingComplaintsEntry, setPendingComplaintsEntry] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [query, setQuery] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [isViewClicked, setIsViewClicked] = useState(false);
  let navigate = useNavigate();
  const messageContext = useContext(MessageContext);
  const { sendWhatsappMsg } = messageContext;

  const handleLogout = async () => {
    let email = localStorage.getItem("email");
    await sendWhatsappMsg(
      "120363141001385478@g.us",
      email + " has been logged out Successfully"
    );
    let memberId = localStorage.getItem("memberId");
    props.socket.emit("user_logout", memberId);
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("memberId");
    localStorage.removeItem("sortBy");
    localStorage.removeItem("stateVal");
    localStorage.removeItem("search");
    localStorage.removeItem("companyVal");
    localStorage.removeItem("role");
    navigate("/signin");
  };

  const handleSearchByPhone = async () => {
    const data = await searchByPhoneNo(query);
    setIsSearch(true);
    setSearchResult(data);
  };

  const handleString = async (e) => {
    const data = await searchByEntry(query, props.month, props.year);
    setIsSearch(true);
    setSearchResult(data);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!isNaN(query)) {
      await handleSearchByPhone();
    } else {
      await handleString();
    }
  };

  const addSerialNo = (toConvertData) => {
    var data = toConvertData.map((item) => ({
      ...item,
      plumbingNo: `"${item.plumbingNo}"`, // convert value to string and add quotes
    }));
    let finalData = [];
    let count = 1;
    data.map((item) => {
      finalData.push(Object.assign(item, { srNo: count++ }));
    });
    return finalData;
  };

  const handleMonthChange = (e) => {
    if (e.target.value === "previousMonth") {
      props.setMonth(getPreviousMonth());
    }
  };

  function getPreviousMonth() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    return previousMonth;
  }

  useEffect(() => {
    setSearchResult(props.searchResultOfMobileNo);
    if (props.searchResultOfMobileNo.length > 0) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
  }, [props.searchResultOfMobileNo]);

  useEffect(() => {
    if (window.location.pathname === "/signin") {
      setShowSearchBar(false);
    } else {
      setShowSearchBar(true);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (query.length < 1) {
      setIsSearch(false);
    }
  }, [query]);

  let headers = [
    { label: "NO", key: "srNo" },
    { label: "DATE", key: "date" },
    { label: "CUSTOMER NAME", key: "partyName" },
    { label: "ADDRESS", key: "address" },
    { label: "PINCODE", key: "pincode" },
    { label: "STATE", key: "state" },
    { label: "CITY", key: "city" },
    { label: "MOBILE NO", key: "mobileNo" },
    { label: "Office No", key: "plumbingNo" },
    { label: "BRAND NAME", key: "brandName" },
    { label: "ENTRY REMARK", key: "entryRemark" },
    { label: "FORWARDING REMARK", key: "remark" },
    { label: "STATUS", key: "workDone" },
    { label: "REPEAT", key: "repeat" },
    { label: "TOTAL PRODUCTS", key: "problem" },
    { label: "TEHCHNICIAN WORK", key: "solution" },
    { label: "PART PENDING DETAILS", key: "partPendingDetails" },
    { label: "CODE", key: "code" },
    { label: "COURIER", key: "courier" },
    { label: "SYPHONE COLOR", key: "syphoneColor" },
    { label: "CLOSING DATE", key: "closingDate" },
  ];

  let headersPending = [
    { label: "NO", key: "srNo" },
    { label: "DATE", key: "date" },
    { label: "CUSTOMER NAME", key: "partyName" },
    { label: "ADDRESS", key: "address" },
    { label: "PINCODE", key: "pincode" },
    { label: "CITY", key: "city" },
    { label: "STATE", key: "state" },
    { label: "MOBILE NO", key: "mobileNo" },
    { label: "OFFICE NO", key: "plumbingNo" },
    { label: "BRAND NAME", key: "brandName" },
    { label: "PART PENDING DETAILS", key: "partPendingDetails" },
    { label: "ENTRY REMARK", key: "entryRemark" },
    { label: "FORWARDING REMARK", key: "remark" },
    { label: "REPEAT", key: "repeat" },
    { label: "CODE", key: "code" },
    { label: "TOTAL PRODUCTS", key: "problem" },
    { label: "TEHCHNICIAN WORK", key: "solution" },
    { label: "COURIER", key: "courier" },
  ];
  return (
    <div className="nunito-font  top-0 z-50 ">
      <nav className="flex bg-[#000000] items-center justify-between flex-wrap p-4 ">
        <ul className="flex space-x-6 items-center text-white font-bold ">
          <li className="flex">
            <Link className="text-2xl " to="/">
              GIGWELL SERVICES
            </Link>
          </li>
        </ul>

        <div className="space-x-1 flex items-center">
          {showSearchBar && (
            <>
              <div className="mr-5 flex items-center">
                {isSearch && (
                  <>
                    <button
                      onClick={() => {
                        setPendingComplaintsEntry(
                          addSerialNo(pendingComplaintsEntry)
                        );
                      }}
                    >
                      <CSVLink
                        data={pendingComplaintsEntry}
                        headers={headersPending}
                        className="bg-[#86da32b5] rounded-xl p-2 px-4 text-lg mr-5"
                      >
                        Pending
                      </CSVLink>
                    </button>
                    <button
                      onClick={() => {
                        setSearchResult(addSerialNo(searchResult));
                      }}
                    >
                      <CSVLink
                        data={searchResult}
                        headers={headers}
                        className="bg-[#86da32b5] rounded-xl p-2 px-4 text-lg mr-5"
                      >
                        Export All
                      </CSVLink>
                    </button>
                  </>
                )}
                {/* temporary removed the access of other then current month */}
                <div className="mr-5 flex">
                  <select
                    name="month"
                    className=" border border-gray-100 focus:outline-none focus:border-indigo-700  w-full text-lg text-gray-500 bg-gray-100 py-0.5 px-3  appearance-none rounded-xl"
                    id="month"
                    onChange={handleMonthChange}
                  >
                    <option value={props.month}>Select Month</option>
                    <option value={"previousMonth"}>Previous Month</option>
                  </select>
                </div>
                {/*  <div className="mr-5 flex items-center ">
                  <select
                    name="year"
                    className=" border border-gray-100 focus:outline-none focus:border-indigo-700  w-full text-lg text-gray-500 bg-gray-100 py-0.5 px-3  appearance-none rounded-xl"
                    id="year"
                    onChange={handleYearChange}
                  >
                    <option value={props.year}>Select Year</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </select>
                </div> */}
                <form className="flex ">
                  <input
                    className="border border-gray-100 focus:outline-none focus:border-indigo-700  w-full text-sm text-gray-500 bg-gray-100 pl-4 py-2 rounded-l-xl "
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search here"
                    onSubmit={handleSearch}
                  />
                  <button
                    className="bg-slate-300 p-2 rounded-r-xl"
                    onClick={handleSearch}
                  >
                    <AiOutlineSearch size={20} />
                  </button>
                </form>
              </div>
            </>
          )}
          <ul className="flex space-x-6 items-center text-white font-bold ">
            <li>
              <Link to="/">Home</Link>
            </li>

            {!localStorage.getItem("token") ? (
              <li>
                <Link to="/signin">Sign in</Link>
              </li>
            ) : (
              <li>
                <button className="font-bold " onClick={handleLogout}>
                  Log out
                </button>
              </li>
            )}
          </ul>
        </div>
      </nav>

      {isSearch && showSearchBar && !isViewClicked && (
        <ComplaintListForUser
          isSearch={isSearch}
          searchResult={searchResult}
          setIsViewClicked={setIsViewClicked}
          setPendingComplaintsEntry={setPendingComplaintsEntry}
          pendingComplaintsEntry={pendingComplaintsEntry}
        />
      )}
    </div>
  );
};

export default Navbar;
