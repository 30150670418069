import React from "react";
import { TbEdit } from "react-icons/tb";
import { MdOutlineDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { deleteComplaint } from "../api/complaint";
import { HiOutlineViewGridAdd } from "react-icons/hi";

const ComplaintList = (props) => {
  const commonClass =
    "px-3 py-4 font-semibold text-sm bg-white text-black  whitespace-nowrap";

  let statusColorArr = [
    "orange",
    "green",
    "violet",
    "red",
    "gray",
    "cyan",
    "fuchsia",
    "stone",
    "lime",
    "rose",
    "teal",
    "blue",
    "indigo",
    "purple",
    "pink",
  ];
  let status, statusColor;
  const handleStatus = (complaint) => {
    let wd = complaint.workDone;

    if (wd === "Yes" || wd === "Work Done") {
      status = "Closed";
      statusColor = statusColorArr[0];
    } else if (wd === "No") {
      status = "Open";
      statusColor = statusColorArr[1];
    } else if (wd === "Visit Ok") {
      status = "Visit Ok";
      statusColor = statusColorArr[2];
    } else if (wd === "Cancel") {
      status = "Cancel";
      statusColor = statusColorArr[3];
    } else if (wd === "Ceramic Issue") {
      status = "Ceramic Issue";
      statusColor = statusColorArr[4];
    } else if (wd === "Other Issue") {
      status = "Other Issue";
      statusColor = statusColorArr[5];
    } else if (wd === "Part Pending") {
      status = "Part Pending";
      statusColor = statusColorArr[6];
    } else if (wd === "Seat Change") {
      status = "Seat Change";
      statusColor = statusColorArr[7];
    } else if (wd === "Courier By Company") {
      status = "Courier By Company";
      statusColor = statusColorArr[8];
    }
  };

  const handleDelete = async (complaintId) => {
    const confirmed = window.confirm("Are you sure!");
    if (!confirmed) return;
    const { error, message } = await deleteComplaint(complaintId);

    if (error) {
      console.log(error);
    }
    const newComplaint = props.complaints.filter((p) => p._id !== complaintId);
    props.setComplaints(newComplaint);
  };

  return (
    <>
      <div className="flex">
        <div className=" w-full ">
          <div className=" sm:rounded-lg mt-5">
            <table
              className="w-full text-sm mr-5 overflow-x-scroll text-left text-gray-500 "
              style={{ maxHeight: "600px", overflow: "hidden" }}
            >
              <thead className="text-sm text-black uppercase bg-[#F1F5F9] ">
                <tr className="">
                  <th scope="col" className="px-3 py-4">
                    Complaint No.
                  </th>
                  <th scope="col" className="px-3 py-4">
                    Customer Name
                  </th>
                  <th
                    scope="col"
                    className="px-3  py-3"
                    style={{ maxWidth: "150px" }}
                  >
                    Brand Name
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Phone No
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Technician Name
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-3 pl-16 py-3">
                    Action
                  </th>
                </tr>
                <div className="mb-5" />
              </thead>

              <tbody
                style={{
                  maxHeight: "600px",
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
              >
                {props.complaints.map((complaint, index) => (
                  <>
                    {handleStatus(complaint)}
                    <tr
                      key={index}
                      className=" border-b  overflow-x-auto shadow-sm"
                    >
                      <th scope="row" className={`${commonClass}`}>
                        {complaint.complaintId}
                      </th>
                      <th scope="row" className={`${commonClass}`}>
                        {complaint.partyName?.substring(0, 15)}
                        {complaint.partyName?.length > 15 ? "..." : ""}
                      </th>
                      <td
                        style={{ maxWidth: "180px" }}
                        className={`${commonClass}  `}
                      >
                        {complaint.brandName}
                      </td>
                      <td
                        className={`px-3 py-4 font-semibold text-sm bg-white   whitespace-nowrap text-${statusColor}-500 `}
                      >
                        {status}
                      </td>
                      <td className={`${commonClass}`}>{complaint.mobileNo}</td>
                      <td className={`${commonClass}`}>
                        {complaint.plumberName?.substring(0, 15)}
                        {complaint.plumberName?.length > 15 ? "..." : ""}
                      </td>
                      <td className={`${commonClass}`}>{complaint.date}</td>
                      <td className={`${commonClass} flex space-x-4`}>
                        <Link
                          to={`/view-details-complaint/${complaint._id}`}
                          className="flex items-center cursor-pointer"
                        >
                          <HiOutlineViewGridAdd
                            className="text-[#4bacc7]"
                            size={22}
                          />
                          <span className="text-[#4bacc7] ml-1 font-semibold">
                            View
                          </span>
                        </Link>
                        <Link
                          to={`/update-complaint/${complaint._id}`}
                          className="flex items-center cursor-pointer"
                        >
                          <TbEdit className="text-gray-500" size={19} />
                          <span className="text-gray-500 ml-1 font-semibold">
                            Edit
                          </span>
                        </Link>
                        <button
                          onClick={() => handleDelete(complaint._id)}
                          className="flex  items-center cursor-pointer"
                        >
                          <MdOutlineDelete className="text-red-500" size={20} />
                          <span className="text-red-500 ml-1 font-semibold">
                            Delete
                          </span>
                        </button>
                      </td>
                    </tr>
                    <div className="mb-5" />
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplaintList;
