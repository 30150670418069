import React from "react";
import ComplaintForm from "./ComplaintForm";

const Home = (props) => {
  return (
    <>
      <ComplaintForm
        month={props.month}
        year={props.year}
        setSearchResultOfMobileNo={props.setSearchResultOfMobileNo}
      />
    </>
  );
};

export default Home;
