import React, { useState, useEffect } from "react";
import { Table, Select, Button, notification, Menu, Dropdown } from "antd";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { fetchMember } from "../api/TechnicianApi";
import member from "../api/member";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { DownloadOutlined } from "@ant-design/icons";

const TechnicianTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [selectedAddedBy, setSelectedAddedBy] = useState(null);
  const [averageAmount, setAverageAmount] = useState(0);
  const [dateRange, setDateRange] = useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);
  const [uniqueAddedByList, setUniqueAddedByList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    fetchTransactionData();
    fetchUniqueMembers();
  }, [selectedAddedBy]);

  const fetchTransactionData = async () => {
    try {
      const response = await member.get(
        "/techniciantransection/getTransectionsByOnlyDateRange",
        {
          params: {
            startDate: dateRange[0].format("DD-MM-YYYY"),
            endDate: dateRange[1].format("DD-MM-YYYY"),
          },
        }
      );

      // Extract transactions from the response
      let { transactions } = response.data;

      // Apply AddedBy filter if selected
      if (selectedAddedBy) {
        transactions = transactions.filter(
          (tran) => tran.addedBy.name === selectedAddedBy
        );
      }

      // Set the filtered transactions to state
      setTransactions(transactions);

      // Calculate the total amount for the filtered transactions
      const totalAmount = transactions.reduce(
        (sum, tran) => sum + tran.amount,
        0
      );
      setTotalAmount(totalAmount);

      // Calculate the number of days in the selected date range
      const dateRangeDays = dateRange[1].diff(dateRange[0], "days") + 1;

      // Calculate the average amount per day within the date range
      const averageAmount =
        totalAmount > 0 && dateRangeDays > 0 ? totalAmount / dateRangeDays : 0;

      // Update the state with the calculated average amount
      setAverageAmount(averageAmount);
    } catch (error) {
      console.log("Error fetching transactions: ", error);
    }
  };

  const handleDownloadCSV = () => {
    if (transactions.length > 0) {
      const csv = Papa.unparse(
        transactions.map(({ _id, __v, technicianId, addedBy, ...rest }) => ({
          ...rest,
          addedBy: addedBy.name, // Include only the name from the addedBy field
        }))
      );
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `transactions_${moment().format("DDMMYYYY")}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      notification.warning({
        message: "No Data",
        description: "No transactions available for download.",
      });
    }
  };

  const handleDownloadPDF = () => {
    if (transactions.length > 0) {
      const doc = new jsPDF();

      if (selectedAddedBy) {
        doc.text(
          `Transaction Report as per date range: ${dateRange[0].format(
            "DD-MM-YYYY"
          )} to ${dateRange[1].format("DD-MM-YYYY")}`,
          0,
          10
        );
        doc.text(`Member: ${selectedAddedBy}`, 0, 20);
      } else {
        doc.text(
          `Transactions Report as per date range: ${dateRange[0].format(
            "DD-MM-YYYY"
          )} to ${dateRange[1].format("DD-MM-YYYY")}`,
          10,
          10
        );
      }

      doc.autoTable({
        startY: 25,
        margin: { left: 5 },
        head: [
          [
            "Sr. No",
            "Technician",
            "Created Date",
            "Modified",
            "Payment Date",
            "Amount",
            "Remarks",
            "Added By",
            "Payment Status",
          ],
        ],
        body: transactions.map((transaction, index) => [
          index + 1,
          transaction.technician,
          new Date(transaction.createdAt).toLocaleDateString(),
          new Date(transaction.modified).toLocaleDateString(),
          new Date(transaction.date).toLocaleDateString(),
          transaction.amount,
          transaction.remarks,
          transaction.addedBy.name,
          new Date(transaction.date).toLocaleDateString() ===
          new Date().toLocaleDateString()
            ? "Done"
            : "Pending",
        ]),
        margin: { left: 0 },
        styles: {
          fontSize: 10,
          marginLeft: 0,
          cellPadding: 2,
          headHeight: 10,
          lineColor: [0, 0, 0], // Cell border color (black)
          lineWidth: 0.1,
        },

        columnStyles: {
          0: {
            halign: "center",
            valign: "center",
            cellWidth: 10,
            // cellWidth: "wrap",
            fillColor: [232, 252, 245],
          },
          1: {
            halign: "center",
            valign: "center",
            cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          2: {
            halign: "center",
            valign: "center",
            // cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          3: {
            halign: "center",
            valign: "center",
            // cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          4: {
            halign: "center",
            valign: "center",
            // cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          5: {
            halign: "center",
            valign: "center",
            cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          6: { halign: "left", cellWidth: 30, fillColor: [232, 252, 245] },
          7: {
            halign: "center",
            valign: "center",
            cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
          8: {
            halign: "center",
            valign: "center",
            cellWidth: "wrap",
            cellWidth: 25,
            fillColor: [232, 252, 245],
          },
        },
      });

      doc.save(`transactions_${moment().format("YYYYMMDD")}.pdf`);
    } else {
      notification.warning({
        message: "No Data",
        description: "No transactions available for download.",
      });
    }
  };

  const fetchUniqueMembers = async () => {
    const members = await fetchMember();
    const uniqueMembers = [...new Set(members.map((member) => member.name))];
    setUniqueAddedByList(uniqueMembers);
  };

  const handleAddedByChange = (value) => {
    setSelectedAddedBy(value);
  };

  const handleDateRangeChange = (newValue) => {
    setDateRange(newValue);
    fetchTransactionData(dateRange);
  };

  const clearFilters = () => {
    setSelectedAddedBy(null);
    setDateRange([moment().startOf("month"), moment().endOf("month")]);
  };

  const downloadMenu = (
    <Menu>
      <Menu.Item key="pdf" onClick={handleDownloadPDF}>
        Download PDF
      </Menu.Item>
      <Menu.Item key="csv" onClick={handleDownloadCSV}>
        Download CSV
      </Menu.Item>
    </Menu>
  );
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>
          Total Amount: ₹{(totalAmount || 0).toFixed(2)}
        </div>

        <div
          style={{ fontSize: "18px", fontWeight: "bold", marginLeft: "20px" }}
        >
          Average Amount: ₹{(averageAmount || 0).toFixed(2)}
        </div>
        <Dropdown overlay={downloadMenu} placement="bottomLeft">
          <Button
            type="default"
            icon={<DownloadOutlined />}
            style={{
              marginLeft: "15px",
              backgroundColor: "rgb(22 78 99 / var(--tw-bg-opacity))",
              color: "white",
            }}
            className="mr-8"
          >
            Download
          </Button>
        </Dropdown>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateRangePicker
            startText="Start Date"
            endText="End Date"
            value={dateRange}
            onChange={handleDateRangeChange}
            format="DD-MM-YYYY"
            renderInput={(startProps, endProps) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  {...startProps.inputProps}
                  placeholder="DD-MM-YYYY"
                  style={{ height: 38, textAlign: "center", padding: "0 10px" }}
                />
                <span style={{ margin: "0 10px" }}>to</span>
                <input
                  {...endProps.inputProps}
                  placeholder="DD-MM-YYYY"
                  style={{ height: 38, textAlign: "center", padding: "0 10px" }}
                />
              </div>
            )}
          />
        </LocalizationProvider>

        <Select
          placeholder="Select Member"
          style={{
            width: 200,
            marginRight: 10,
            marginLeft: 10,
            backgroundColor: "lightgray",
          }}
          onChange={handleAddedByChange}
          value={selectedAddedBy || undefined}
        >
          {/* Default option for no filter */}
          <Select.Option key="no-filter" value="">
            Select Member
          </Select.Option>

          {/* Options for members */}
          {uniqueAddedByList.map((member) => (
            <Select.Option key={member} value={member}>
              {member}
            </Select.Option>
          ))}
        </Select>

        <Button
          onClick={clearFilters}
          type="primary"
          style={{ backgroundColor: "#0050b3" }}
        >
          Clear Filters
        </Button>
      </div>

      <Table
        dataSource={transactions}
        sticky
        scroll={{
          x: "max-content",
        }}
        style={{
          marginTop: "1px",
          width: "1180px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          overflow: "hidden",
          maxHeight: "600px",
          overflowX: "scroll",
          overflowY: "scroll",
          scrollbarWidth: "none",
        }}
        className="custom-table"
        columns={[
          {
            title: "Sr. No",
            key: "srNo",
            width: "80px",
            render: (text, record, index) => index + 1, // Serial number
            align: "center",
          },
          {
            title: "Technician",
            dataIndex: "technician",
            key: "technician",
            width: "130px",
            align: "center", // Center-align text in the column
          },
          {
            title: "Created Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date) => new Date(date).toLocaleDateString(), // Format date
            align: "center", // Center-align text in the column
          },
          {
            title: "Payment Date",
            dataIndex: "date",
            key: "date",
            render: (date) => new Date(date).toLocaleDateString(), // Format date
            align: "center", // Center-align text in the column
          },
          {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            align: "center", // Center-align text in the column
          },
          {
            title: "Added By",
            dataIndex: ["addedBy", "name"],
            key: "addedBy",
            align: "center",
            width: "100px",
            render: (text, record) =>
              record.addedBy ? record.addedBy.name : "N/A",
          },
          {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            align: "flex-start",
            render: (text) => (
              <div
                style={{
                  whiteSpace: "normal", // Allow text to wrap
                  textWrap: "wrap",
                  textAlign: "left", // Break long words if necessary
                  maxWidth: "300px", // Adjust the max-width as needed
                  overflow: "hidden", // Hide overflow text
                  textOverflow: "ellipsis", // Add ellipsis if text is too long
                }}
              >
                {text}
              </div>
            ),
          },
          {
            title: "Payment Status",
            key: "paymentStatus",
            align: "center", // Center-align text in the column
            render: (text, record) => {
              const paymentDate = new Date(record.date).toLocaleDateString();
              const currentDate = new Date().toLocaleDateString();
              return paymentDate === currentDate ? (
                <span style={{ color: "green", fontWeight: "bold" }}>Done</span>
              ) : (
                <span style={{ color: "red", fontWeight: "bold" }}>
                  Pending
                </span>
              );
            },
          },
        ]}
        rowKey="_id"
      />

      <style jsx>{`
        .custom-table .ant-table-body::-webkit-scrollbar {
          width: 0;
          height: 8px;
        }

        .MuiInputBase-input {
          height: 38px !important;
          padding: 4px;
          width: 120px;
          text-align: center;
          box-sizing: border-box;
        }

        .custom-table .ant-table-body {
          -ms-overflow-style: none;
          scrollbar-width: none;
          overflow-y: scroll;
        }

        .ant-table-thead > tr > th {
          background-color: #fafafa;
          font-weight: bold;
          text-align: center;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .ant-table-tbody > tr:hover > td {
          background-color: #f5f5f5;
        }

        .ant-table-tbody > tr > td {
          padding: 12px 8px;
          text-align: center;
        }

        .ant-table-footer {
          background-color: #fafafa;
          font-weight: bold;
          text-align: center;
          position: sticky;
          z-index: 1;
        }
      `}</style>
    </div>
  );
};

export default TechnicianTransactions;
