import React, { useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import {
  deleteTechnicianWork,
  fetchAllTechnicianWorks,
} from "../api/technicianWork";

const TechnicianWork = () => {
  const [technicianWork, setTechnicianWork] = useState("");
  const [technicianWorks, setTechnicianWorks] = useState([]);

  const fetchTechnicianWorks = async () => {
    const data = await fetchAllTechnicianWorks();
    setTechnicianWorks(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(
      "https://ideal-yb257.ondigitalocean.app/api/technicianWork/addTechnicianWork",
      // "http://localhost:5000/api/technicianWork/addTechnicianWork",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          technicianWork: technicianWork,
        }),
      }
    );

    const json = await response.json();
    if (json.success) {
      fetchTechnicianWorks();
      setTechnicianWork("");
      alert("Technician Work Added successfully");
    } else {
      alert(json.errors);
    }
  };

  const handleDelete = async (technicianWorkId) => {
    const confirmed = window.confirm("Are you sure!");
    if (!confirmed) return;
    await deleteTechnicianWork(technicianWorkId);
    const newTechnicianWorks = technicianWorks.filter(
      (p) => p._id !== technicianWorkId
    );
    setTechnicianWorks(newTechnicianWorks);
  };

  useEffect(() => {
    fetchTechnicianWorks();
  }, []);

  useEffect(() => {
    fetchTechnicianWorks();
  }, [technicianWorks]);

  const commonClass =
    "w-full rounded-xl border border-gray-300 focus:border-[#717984] focus:bg-white focus:ring-1 focus:ring-[#717984] text-lg outline-none text-gray-700  py-2 px-4 leading-8 transition-colors duration-200 ease-in-out";

  return (
    <div className="w-[80vw] nunito-font  h-full bg-[#F1F5F9]" style={{height:"740px"}}>
      <div className="w-full">
        <section className="text-gray-600  body-font relative">
          <div className="container px-5 py-4 mx-auto">
            <div className="flex flex-col   mb-6">
              <h1 className="text-xl font-medium">Add Technician Work</h1>
            </div>
          </div>
          <div className="flex">
            <div className="w-2/3">
              <form className="lg:w-2/3 md:w-2/3 mx-auto">
                <div>
                  <div className="p-2 ">
                    <div className="relative">
                      <label
                        htmlFor="technicianWork"
                        className="leading-7 text-base text-gray-600"
                      >
                        Technician Work
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setTechnicianWork(e.target.value)}
                        required
                        id="technicianWork"
                        name="technicianWork"
                        value={technicianWork}
                        className={`${commonClass}`}
                        placeholder="Ex. Push Button"
                      />
                    </div>
                  </div>
                </div>

                <div className="p-2 mt-5  w-full">
                  <button
                    onClick={handleSubmit}
                    className="flex  text-white bg-[#717984] border-0 py-2 px-8 focus:outline-none hover:bg-[#5c646f] rounded text-lg"
                  >
                    Add Technician Work
                  </button>
                </div>
              </form>
            </div>
            <div
              className=" overflow-x-auto overflow-y-scroll w-1/3 h-min text-white shadow-md sm:rounded-lg"
              style={{ maxHeight: "550px",scrollbarWidth:"thin" }}
            >
              <table className="table-fixed w-full">
                <thead className="text-sm text-gray-700 uppercase bg-gray-50 sticky dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Technician Works
                    </th>
                  </tr>
                </thead>
                <tbody
                  className="overflow-y-hidden"
                  style={{ maxHeight: "500px" }}
                >
                  {technicianWorks.map((item) => (
                    <tr
                      key={item._id}
                      className="border-b  dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 "
                    >
                      <td className="px-6 py-3 text-black dark:text-white text-center">
                        <div className="flex justify-between">
                          <div className="w-4/5 text-center">
                            {item.technicianWork}
                          </div>
                          <button
                            className="w-1/5 mx-auto"
                            onClick={() => handleDelete(item._id)}
                          >
                            <MdDeleteOutline size={20} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TechnicianWork;
