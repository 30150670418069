import React, { useState } from "react";
import { Card } from "antd";
import FetchList from "./FetchList";
import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import defaultLabels from "../excelFileds/Default";
const DataCard = (props) => {
  //
  const { districtList, extractData } = props;
  const [districtData, setDistrictData] = useState(
    extractData[districtList[0]]
  );
  const [downloadData, setDownloadData] = useState(
    extractData[districtList[0]].totalComplaints
  );

  const addSerialNo = (toConvertData) => {
    var data = toConvertData.map((item) => ({
      ...item,
      plumbingNo: `"${item.plumbingNo}"`,
    }));
    let finalData = [];
    let count = 1;
    data.map((item) => {
      finalData.push(Object.assign(item, { srNo: count++ }));
    });
    return finalData;
  };

  const districtChangeHandler = (value) => {
    setDistrictData(extractData[value]);
    setDownloadData(extractData[value].totalComplaints);
  };
  const downloadHandler = () => {
    setDownloadData(addSerialNo(downloadData));
  };

  //
  return (
    <Card className="bg-transparent border-none">
      <div className="w-48 ml-auto mb-5">
        <FetchList
          list={districtList}
          placeholder="Select a district"
          OnChangeHandler={districtChangeHandler}
        />
      </div>
      <div className="flex flex-wrap justify-between gap-2">
        <div className="py-2 w-full rounded-lg px-4 bg-blue-50 text-black font-semibold">{`Total Complaints : ${districtData.totalComplaints.length}`}</div>
        <div className="py-2 w-full rounded-lg px-4 bg-blue-50 text-black font-semibold">{`Pending Complaints : ${districtData.pendingComplaints.length}`}</div>
        <div className="py-2 w-full rounded-lg px-4 bg-blue-50 text-black font-semibold">{`Close Complaints : ${districtData.closeComplaints.length}`}</div>
        <div className="py-2 w-full rounded-lg px-4 bg-blue-50 text-black font-semibold">{`Total Payments : ${districtData.totalPayments}`}</div>
        <div className="py-2 w-full rounded-lg px-4 bg-blue-50 text-black font-semibold">{`Avergae Paymnets : ${(
          districtData.totalPayments / districtData.totalPaymentCount
        ).toFixed(2)}`}</div>
      </div>
      <button
        className="bg-green-100 border-none w-full mt-3 text-green-600 text-xl px-2 py-3 rounded-lg cursor-pointer hover:bg-green-200"
        onClick={downloadHandler}
      >
        <CSVLink data={downloadData} headers={defaultLabels}>
          <DownloadOutlined /> Download
        </CSVLink>
      </button>
    </Card>
  );
};

export default DataCard;
