import React from "react";
import { Select } from "antd";
import { makeDropDownList } from "./Helper/Helper.js";

const FetchList = (props) => {
  const { list, placeholder } = props;
  const onChange = (value) => {
    props.OnChangeHandler(value);
  };
  const onSearch = (value) => {};

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      className="w-full"
      showSearch
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      options={makeDropDownList(list)}
      filterOption={filterOption}
      defaultValue={
        list[0] === "ANDHRA PRADESH"
          ? props.state
          : list[0] === "eight@ideal.com"
          ? props.email
          : list[0]
      }
    />
  );
};
export default FetchList;
