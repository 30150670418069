import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
  message,
  Upload,
  Select,
  Menu,
  Dropdown,
  notification,
} from "antd";
import member from "../api/member";
import moment from "moment";
import { TbEdit } from "react-icons/tb";
import AddTechnicianModal from "./FormModel/AddTechnicianModel";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";

const stateList = [
  "ANDHRA PRADESH",
  "ARUNACHAL PRADESH",
  "ASSAM",
  "BIHAR",
  "CHATTISGARH",
  "CHANDIGARH",
  "DELHI",
  "GOA",
  "GUJARAT",
  "HARYANA",
  "HIMACHAL PRADESH",
  "JAMMU & KASHMIR",
  "JHARKHAND",
  "KARNATAKA",
  "KERALA",
  "MADHYA PRADESH",
  "MAHARASHTRA",
  "MANIPUR",
  "MEGHALAYA",
  "MIZORAM",
  "ODISHA",
  "PUNJAB",
  "PONDICHERRY",
  "RAJASTHAN",
  "SIKKIM",
  "TAMIL NADU",
  "TELANGANA",
  "TRIPURA",
  "UTTARAKHAND",
  "UTTAR PRADESH",
  "WEST BENGAL",
  "ANDAMAN & NICOBAR ISLANDS",
];

const AddTechnician = () => {
  const [technicians, setTechnicians] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [currentTechnicianId, setCurrentTechnicianId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTechnicians, setFilteredTechnicians] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [form] = Form.useForm();
  const [addForm] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [fileAadhar, setFileAadhar] = useState(null);
  const [filePan, setFilePan] = useState(null);
  const memberId = localStorage.getItem("memberId");

  useEffect(() => {
    // Using GET with Query Parameter
    if (memberId) {
      member
        .get(`/technician/getTechniciansByUser`, {
          params: { memberId }, // Send memberId as a query parameter
        })
        .then((response) => {
          console.log(response.data, memberId);
          setTechnicians(response.data.technicians);
        })
        .catch((error) => {
          console.error("Error fetching the technicians!", error);
        });
    }
  }, []);
  //for search result
  useEffect(() => {
    if (searchQuery) {
      setFilteredTechnicians(
        technicians.filter((technician) =>
          technician.technicianName
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredTechnicians(technicians);
    }
  }, [searchQuery, technicians]);

  const handleAddTechnician = async (
    values,
    addfileAadhar,
    addfilePan,
    selectedState
  ) => {
    const capitalizeWords = (str) =>
      str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    console.log("aadhar card  nad pan card", addfilePan, addfileAadhar);
    const formattedValues = {
      technicianName: capitalizeWords(values.technicianName),
      mobileNo: values.mobileNo,
      state: capitalizeWords(selectedState),
      city: capitalizeWords(values.city),
      address: capitalizeWords(values.address),
      pincode: values.pincode,
      aadharcard: addfilePan,
      pancard: addfileAadhar,
    };
    console.log("values front", formattedValues);
    try {
      const response = await member.post(
        "/technician/addTechnician",
        formattedValues,
        {
          headers: {
            addedby: memberId,
          },
        }
      );

      setTechnicians([...technicians, response.data.technician]);
      setIsAddModalOpen(false);
      addForm.resetFields();
      message.success("Technician added successfully");
    } catch (error) {
      console.error("Error adding the technician!", error);
      message.error("Failed to add the technician");
    }
  };

  const handleDelete = (technicianName) => {
    member
      .delete(`/technician/deleteTechnician/${technicianName}`)
      .then(() => {
        setTechnicians(
          technicians.filter(
            (technician) => technician.technicianName !== technicianName
          )
        );
        message.success("Technician deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting the technician!", error);
      });
  };

  const handleEdit = (record) => {
    setCurrentTechnicianId(record._id);
    form.setFieldsValue(record);
    setFileAadhar(record.aadharcard);
    setFilePan(record.pancard);
    console.log(record);
    setIsFormChanged(false);
    setIsModalOpen(true);
  };

  const handleUpdateTechnician = async (values) => {
    if (!currentTechnicianId) {
      message.error("Technician ID is not available.");
      return;
    }

    const capitalizeWords = (str) =>
      str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    // console.log("aadhar card  nad pan card", fileAadhar, filePan);
    const modifiedDate = moment().tz("Asia/Kolkata").format();
    const formattedValues = {
      technicianName: capitalizeWords(values.technicianName),
      mobileNo: values.mobileNo,
      state: capitalizeWords(values.state),
      city: capitalizeWords(values.city),
      address: capitalizeWords(values.address),
      pincode: values.pincode,
      aadharcard: fileAadhar,
      pancard: filePan,
      modified: modifiedDate,
    };
    console.log(formattedValues);
    try {
      await member.put(
        `/technician/updateTechnician/${currentTechnicianId}`,
        formattedValues,
        {
          headers: { addedby: memberId },
        }
      );

      const newTechnicianName = values.technicianName;
      await member.put(
        `/techniciantransection/updateTransactionWhenTechnicianNameChange`,
        { newTechnicianName, technicianId: currentTechnicianId }
      );

      setTechnicians((prev) =>
        prev.map((technician) =>
          technician._id === currentTechnicianId
            ? { ...technician, ...formattedValues }
            : technician
        )
      );

      message.success("Technician updated successfully");
      setIsModalOpen(false);
      form.resetFields();
    } catch (error) {
      console.error("Error updating the technician!", error);
      message.error("Failed to update the technician");
    }
  };

  // const handleDownloadCSV = () => {
  //   const csvData = technicians.map((technician) => ({
  //     "Technician Name": technician.technicianName,
  //     "Mobile No.": technician.mobileNo,
  //     State: technician.state,
  //     City: technician.city,
  //     Pincode: technician.pincode,
  //     "Aadhar Card": technician.aadharcard ? "Yes" : "No",
  //     "PAN Card": technician.pancard ? "Yes" : "No",
  //     "Created Date": technician.date,
  //   }));

  //   const csv = Papa.unparse(csvData);

  //   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   const link = document.createElement("a");
  //   const url = URL.createObjectURL(blob);
  //   link.href = url;
  //   link.setAttribute("download", "technicians.csv");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  // const handleDownloadPDF = () => {
  //   if (filteredTechnicians.length > 0) {
  //     const doc = new jsPDF();
  //     doc.text(`Technician Details ${localStorage.getItem("email")}`, 10, 10);
  //     doc.autoTable({
  //       head: [
  //         [
  //           "Sr.No",
  //           "Technician Name",
  //           "Mobile No.",
  //           "State",
  //           "City",
  //           "Aadhar",
  //           "PAN",
  //           "Created Date",
  //           "Modified",
  //         ],
  //       ],
  //       body: filteredTechnicians.map((tech, index) => [
  //         index + 1,
  //         tech.technicianName,
  //         tech.mobileNo,
  //         tech.state,
  //         tech.city,
  //         tech.aadharcard ? "yes" : "No",
  //         tech.pancard ? "yes" : "No",
  //         new Date(tech.date).toLocaleDateString(),
  //         new Date(tech.modified).toLocaleDateString(),
  //       ]),
  //       styles: {
  //         fontSize: 10,
  //         overflow: "scroll",
  //         overflowX: "scroll",
  //         marginLeft: -10,
  //         cellPadding: 2,
  //         headHeight: 10,
  //         lineColor: [0, 0, 0],
  //         lineWidth: 0.1,
  //       },
  //       columnStyles: {
  //         0: { halign: "center", valign: "center", cellWidth: 12 },
  //         1: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: 30,
  //           cellWidth: "wrap",
  //         },
  //         2: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: 30,
  //           cellWidth: "wrap",
  //         },
  //         3: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: 30,
  //           cellWidth: "wrap",
  //         },
  //         4: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: 30,
  //           cellWidth: "wrap",
  //         },
  //         5: {
  //           halign: "left",
  //           valign: "center",
  //           cellWidth: 30,

  //           cellWidth: "",
  //         },
  //         6: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: 30,
  //           cellWidth: "wrap",
  //         },

  //         7: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: 30,
  //           cellWidth: "wrap",
  //         },
  //         8: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: 30,
  //           cellWidth: "wrap",
  //         },
  //       },
  //     });

  //     doc.save(`technicians_${moment().format("DDMMYYYY")}.pdf`);
  //   } else {
  //     notification.warning({
  //       message: "No Data",
  //       description: "No technicians available for download.",
  //     });
  //   }
  // };
  //searching functionality
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
  };

  // const downloadMenu = (
  //   <Menu>
  //     <Menu.Item key="1" onClick={handleDownloadCSV}>
  //       Download as CSV
  //     </Menu.Item>
  //     <Menu.Item key="2" onClick={handleDownloadPDF}>
  //       Download as PDF
  //     </Menu.Item>
  //   </Menu>
  // );
  const columns = [
    {
      title: "Sr.No",
      dataIndex: "srNo",
      key: "srNo",
      align: "center",
      width: "80px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Technician Name",
      dataIndex: "technicianName",
      key: "technicianName",
      align: "center",
      width: "130px",
    },
    // {
    //   title: "Mobile No.",
    //   dataIndex: "mobileNo",
    //   key: "mobileNo",
    //   align: "center",
    // },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      align: "center",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      align: "center",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
      align: "center",
    },
    // {
    //   title: "Aadhar Card",
    //   dataIndex: "aadharcard",
    //   key: "aadharcard",
    //   align: "center",
    //   width: "100px",
    //   render: (aadharcard) => (aadharcard ? "Yes" : "No"),
    // },
    // {
    //   title: "PAN Card",
    //   dataIndex: "pancard",
    //   key: "pancard",
    //   align: "center",
    //   width: "100px",
    //   render: (pancard) => (pancard ? "Yes" : "No"),
    // },
    // {
    //   title: "Modified Date",
    //   dataIndex: "modified",
    //   key: "modified",
    //   align: "center",
    //   render: (date) => new Date(date).toLocaleDateString(),
    // },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>
            <TbEdit className="text-gray-500" size={19} />
            <span className="text-gray-500 font-semibold">Edit</span>
          </Button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.technicianName)}
          ></Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <h1
        style={{
          fontSize: "50px",
          fontWeight: "bold",
          color: "rgb(22 78 99 / var(--tw-bg-opacity))",
        }}
        className="ml-7"
      >
        Technicians
      </h1>
      {/* <Dropdown overlay={downloadMenu} placement="bottomLeft">
        <Button
          type="default"
          icon={<DownloadOutlined />}
          style={{
            marginLeft: "10px",
            backgroundColor: "rgb(22 78 99 / var(--tw-bg-opacity))",
            color: "white",
          }}
          className="mr-8"
        >
          Download
        </Button>
      </Dropdown> */}

      <Input
        placeholder="Search by Technician Name"
        value={searchQuery}
        onChange={handleSearch}
        style={{ marginLeft: "20px", width: "300px" }}
      />
      <Button
        type="primary"
        onClick={() => setIsAddModalOpen(true)}
        style={{
          backgroundColor: "rgb(22 78 99 / var(--tw-bg-opacity))",
          color: "white",
          float: "right",
        }}
        className="mr-8"
      >
        Add Technician
      </Button>

      <div style={{ padding: "20px", backgroundColor: "#f0f2f5" }}>
        <Table
          dataSource={filteredTechnicians}
          columns={columns}
          rowKey="_id"
          sticky
          scroll={{
            x: "max-content",
          }}
          style={{
            marginTop: "20px",
            width: "1180px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            overflow: "hidden",
            maxHeight: "600px",
            overflowX: "scroll",
            overflowY: "scroll",
            scrollbarWidth: "none",
          }}
          className="custom-table"
        />

        <Modal
          title={currentTechnicianId ? "Edit Technician" : ""}
          visible={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
            form.resetFields();
            setIsFormChanged(false);
          }}
          footer={null}
          style={{ top: 20 }}
        >
          <p>NOTE: Click Update Technician after changing the value.</p>
          <Form
            form={form}
            onFinish={currentTechnicianId ? handleUpdateTechnician : null}
            onFieldsChange={() => setIsFormChanged(true)}
            layout="vertical"
          >
            <Form.Item
              name="technicianName"
              label="Technician Name(Always unique from others Technician)"
              rules={[{ required: true, min: 3 }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="mobileNo"
              label="Mobile No.(enter with out country-code if indian)"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: "Please select a state" }]}
            >
              <Select
                placeholder="Select State"
                style={{ width: 200, marginRight: 10 }}
                onChange={handleStateChange}
                value={selectedState || undefined}
                showSearch // Enable the search functionality
                filterOption={
                  (input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase()) // Filter options by the input value
                } // Set value to undefined if no state is selected
              >
                <Select.Option key="no-filter" value="">
                  ---Select State---
                </Select.Option>
                {stateList.map((state) => (
                  <Select.Option key={state} value={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="city"
              label="City(Please Enter Right Spelling Of City)"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="pincode"
              label="Pincode"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Aadhar Card"
              name="aadharcard"
              rules={[{ required: false }]}
            >
              <Upload
                beforeUpload={() => false}
                onChange={(info) => setFileAadhar(info.file)}
              >
                {fileAadhar ? (
                  <Button icon={<UploadOutlined />}>
                    Upload New Aadhar Card
                  </Button>
                ) : (
                  <Button icon={<UploadOutlined />}>Upload Aadhar Card</Button>
                )}
                {fileAadhar ? (
                  <p style={{ marginBottom: "10px" }}>
                    Aadhar Card uploaded: [Hidden for security]
                  </p>
                ) : (
                  ""
                )}
              </Upload>
            </Form.Item>
            <Form.Item label="PAN Card:" name="pancard">
              <Upload
                beforeUpload={() => false}
                onChange={(info) => setFilePan(info.file)}
                customRequest={({ file, onSuccess }) => {
                  setTimeout(() => onSuccess("ok"), 0);
                }}
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                  showDownloadIcon: false,
                }}
              >
                {filePan ? (
                  <Button icon={<UploadOutlined />}>Upload New PAN Card</Button>
                ) : (
                  <Button icon={<UploadOutlined />}>Upload PAN Card</Button>
                )}
                {filePan ? (
                  <p style={{ marginBottom: "10px" }}>
                    PAN Card uploaded: [Hidden for security]
                  </p>
                ) : (
                  ""
                )}
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "rgb(22 78 99 )",
                  color: "white",
                  borderColor: "rgb(59 130 246 / 50%)",
                  marginRight: "20px",
                }}
                disabled={!isFormChanged}
              >
                {currentTechnicianId ? "Update Technician" : ""}
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <AddTechnicianModal
          isVisible={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onAdd={handleAddTechnician}
          form={addForm}
          stateList={stateList}
        />
        <style jsx>{`
          .custom-table .ant-table-body::-webkit-scrollbar {
            width: 0;
            height: 8px;
          }

          .custom-table .ant-table-body {
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow-y: scroll;
          }

          .ant-table-thead > tr > th {
            background-color: #fafafa;
            font-weight: bold;
            text-align: center;
            position: sticky;
            top: 0;
            z-index: 1;
          }

          .ant-table-tbody > tr:hover > td {
            background-color: #f5f5f5;
          }

          .ant-table-tbody > tr > td {
            padding: 12px 8px;
            text-align: center;
          }

          .ant-table-footer {
            background-color: #fafafa;
            font-weight: bold;
            text-align: center;
            position: sticky;
            z-index: 1;
          }
        `}</style>
      </div>
    </div>
  );
};

export default AddTechnician;
