import React, { useState } from "react";
import { Button } from "antd";
import TechnicianDetails from "./TechnicianDetails";
import TechnicianTransactions from "./TechnicianTransactions";

const TechnicianHome = () => {
  const [activeTab, setActiveTab] = useState("details");

  return (
    <div style={{height:"740px"}}>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <Button
          type="primary"
          className="ml-3 mr-3 bg-cyan-700"
          style={
            activeTab === "details"
              ? { backgroundColor: "rgb(22 78 99 )", borderColor: "blue" }
              : {}
          }
          onClick={() => setActiveTab("details")}
        >
          Technician Details
        </Button>
        <Button
          type="primary"
          className="bg-cyan-700"
          style={
            activeTab === "transactions"
              ? { backgroundColor: "rgb(22 78 99 )", borderColor: "blue" }
              : {}
          }
          onClick={() => setActiveTab("transactions")}
        >
          Technician Transactions
        </Button>
      </div>

      {activeTab === "details" && <TechnicianDetails />}
      {activeTab === "transactions" && <TechnicianTransactions />}
    </div>
  );
};

export default TechnicianHome;
