import React, { useEffect, useState } from "react";
import { BsDownload } from "react-icons/bs";
import { fetchDateWiseComplaints } from "../api/complaint";
import { CSVLink } from "react-csv";
import loadingImg from "../images/loading.gif";
import defaultLabels from "./excelFileds/Default";

const DateWiseComplaint = (props) => {
  const { month, year } = props;
  const [complaints, setComplaints] = useState([]);
  const [dateWiseComplaints, setDateWiseComplaints] = useState([]);
  const [complaintData, setComplaintData] = useState([]);

  const fetchComplaints = async () => {
    setDateWiseComplaints([]);
    const data = await fetchDateWiseComplaints(month, year);
    setDateWiseComplaints(data.dateWiseArr);
  };

  const handleComplaintStatus = async () => {
    setComplaints([]);
    dateWiseComplaints.map((complaints) => {
      let totalArr = new Array();
      let closeArr = new Array();
      let todayClosedArr = new Array();
      let combineArr = new Array();
      let date = complaints[0];
      complaints[1].map((complaint) => {
        if (complaint.workDone !== "No") {
          closeArr.push(complaint);
        }
        totalArr.push(complaint);
      });
      complaints[2].map((complaint) => {
        todayClosedArr.push(complaint);
      });
      combineArr.push(date);
      combineArr.push(totalArr);
      combineArr.push(closeArr);
      combineArr.push(todayClosedArr);
      setComplaints((prev) => [...prev, combineArr]);
    });
  };

  const addSerialNo = (toConvertData) => {
    var data = toConvertData.map((item) => ({
      ...item,
      plumbingNo: `"${item.plumbingNo}"`, // convert value to string and add quotes
    }));
    let finalData = [];
    let count = 1;
    data.map((item) => {
      finalData.push(Object.assign(item, { srNo: count++ }));
    });
    return finalData;
  };

  useEffect(() => {
    fetchComplaints();
  }, []);

  useEffect(() => {
    fetchComplaints();
  }, [month, year]);

  useEffect(() => {
    handleComplaintStatus();
  }, [dateWiseComplaints.length >= 28]);

  return (
    <div className="w-full p-2 grid grid-cols-3  mt-10 mx-auto" style={{height:"740px"}}>
      {complaints.length > 0 ? (
        complaints.map((item, index) => (
          <>
            <div
              key={index}
              className="mx-10 my-8 min-w-fit  h-40 rounded-xl bg-emerald-300 flex flex-col justify-center"
            >
              <h3 className="text-2xl p-10 text-green-800 font-bold mx-auto">
                {item[0]}
              </h3>
              <div className="flex justify-around mb-10">
                <div className="flex flex-col items-center ">
                  <h4>Total ({item[1].length})</h4>
                  <button>
                    <button
                      onClick={() => {
                        setComplaintData(addSerialNo(item[1]));
                      }}
                    >
                      <CSVLink data={complaintData} headers={defaultLabels}>
                        <BsDownload className="cursor-pointer mt-2" />
                      </CSVLink>
                    </button>
                  </button>
                </div>
                <div className="flex flex-col items-center ">
                  <h4>Close ({item[2].length})</h4>
                  <button>
                    <button
                      onClick={() => {
                        setComplaintData(addSerialNo(item[2]));
                      }}
                    >
                      <CSVLink data={complaintData} headers={defaultLabels}>
                        <BsDownload className="cursor-pointer mt-2" />
                      </CSVLink>
                    </button>
                  </button>
                </div>
                <div className="flex flex-col items-center ">
                  <h4>Day Closed ({item[3].length})</h4>
                  <button>
                    <button
                      onClick={() => {
                        setComplaintData(addSerialNo(item[3]));
                      }}
                    >
                      <CSVLink data={complaintData} headers={defaultLabels}>
                        <BsDownload className="cursor-pointer mt-2" />
                      </CSVLink>
                    </button>
                  </button>
                </div>
              </div>
            </div>
          </>
        ))
      ) : (
        <>
          <div></div>
          <div className="mt-40   flex justify-center">
            <img
              className="w-[400px] h-[300px]"
              src={loadingImg}
              alt="loading..."
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DateWiseComplaint;
