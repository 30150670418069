import { Divider } from "antd";
import React, { useEffect, useState } from "react";

const Heading = (props) => {
  const [isDivider, setIsDivider] = useState(true);
  const classes = props.className;

  useEffect(() => {
    if (props.isDivider === false) {
      setIsDivider(props.isDivider);
    }
  }, []);
  return (
    <>
      <div className={classes}>{props.children}</div>
      {isDivider && <Divider className="my-2" />}
    </>
  );
};

export default Heading;
