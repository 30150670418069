import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import io from "socket.io-client";

// const socket = io("http://localhost:5001"); // Use your server's URL
const socket = io("https://ideal-yb257.ondigitalocean.app"); // Use your server's URL

const handleLogout = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("email");
  localStorage.removeItem("memberId");
  localStorage.removeItem("sortBy");
  localStorage.removeItem("stat`eVal");
  localStorage.removeItem("search");
  localStorage.removeItem("companyVal");
  localStorage.removeItem("role");
  window.location.href = "https://gigwellservices.com/signin";
};

// Handle the session-expired event from the server
socket.on("session-expired", async () => {
  await handleLogout();
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
