import React, { useState, useEffect } from "react";
import { DatePicker, Spin, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { getCompanyData,fetchCompanyList } from "../api/complaint";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import member from "../api/member";

const { Option } = Select;


const CompanyWiseAnalysis = () => {
  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [endDate, setEndDate] = useState(moment().endOf("month"));
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async()=>{
    const res=await fetchCompanyList();
    setCompanyList(res.data)
  })
  useEffect(() => {
    fetchTopCompanies();
  }, [startDate, endDate]);

  const fetchTopCompanies = async () => {
    setIsLoading(true);
    try {
      const response = await member.post('/api/topCompaniesByPayments', {
        startDate: startDate.format('DD-MM-YYYY'),
        endDate: endDate.format('DD-MM-YYYY'),
        companies: companyList,
      });
      setCompanyData(response.data);
    } catch (error) {
      console.error('Error fetching top companies:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompanyChange = async (value) => {
    setSelectedCompany(value);
    if (value) {
      setIsLoading(true);
      try {
        const data = await getCompanyData(
          value,
          startDate.format("DD-MM-YYYY"),
          endDate.format("DD-MM-YYYY")
        );
        setCompanyData([data]);
      } catch (error) {
        console.error("Error fetching company data:", error);
        setCompanyData(null);
      } finally {
        setIsLoading(false);
      }
    } else {
      fetchTopCompanies();
    }
  };

  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(moment().startOf("month"));
      setEndDate(moment().endOf("month"));
    }
  };

  return (
    <div className="w-full p-2 flex flex-col items-center">
      <div className="w-full max-w-4xl px-10">
        <div className="mx-auto mt-3 px-4">
          <div className="flex justify-between items-center p-1">
            <h2 className="text-2xl font-semibold text-gray-700 my-2">
              Company Analytics
            </h2>
       
          </div>
          <div className="p-2 w-fit mb-4 flex ">
          {/* <div className="w-full flex justify-end items-center mb-4"> */}
            <LocalizationProvider dateAdapter={AdapterMoment} >
              <DateRangePicker
                startText="Start Date"
                endText="End Date"
                value={[startDate,endDate]}
                onChange={handleDateChange}
                renderInput={(startProps, endProps) => (
                  <>
                    <input
                      {...startProps.inputProps}
                      placeholder="DD-MM-YYYY"
                      value={startProps.inputProps.value || ""}
                      onChange={startProps.inputProps.onChange}
                    />
                    <input
                      {...endProps.inputProps}
                      placeholder="DD-MM-YYYY"
                      value={endProps.inputProps.value || ""}
                      onChange={endProps.inputProps.onChange}
                    />
                  </>
                )}
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>
            <Select
              value={selectedCompany}
              onChange={handleCompanyChange}
              className="ml-4 mr-10 h-14 justify-center align-middle w-64"
              placeholder="Select Company"
            >
              {companyList.map((company) => (
                <Option key={company.name} value={company.name}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="mb-8 w-full">
            {isLoading ? (
              <div className="flex justify-center items-center">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
                />
              </div>
            ) : (
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b">Number</th>
                    <th className="py-2 px-4 border-b">Company Name</th>
                    <th className="py-2 px-4 border-b">Total Payments</th>
                  </tr>
                </thead>
                <tbody>
                  {companyData && companyData.length > 0 ? (
                    companyData.map((company, index) => (
                      <tr key={company.name}>
                        <td className="py-2 px-4 border-b">{index + 1}</td>
                        <td className="py-2 px-4 border-b">{company.name}</td>
                        <td className="py-2 px-4 border-b">{company.totalPayments || 0}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="py-2 px-4 text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyWiseAnalysis;
