import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Space,
  notification,
  Select,
  Popconfirm,
  message,
} from "antd";
import member from "../api/member";
import AddTransactionModal from "./FormModel/AddTransactionModal";
import EditTransactionModal from "./FormModel/EditTransactionModal";
import "antd/dist/reset.css";
import { TbEdit, TbTrash } from "react-icons/tb";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import moment from "moment";
// import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
// import { jsPDF } from "jspdf";
// import "jspdf-autotable";
// import Papa from "papaparse";

const AddTransaction = () => {
  const defaultStartDate = moment().startOf("month");
  const defaultEndDate = moment().endOf("month");
  const [transactions, setTransactions] = useState([]);
  const [filtertransactions, setFilterTransactions] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [techniciansDetails, setTechniciansDetails] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingTransaction, setEditingTransaction] = useState(null);
  // const [isDateRangeSet, setIsDateRangeSet] = useState(false);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  // const [dateRange, setDateRange] = useState([
  //   defaultStartDate,
  //   defaultEndDate,
  // ]);
  const memberId = localStorage.getItem("memberId");

  useEffect(() => {
    const memberId = localStorage.getItem("memberId");
    if (memberId) {
      if (selectedTechnician) {
        const filteredByTechnician = transactions
          .filter((trans) =>
            trans.technician ? trans.technicianId === selectedTechnician : false
          )
          .map((trans) => {
            const technicianDetails = techniciansDetails.find(
              (tech) => tech._id === trans.technicianId
            );

            return {
              ...trans,
              state: technicianDetails ? technicianDetails.state : null,
              city: technicianDetails ? technicianDetails.city : null,
            };
          });

        setFilterTransactions(filteredByTechnician);
      }
    }
  }, []);

  useEffect(() => {
    const totalAmount = filtertransactions.reduce(
      (acc, transection) => acc + transection.amount,
      0
    );
    setTotalAmount(totalAmount);
  }, [filtertransactions]);

  useEffect(() => {
    const memberId = localStorage.getItem("memberId");
    member
      .get(`/technician/getTechniciansByUser`, {
        params: { memberId },
      })
      .then((response) => {
        if (response.data) {
          // console.log("response data technician", response.data);
          setTechnicians(response.data.technicians || []);
          setTechniciansDetails(response.data.technicians || []);
        } else {
          setTechnicians([]);
          setTechniciansDetails([]);
        }
      })
      .catch((error) => console.error("Error fetching technicians:", error));
  }, []);

  const getTransaction = async () => {
    const memberId = localStorage.getItem("memberId");
    if (!selectedTechnician && memberId) {
      member
        .get("/techniciantransection/getTransectionsByDateRange", {
          params: { memberId },
        })
        .then((response) => {
          const transactions = response.data;
          const filteredByTechnician = transactions.map((trans) => {
            const technicianDetails = technicians.find(
              (tech) => tech._id === trans.technicianId
            );

            return {
              ...trans,
              state: technicianDetails ? technicianDetails.state : null,
              city: technicianDetails ? technicianDetails.city : null,
            };
          });

          setTransactions(transactions);
          setFilterTransactions(filteredByTechnician);
          console.log("Filtered Transactions:", filteredByTechnician);
        })
        .catch((error) => console.error("Error fetching transactions:", error));
    }
  };
  useEffect(() => {
    getTransaction();
  }, [selectedTechnician, techniciansDetails]);

  const columns = [
    {
      title: "Sr. No",
      key: "srNo",
      width: "5vw",
      render: (text, record, index) => index + 1, // Serial number
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "6.5vw",
      render: (date) => new Date(date).toLocaleDateString(), // Format date
      align: "center", // Center-align text in the column
    },
    {
      title: "Technician",
      dataIndex: "technician",
      key: "technician",
      width: "9vw",
      align: "center", // Center-align text in the column
    },
    {
      title: "State",
      width: "6.5vw",
      key: "state",
      align: "center", // Center-align text in the column
      dataIndex: "state",
    },
    {
      title: "City",
      dataIndex: "city",
      width: "6.5vw",
      key: "city",
      align: "center", // Center-align text in the column
    },
    // {
    //   title: "Modified Date",
    //   dataIndex: "modified",
    //   key: "modified",
    //   render: (date) => new Date(date).toLocaleDateString(),
    //   align: "center",
    // },
    // {
    //   title: "Payment Date",
    //   dataIndex: "date",
    //   key: "date",
    //   render: (date) => new Date(date).toLocaleDateString(), // Format date
    //   align: "center",
    // },
    {
      title: "Payment",
      dataIndex: "amount",
      key: "amount",
      width: "6vw",
      align: "center",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      align: "flex-start",
      render: (text) => (
        <div
          style={{
            whiteSpace: "normal", // Allow text to wrap
            textWrap: "wrap",
            textAlign: "left", // Break long words if necessary
            maxWidth: "19vw", // Adjust the max-width as needed
            overflow: "hidden", // Hide overflow text
            textOverflow: "ellipsis", // Add ellipsis if text is too long
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "status",
      key: "status",
      align: "center", // Center-align text in the column
      width: "100px",
    },
    {
      title: "Actions",
      key: "actions",
      width: "10vw",
      align: "center", // Center-align text in the column
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              console.log("edit model", record);
              setEditingTransaction(record);
              setIsEditModalVisible(true);
            }}
          >
            <TbEdit className="text-gray-500" size={19} />
            <span className="text-gray-500 font-semibold">Edit</span>
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this transaction?"
            onConfirm={() => handleDelete(record._id)} // Trigger delete on confirmation
            overlayStyle={{
              maxWidth: "250px",
              whiteSpace: "normal",
              marginRight: "10px",
            }}
            okButtonProps={{
              style: {
                backgroundColor: "#f5222d",
                borderColor: "#f5222d",
                color: "#fff",
              }, // Customize the "Yes" button
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <TbTrash className="text-gray-500" size={19} />
              {/* <span className="text-gray-500 font-semibold">Delete</span> */}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  //add transaction
  const handleAddTransaction = async (values) => {
    try {
      const response = await member.post(
        "/techniciantransection/addtransactions",
        {
          technician: values.technician,
          technicianId: values.technicianId,
          date: values.date, // Ensure proper date format
          amount: values.amount,
          remarks: values.remarks,
          addedBy: memberId,
        }
      );

      setTransactions((prev) => [...prev, response.data]);
      // fatch after add new transaction
      getTransaction();
      setIsAddModalVisible(false);
      notification.success({
        message: "Success",
        description: "Transaction added successfully!",
      });
    } catch (error) {
      console.error("Error adding transaction:", error);
      notification.error({
        message: "Error",
        description: "Failed to add transaction.",
      });
    }
  };
  //update transaction
  const handleUpdateTransaction = async (values) => {
    // console.log("Updating transaction with values:", values); // Debugging
    const modifiedDate = moment().tz("Asia/Kolkata").format();
    try {
      // Send request to update transaction
      const response = await member.put(
        `/techniciantransection/updateTransection/${editingTransaction._id}`,
        {
          technician: values.technician,
          technicianId: values.technicianId,
          date: values.date, // Use formatted date
          amount: values.amount,
          status: values.status,
          remarks: values.remarks,
          modified: modifiedDate,
        }
      );

      // console.log("Response from server:", response.data); // Debugging

      // Update the transactions state
      setTransactions((prev) =>
        prev.map((transaction) =>
          transaction._id === editingTransaction._id
            ? response.data.transection // Ensure correct data path
            : transaction
        )
      );
      // fatch after edit
      getTransaction();

      setIsEditModalVisible(false);
      notification.success({
        message: "Success",
        description: "Transaction updated successfully!",
      });
    } catch (error) {
      console.error("Error updating transaction:", error);
      notification.error({
        message: "Error",
        description: "Failed to update transaction.",
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await member.delete(
        `/techniciantransection/deleteTransection/${id}`
      );
      if (response.status === 200) {
        message.success("Transaction deleted successfully!");
        // Optionally, refresh the transactions list after deletion
        setTransactions(transactions.filter((trans) => trans._id !== id));
        getTransaction();
      }
    } catch (error) {
      console.error("Error deleting transaction:", error);
      message.error("Failed to delete the transaction.");
    }
  };

  // const handleSetDateRange = async () => {
  //   if (dateRange[0] && dateRange[1]) {
  //     try {
  //       // Convert moment dates to the desired format
  //       const startDate = dateRange[0].startOf("day").toISOString();
  //       const endDate = dateRange[1].endOf("day").toISOString();

  //       // Make API call to fetch transactions within the date range
  //       const response = await member.get(
  //         `/techniciantransection/getTransectionsByDateRange`,
  //         {
  //           params: {
  //             startDate,
  //             endDate,
  //             memberId,
  //           },
  //         }
  //       );
  //       // Update the state with the fetched transactions
  //       setTransactions(response.data);
  //     } catch (error) {
  //       console.error("Error fetching transactions:", error);
  //       notification.error({
  //         message: "Error",
  //         description: "No Transection Available.",
  //       });
  //     }
  //   }
  // };

  //csv download
  // const handleDownloadCSV = () => {
  //   if (transactions.length > 0) {
  //     const csv = Papa.unparse(
  //       transactions.map(({ _id, __v, technicianId, addedBy, ...rest }) => rest) // Exclude unnecessary fields
  //     );
  //     const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.setAttribute("href", url);
  //     link.setAttribute(
  //       "download",
  //       `transactions_${moment().format("DDMMYYYY")}.csv`
  //     );
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } else {
  //     notification.warning({
  //       message: "No Data",
  //       description: "No transactions available for download.",
  //     });
  //   }
  // };
  //pdf down load
  // const handleDownloadPDF = () => {
  //   if (transactions.length > 0) {
  //     const doc = new jsPDF();

  //     const memberName = localStorage.getItem("email"); // Retrieve the member name from local storage

  //     // Add the heading with the member's name
  //     doc.setFontSize(18);
  //     doc.text(`Technician Transaction Data for ${memberName}`, 14, 15);
  //     doc.text(`Totoal transaction amount : ${totalAmount}`, 14, 22);
  //     doc.autoTable({
  //       startY: 25,
  //       margin: { left: 0, top: 10 },
  //       styles: {
  //         fontSize: 10,
  //         marginLeft: 0,
  //         lineColor: [0, 0, 0],
  //         lineWidth: 0.1,
  //         cellPadding: 2,
  //       },
  //       head: [
  //         [
  //           "Sr. No",
  //           "Technician",
  //           "Created Date",
  //           "Modified",
  //           "Payment Date",
  //           "Amount",
  //           "Remarks",
  //           "Payment Status",
  //         ],
  //       ],
  //       columnStyles: {
  //         0: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: "wrap",
  //           cellWidth: 12,
  //           fillColor: [232, 252, 245],
  //         },
  //         1: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: "wrap",
  //           cellWidth: 23,
  //           fillColor: [232, 252, 245],
  //         },
  //         2: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: "wrap",
  //           cellWidth: 25,
  //           fillColor: [232, 252, 245],
  //         },
  //         3: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: "wrap",
  //           cellWidth: 25,
  //           fillColor: [232, 252, 245],
  //         },
  //         4: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: "wrap",
  //           cellWidth: 25,
  //           fillColor: [232, 252, 245],
  //         },
  //         5: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: "wrap",
  //           cellWidth: 30,
  //           fillColor: [232, 252, 245],
  //         },
  //         6: {
  //           halign: "left",
  //           cellWidth: "wrap",
  //           cellWidth: 40,
  //           fillColor: [232, 252, 245],
  //         },
  //         7: {
  //           halign: "center",
  //           valign: "center",
  //           cellWidth: "wrap",
  //           cellWidth: 30,
  //           fillColor: [232, 252, 245],
  //         },
  //       },

  //       body: transactions.map((transaction, index) => [
  //         index + 1,
  //         transaction.technician,
  //         new Date(transaction.createdAt).toLocaleDateString(),
  //         new Date(transaction.modified).toLocaleDateString(),
  //         new Date(transaction.date).toLocaleDateString(),
  //         transaction.amount,
  //         transaction.remarks,
  //         new Date(transaction.date).getTime() <= new Date().getTime()
  //           ? "Done"
  //           : "Pending",
  //       ]),
  //     });
  //     doc.autoTable();
  //     doc.save(`transactions_${moment().format("YYYYMMDD")}.pdf`);
  //   } else {
  //     notification.warning({
  //       message: "No Data",
  //       description: "No transactions available for download.",
  //     });
  //   }
  // };

  const handleTechnicianChange = async (value) => {
    // if (selectedTechnician) {
    //   setSelectedTechnician(value ? true : false);
    // }
    setSelectedTechnician(value);
    // try {
    //   const response = await member.get(
    //     `/techniciantransection/getTransectionByName/${value}`
    //   );

    //   setTransactions(response.data ? [response.data] : []);

    //   console.log("front", transactions);
    // } catch (error) {
    //   // console.error("Error fetching transactions:", error);
    //   notification.error({
    //     message: "Sorry",
    //     description: "No Transection Of This Technician.",
    //   });
    // }
  };

  // const handleDateChange = (newDateRange) => {
  //   setDateRange(newDateRange);
  //   setIsDateRangeSet(newDateRange[0] || newDateRange[1] ? true : false);
  // };

  const handleClearFilter = () => {
    // console.log("helloclear");
    setSelectedTechnician(null);
    // setDateRange([defaultStartDate, defaultEndDate]);
    // handleSetDateRange(dateRange);
    setTotalAmount(totalAmount);
    // setIsDateRangeSet(false);
  };

  // const downloadMenu = (
  //   <Menu>
  //     <Menu.Item key="1" onClick={handleDownloadCSV}>
  //       Download as CSV
  //     </Menu.Item>
  //     {/* <Menu.Item key="2" onClick={handleDownloadPDF}>
  //       Download as PDF
  //     </Menu.Item> */}
  //   </Menu>
  // );

  return (
    <div
      style={{
        padding: "0px 20px",
        backgroundColor: "#f0f2f5",
        textAlign: "center",
      }}
    >
      <div className="flex h-13">
        <h1
          style={{
            width: "100%",
            fontSize: "50px",
            fontWeight: "bold",
            textAlign: "left",
            color: "rgb(22 78 99 / var(--tw-bg-opacity))",
          }}
          className="ml-2"
        >
          Payments
        </h1>
        <p
          style={{
            marginRight: "20px",
            width: "700px",
            fontSize: "20px",
            fontWeight: "bold",
            textAlign: "right",
            alignSelf: "center",
            color: "rgb(22 78 99 / var(--tw-bg-opacity))",
          }}
        >
          Total payment : {totalAmount} ₹
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <Dropdown overlay={downloadMenu} placement="bottomLeft">
            <Button
              type="default"
              icon={<DownloadOutlined />}
              style={{
                marginLeft: "15px",
                backgroundColor: "rgb(22 78 99 / var(--tw-bg-opacity))",
                color: "white",
              }}
              className="mr-8"
            >
              Download
            </Button>
          </Dropdown> */}
          {/* <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={dateRange}
              onChange={handleDateChange}
              renderInput={(startProps, endProps) => (
                <>
                  <input
                    {...startProps.inputProps}
                    placeholder="DD-MM-YYYY"
                    className="MuiInputBase-input"
                    value={startProps.inputProps.value || ""}
                    onChange={startProps.inputProps.onChange}
                  />
                  <input
                    {...endProps.inputProps}
                    placeholder="DD-MM-YYYY"
                    className="MuiInputBase-input"
                    value={endProps.inputProps.value || ""}
                    onChange={endProps.inputProps.onChange}
                  />
                </>
              )}
              format="DD-MM-YYYY"
            />

            <Button
              className="ml-4 text-white font-bold py-2 px-4 rounded"
              onClick={handleSetDateRange}
              style={{
                backgroundColor: isDateRangeSet
                  ? "rgb(22 78 99)"
                  : "rgb(22 78 99 / 50%)",
                color: "white",
                cursor: isDateRangeSet ? "pointer" : "not-allowed",
              }}
              disabled={!isDateRangeSet}
            >
              Set Date Range
            </Button>
          </LocalizationProvider> */}
          <Select
            placeholder="Select Technician"
            onChange={handleTechnicianChange}
            style={{ width: 200, marginLeft: 10 }}
            value={selectedTechnician || undefined}
          >
            <Select.Option key="no-filter" value="">
              ---Select Technician---
            </Select.Option>
            {techniciansDetails.length
              ? technicians.map((tech) => (
                  <Select.Option key={tech._id} value={tech._id}>
                    {tech.technicianName}
                  </Select.Option>
                ))
              : "null"}
          </Select>
          <Button
            className="ml-4 text-white font-bold py-2 px-4 rounded"
            onClick={handleClearFilter}
            style={{
              backgroundColor: selectedTechnician
                ? "rgb(22 78 99)"
                : "rgb(22 78 99 / 50%)",
              color: "white",
              cursor: selectedTechnician ? "pointer" : "not-allowed",
            }}
            disabled={!selectedTechnician}
          >
            Clear Filter
          </Button>
        </div>

        <div>
          <Button
            type="primary"
            onClick={() => setIsAddModalVisible(true)}
            className=""
            style={{
              backgroundColor: "rgb(22 78 99 / var(--tw-bg-opacity))",
              color: "white",
              borderColor: "rgb(59 130 246 / 50%)",
              marginRight: "25px",
            }}
          >
            Add Payment
          </Button>
        </div>
      </div>

      <div style={{ padding: "20px", backgroundColor: "#f0f2f5" }}>
        <Table
          columns={columns}
          dataSource={filtertransactions}
          rowKey="_id"
          sticky
          scroll={{
            x: "max-content",
          }}
          style={{
            marginTop: "1px",
            width: "75vw",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            overflow: "hidden",
            height: "500px",
            maxHeight: "600px",
            overflowX: "scroll",
            overflowY: "scroll",
            scrollbarWidth: "none",
          }}
          className="custom-table"
          pagination={false} // Disable pagination if you want all data on one page
        />
      </div>

      <AddTransactionModal
        isVisible={isAddModalVisible}
        onCancel={() => {
          setIsAddModalVisible(false);
        }}
        onFinish={handleAddTransaction}
        technicians={technicians}
      />

      <EditTransactionModal
        isVisible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onFinish={handleUpdateTransaction}
        editingTransaction={editingTransaction}
        technicians={technicians}
      />

      <style jsx>{`
        .custom-table .ant-table-body::-webkit-scrollbar {
          width: 0;
          height: 8px;
        }

        .MuiInputBase-input {
          height: 38px !important;
          padding: 4px;
          /* marginLeft:0; */
          width: 120px;
          text-align: center;
          box-sizing: border-box;
        }

        .custom-table .ant-table-body {
          -ms-overflow-style: none;
          scrollbar-width: none;
          overflow-y: scroll;
        }

        .ant-table-thead > tr > th {
          background-color: #fafafa;
          font-weight: bold;
          text-align: center;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .ant-table-tbody > tr:hover > td {
          background-color: #f5f5f5;
        }

        .ant-table-tbody > tr > td {
          padding: 12px 8px;
          text-align: center;
        }

        .ant-table-footer {
          background-color: #fafafa;
          font-weight: bold;
          text-align: center;
          position: sticky;
          z-index: 1;
        }
      `}</style>
    </div>
  );
};

export default AddTransaction;
