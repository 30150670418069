import React, { useState } from "react";
import { Badge, Space, Switch } from "antd";

// total-blue-outline
// close-green-outline
// pending-red-outline
// Total Amount-green
// Avg. Amount-blue

const BadgeData = (props) => {
  //
  const {
    closeComplaints,
    totalComplaints,
    pendingComplaints,
    totalPayments,
    averagePayments,
  } = props;

  //
  const [show, setShow] = useState(true);
  const commonCSS = {
    padding: 14,
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
  };
  return (
    <div>
      <Space>
        <Switch checked={show} onChange={() => setShow(!show)} />

        <Badge
          style={commonCSS}
          count={show ? `Total : ${totalComplaints}` : 0}
          color={show && "volcano"}
        />
        <Badge
          style={commonCSS}
          count={show ? `Pending : ${pendingComplaints}` : 0}
          color={show && "orange"}
        />
        <Badge
          style={commonCSS}
          count={show ? `Close : ${closeComplaints}` : 0}
          color={show && "gold"}
        />
        <Badge
          style={commonCSS}
          count={show ? `Total Amt. : ${totalPayments}` : 0}
          color={show && "green"}
        />
        <Badge
          style={commonCSS}
          count={show ? `Avg. Amt. : ${Math.round(averagePayments)}` : 0}
          color={show && "blue"}
        />
      </Space>
    </div>
  );
};
export default BadgeData;
