import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { BsDownload } from "react-icons/bs";
import moment from "moment-timezone";
import {
  fetchCountForCloseToday,
  fetchTotalComplaintsByMobile,
  getNumberForwarding,
  searchByMobileNoEntry,
} from ".././api/complaint";
import { MessageOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { fetchAllProducts } from "../api/product";
import { fetchAllRemarks } from "../api/remark";
import headerPending from "../adminComponents/excelFileds/Pending";
import axios from "axios";
import member from "../api/member";
var pincodeQuery = require("india-pincode-search");

export const defaultComplaint = {
  partyName: "",
  address: "",
  pincode: "",
  state: "",
  city: "",
  mobileNo: "",
  plumbingNo: "",
  brandName: "",
  workDone: "No",
  repeat: "No",
  syphoneColor: "",
  code: "",
  problem: "",
  product: "",
  entryRemark: "",
  satisfactionCode: "",
  purchaseTime: "",
  serviceType: "",
  note: "",
  createdAt: moment().tz("Asia/Kolkata").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
  date: moment().tz("Asia/Kolkata").format("DD-MM-YYYY"),
};

const ComplaintForm = (props) => {
  const [totalComplaintsCount, setTotalComplaintsCount] = useState(0);
  const [totalComplaints, setTotalComplaints] = useState([]);
  const [todaysTotalComplaintsCount, setTodaysTotalComplaintsCount] =
    useState(0);
  const [todaysTotalComplaints, setTodaysTotalComplaints] = useState([]);
  const [todaysCloseComplaintsCount, setTodaysCloseComplaintsCount] =
    useState(0);
  const [todaysCloseComplaints, setTodaysCloseComplaints] = useState([]);

  const [closed, setClosed] = useState(0);
  const [pending, setPending] = useState(0);
  const [cancel, setCancel] = useState(0);
  const [ceramicIssue, setCeramicIssue] = useState(0);
  const [partPending, setPartPending] = useState(0);
  const [otherIssue, setOtherIssue] = useState(0);
  const [seatChange, setSeatChange] = useState(0);
  const [ceramicIssueComplaints, setCeramicIssueComplaints] = useState([]);
  const [otherIssueComplaints, setOtherIssueComplaints] = useState([]);
  const [partPendingComplaints, setPartPendingComplaints] = useState([]);
  const [seatChangeComplaints, setSeatChangeComplaints] = useState([]);
  const [complaint, setComplaint] = useState(defaultComplaint);
  const [pendingComplaints, setPendingComplaints] = useState([]);
  const [cancelComplaints, setCancelComplaints] = useState([]);
  const [closedComplaints, setClosedComplaints] = useState([]);
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [dateX, setDateX] = useState(
    moment(Date.now()).tz("Asia/Kolkata").format("YYYY-MM-DD")
  );
  const [officeNo, setOfficeNo] = useState([]);
  const [products, setProducts] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [otherRemark, setOtherRemark] = useState("");
  const [satisfactionCode, setSatisfactionCode] = useState("");
  const [grpInfo, setGrpInfo] = useState([]);
  const [totalMobileNoCount, setTotalMobileNoCount] = useState(0);
  let navigate = useNavigate();

  const [isActive, setIsActive] = useState({ sms: false, whatsapp: false });

  const handleToggle = (name) => {
    let toggleState = {
      ...isActive,
      [name]: name === "sms" ? !isActive.sms : !isActive.whatsapp,
    };
    setIsActive(toggleState);
    localStorage.setItem("toggleState", JSON.stringify(toggleState));
  };

  const handleTotalComplaint = async () => {
    const { data } = await member.get(
      `/complaint/fetchComplaintsCount?email=${email}&month=${props.month}&year=${props.year}`
    );

    setTotalComplaintsCount(data.lenTotal);
    setTotalComplaints(data.complaint);
  };

  const getComplaintsCount = async () => {
    let ope = 0,
      clo = 0,
      cer = 0,
      can = 0,
      oth = 0,
      pp = 0,
      sc = 0;
    setPendingComplaints([]);
    setCancelComplaints([]);
    setClosedComplaints([]);
    setCeramicIssueComplaints([]);
    setOtherIssueComplaints([]);
    setPartPendingComplaints([]);
    setSeatChangeComplaints([]);
    setPending(0);
    setCancel(0);
    setClosed(0);
    setPartPending(0);
    setOtherIssue(0);
    setCeramicIssue(0);
    setSeatChange(0);

    totalComplaints.map((complaint) => {
      if (complaint.workDone === "Yes" || complaint.workDone === "Work Done") {
        clo++;
        setClosed(clo);
        setClosedComplaints((c) => [...c, complaint]);
      } else if (
        (complaint.repeat === "Yes" && complaint.workDone === "No") ||
        complaint.workDone === "No" ||
        complaint.workDone === "Visit Ok" ||
        complaint.workDone === "Part Pending"
      ) {
        ope++;
        setPending(ope);
        setPendingComplaints((c) => [...c, complaint]);
      } else if (complaint.workDone === "Cancel") {
        can++;
        setCancel(can);
        setCancelComplaints((c) => [...c, complaint]);
      } else if (complaint.workDone === "Ceramic Issue") {
        cer++;
        setCeramicIssue(cer);
        setCeramicIssueComplaints((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Other Issue") {
        oth++;
        setOtherIssue(oth);
        setOtherIssueComplaints((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Part Pending") {
        pp++;
        setPartPending(pp);
        setPartPendingComplaints((prev) => [...prev, complaint]);
      } else if (complaint.workDone === "Seat Change") {
        pp++;
        setSeatChange(sc);
        setSeatChangeComplaints((prev) => [...prev, complaint]);
      }
    });
  };

  const handleTodaysTotalComplaint = async () => {
    const { data } = await member.get(
      `/complaint/fetchTodaysComplaintsCount?email=${email}&month=${props.month}&year=${props.year}`
    );
    setTodaysTotalComplaintsCount(data.lenTotal);
    setTodaysTotalComplaints(data.complaint);
  };

  const handleTodayCloseComplaint = async () => {
    const data = await fetchCountForCloseToday(email, null);
    setTodaysCloseComplaintsCount(data.lenTotal);
    setTodaysCloseComplaints(data.complaint);
  };

  const handleOfficeNo = async () => {
    const { data } = await getNumberForwarding();
    setOfficeNo(data);
  };

  const addSerialNo = (toConvertData) => {
    var data = toConvertData.map((item) => ({
      ...item,
      plumbingNo: `"${item.plumbingNo}"`, // convert value to string and add quotes
    }));
    let finalData = [];
    let count = 1;
    data.map((item) => {
      finalData.push(Object.assign(item, { srNo: count++ }));
    });
    return finalData;
  };

  const sendSMS = async (tempId, mobileNo, msgText) => {
    const queryParams = new URLSearchParams({
      UserID: "IDLINT",
      Password: "IDLINT@123",
      SenderID: "IDLINT",
      SMSType: 2,
      Mobile: mobileNo,
      MsgText: msgText,
      Entityid: "1301167879032838908",
      Templateid: tempId,
    }).toString();

    try {
      const response = await axios.get(
        `https://web.shreesms.net/API/SendSMS.aspx?${queryParams}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Generate a random number between 10000 and 99999
    const randomNum = Math.floor(Math.random() * 90000) + 10000;
    // Convert the random number to a string
    setSatisfactionCode(randomNum.toString());
    // API Call

    const { data } = await member.post(`/complaint/addcomplaint`, {
      partyName: complaint.partyName,
      address: complaint.address,
      pincode: complaint.pincode,
      state: complaint.state,
      city: complaint.city,
      mobileNo: complaint.mobileNo,
      plumbingNo: complaint.plumbingNo,
      brandName: complaint.brandName,
      workDone: complaint.workDone,
      repeat: complaint.repeat,
      syphoneColor: complaint.syphoneColor,
      code: complaint.code,
      problem: complaint.problem,
      product: complaint.product,
      entryRemark:
        complaint.entryRemark !== "Other" ? complaint.entryRemark : otherRemark,
      entryUserEmail: localStorage.getItem("email"),
      date: complaint.date,
      createdAt: complaint.createdAt,
      satisfactionCode: satisfactionCode,
      purchaseTime: complaint.purchaseTime,
      serviceType: complaint.serviceType,
      note: complaint.note,
    });
    if (data.success) {
      // sending whatsapp msg to particular grp
      if (isActive.whatsapp) {
        const groupName = complaint.state;
        const group = grpInfo.find((item) => item.name === groupName);
        if (group) {
          handleWhatsappMsg(group.id, data?.complaintId);
        } else {
          alert(
            `Group ${groupName} not found in whatsapp. complaint has successfully submitted but message has not been sent!`
          );
        }
      }

      handleTodaysTotalComplaint();
      alert("Complaint Submitted successfully");

      if (isActive.sms) {
        let msg1 = `Dear Customer , Your complaint no: ${data?.complaintId} has been successfully registered regards Team ideal international`;

        let msg2 = `Dear Customer ,Satisfaction Code is ${satisfactionCode} please share it with the technician only if you are satisfied with tha job done regards Team ideal international`;

        await sendSMS("1307167974258776466", complaint.mobileNo, msg1);
        await sendSMS("1307167974261955698", complaint.mobileNo, msg2);
      }

      setComplaint(defaultComplaint);
    } else {
      alert(data.errors[0].msg);
    }
  };

  const handleDate = (date) => {
    let createAtDate = moment(date)
      .tz("Asia/Kolkata")
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    let normalDate = moment(date).utcOffset("+05:30").format("DD-MM-YYYY");
    setDateX(moment(date).tz("Asia/Kolkata").format("YYYY-MM-DD"));
    setComplaint({
      ...complaint,
      date: normalDate,
      createdAt: createAtDate,
    });
  };

  const onChange = (e) => {
    if (e.target.name === "date") {
      handleDate(e.target.value);
    } else {
      if (e.target.name === "pincode") {
        handlePincode(e);
      }
      if (e.target.name === "mobileNo") {
        searchByMobileNo(e);
      }
      setComplaint({ ...complaint, [e.target.name]: e.target.value });
    }
  };

  const handlePincode = async (e) => {
    if (e.target.value.length === 6) {
      let no = pincodeQuery.search(e.target.value);
      complaint.state = no[0]?.state;
      complaint.city = no[0]?.city;
    }
  };

  const fetchProducts = async () => {
    const data = await fetchAllProducts();
    setProducts(data);
  };

  const fetchRemarks = async () => {
    const data = await fetchAllRemarks();
    setRemarks(data);
  };

  const searchByMobileNo = async (e) => {
    if (e.target.value.length === 10) {
      const data = await searchByMobileNoEntry(e.target.value);
      props.setSearchResultOfMobileNo(data);

      const totalCounts = await fetchTotalComplaintsByMobile(e.target.value);
      setTotalMobileNoCount(totalCounts.totalComplaints);

      if (data.length > 0) {
        setComplaint({
          partyName: data[0].partyName,
          brandName: data[0].brandName,
          state: data[0].state,
          city: data[0].city,
          pincode: data[0].pincode,
          mobileNo: data[0].mobileNo,
          address: data[0].address,
          plumbingNo: data[0].plumbingNo,
          syphoneColor: data[0].syphoneColor,
          repeat: data[0].repeat,
          code: data[0].code,
          entryRemark: data[0].entryRemark,
          problem: data[0].problem,
          product: data[0].product,
          serviceType: data[0].serviceType,
          purchaseTime: data[0].purchaseTime,
          note: data[0].note,
        });
      }
    }
  };

  const fetchGrpDetails = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var urlencoded = new URLSearchParams();
    urlencoded.append("token", "sopq0skd7jj8c233");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://api.ultramsg.com/instance42164/groups?" + urlencoded,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setGrpInfo([]);
        const groupList = JSON.parse(result);
        groupList.forEach((group) => {
          setGrpInfo((prev) => [...prev, { id: group.id, name: group.name }]);
        });
      })
      .catch((error) => console.log("error", error));
  };

  const handleWhatsappMsg = async (grpId, complaintId) => {
    let msg = `*Complaint no.* : ${complaintId}\n\n*Customer name* : ${
      complaint.partyName
    }\n\n*Mobile No.* : ${complaint.mobileNo} (${
      totalMobileNoCount + 1
    })\n\n*Pincode* : ${complaint.pincode}  *City* : ${
      complaint.city
    }  *State* : ${complaint.state}\n\n*Address* : ${
      complaint.address
    }\n\n*Repeat* : ${complaint.repeat}  *Total products* : ${
      complaint.problem
    }  *Code* : ${complaint.code}\n\n*Purchase time* : ${
      complaint.purchaseTime
    }\n\n*Brand name* : *${complaint.brandName}*\n\n*Product* : *${
      complaint.product
    }*\n\n*Service type* : ${complaint.serviceType}\n\n*Customer problem* : ${
      complaint.entryRemark
    }\n\n*Note* : *${complaint.note}*\n\n*Technician work details* :`;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("token", "sopq0skd7jj8c233");
    urlencoded.append("to", grpId);
    urlencoded.append("body", msg);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://api.ultramsg.com/instance42164/messages/chat",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/signin");
    }
    const storedValue = localStorage.getItem("toggleState");
    if (storedValue !== null) {
      setIsActive(JSON.parse(localStorage.getItem("toggleState")));
    }
    fetchGrpDetails();
    handleTotalComplaint();
    handleTodaysTotalComplaint();
    handleOfficeNo();
    fetchProducts();
    fetchRemarks();
    handleTodayCloseComplaint();
  }, []);

  useEffect(() => {
    getComplaintsCount();
  }, [totalComplaints]);

  useEffect(() => {
    handleTotalComplaint();
  }, [props.month, props.year]);

  let headers = [
    { label: "NO", key: "srNo" },
    { label: "COMPLAIT NO", key: "complaintId" },
    { label: "DATE", key: "date" },
    { label: "CUSTOMER NAME", key: "partyName" },
    { label: "ADDRESS", key: "address" },
    { label: "PINCODE", key: "pincode" },
    { label: "STATE", key: "state" },
    { label: "CITY", key: "city" },
    { label: "MOBILE NO", key: "mobileNo" },
    { label: "Office No", key: "plumbingNo" },
    { label: "BRAND NAME", key: "brandName" },
    { label: "ENTRY REMARK", key: "entryRemark" },
    { label: "FORWARDING REMARK", key: "remark" },
    { label: "REPEAT", key: "repeat" },
    { label: "TOTAL PRODUCTS", key: "problem" },
    { label: "TEHCHNICIAN WORK", key: "solution" },
    { label: "PART PENDING DETAILS", key: "partPendingDetails" },
    { label: "CODE", key: "code" },
    { label: "NOTE", key: "note" },
    { label: "COURIER", key: "courier" },
    { label: "SYPHONE COLOR", key: "syphoneColor" },
  ];

  let selectClass =
    "form-select form-select-lg mb-3 appearance-none block w-full px-4 py-2 text-base font-normal text-gray-700 bg-gray-100 bg-opacity-50  bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:border-[#717984] focus:bg-white focus:ring-2 focus:ring-[#717984]";

  let inputClass =
    "w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-[#717984] focus:bg-white focus:ring-2 focus:ring-[#717984] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out";

  return (
    <div>
      <section className="text-gray-600 min-h-screen body-font relative">
        <div className="container flex px-5 py-12 mx-auto">
          <div className="w-9/12">
            <h1 className="sm:text-3xl flex justify-center mx-auto md:w-2/3 mb-10 text-2xl font-medium title-font  text-gray-900">
              Enter Complaints
            </h1>
            <div className="sm:text-xl flex justify-start items-center mx-auto md:w-2/3 mb-10 text-2xl font-medium title-font  text-gray-900 ">
              <span className="mr-3 text-base">
                Tick green for sending message
              </span>
              <div className="space-x-3">
                <MessageOutlined
                  onClick={() => handleToggle("sms")}
                  className={`cursor-pointer text-2xl ${
                    isActive.sms ? "text-green-600" : "text-red-600"
                  }`}
                />
                <WhatsAppOutlined
                  onClick={() => handleToggle("whatsapp")}
                  className={`cursor-pointer text-2xl ${
                    isActive.whatsapp ? "text-green-600" : "text-red-600"
                  }`}
                  name="whatsapp"
                />
              </div>
            </div>

            <form onSubmit={handleSubmit} className=" mx-auto md:w-2/3  ">
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="partyname"
                      className="leading-7 text-base text-gray-600"
                    >
                      Customer Name
                    </label>
                    <input
                      type="text"
                      onChange={onChange}
                      value={complaint.partyName}
                      name="partyName"
                      className={`${inputClass}`}
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="brandName"
                      className="leading-7 text-base text-gray-600"
                    >
                      Brand Name<span className="text-sm">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={onChange}
                      value={complaint.brandName}
                      required
                      id="brandName"
                      name="brandName"
                      className={`${inputClass}`}
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="pincode"
                      className="leading-7 text-base text-gray-600"
                    >
                      Pincode<span className="text-sm">*</span>
                    </label>
                    <input
                      type="number"
                      required
                      onChange={onChange}
                      value={complaint.pincode}
                      name="pincode"
                      className={`${inputClass}`}
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="mobileNo"
                      className="leading-7 text-base text-gray-600"
                    >
                      Mobile No<span className="text-sm">*</span>
                    </label>
                    {totalMobileNoCount > 0 && (
                      <span> | Count :- {totalMobileNoCount}</span>
                    )}
                    <input
                      type="number"
                      required
                      value={complaint.mobileNo}
                      onChange={onChange}
                      name="mobileNo"
                      className={`${inputClass}`}
                    />
                  </div>
                </div>
                {/* dropdown */}
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="state"
                      className="leading-7 text-base text-gray-600"
                    >
                      State<span className="text-sm">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={onChange}
                      required
                      id="state"
                      name="state"
                      value={complaint.state}
                      className={`${inputClass}`}
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="city"
                      className="leading-7 text-base text-gray-600"
                    >
                      City<span className="text-sm">*</span>
                    </label>
                    <input
                      type="text"
                      onChange={onChange}
                      required
                      id="city"
                      value={complaint.city}
                      name="city"
                      className={`${inputClass}`}
                    />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div className="relative">
                    <label
                      htmlFor="address"
                      className="leading-7 text-base text-gray-600"
                    >
                      Address<span className="text-sm">*</span>
                    </label>
                    <textarea
                      name="address"
                      onChange={onChange}
                      value={complaint.address}
                      required
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-[#717984] focus:bg-white focus:ring-2 focus:ring-[#717984] h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    ></textarea>
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="plumbingNo"
                      className="leading-7 text-base text-gray-600"
                    >
                      Office No
                    </label>

                    <select
                      name="plumbingNo"
                      onChange={onChange}
                      value={complaint.plumbingNo}
                      className={`${selectClass}`}
                      aria-label=".form-select-lg example"
                    >
                      <option className="p-2 text-xl">Select Office No.</option>
                      {officeNo.map((item) => (
                        <option className="p-2 text-xl">{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="syphoneColor"
                      className="leading-7 text-base text-gray-600"
                    >
                      Syphone Color
                    </label>
                    <input
                      type="text"
                      onChange={onChange}
                      value={complaint.syphoneColor}
                      name="syphoneColor"
                      className={`${inputClass}`}
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <label
                    htmlFor="repeat"
                    className="leading-7 text-base text-gray-600"
                  >
                    Repeat<span className="text-sm">*</span>
                  </label>
                  <select
                    name="repeat"
                    onChange={onChange}
                    value={complaint.repeat}
                    required
                    className={`${selectClass}`}
                    aria-label=".form-select-lg example"
                  >
                    <option className="p-2 text-xl">No</option>
                    <option className="p-2 text-xl">Yes</option>
                  </select>
                </div>
                <div className="p-2 w-1/2 ">
                  <div className="relative">
                    <label
                      htmlFor="code"
                      className="leading-7 text-base text-gray-600"
                    >
                      Code
                    </label>
                    <input
                      type="text"
                      onChange={onChange}
                      value={complaint.code}
                      name="code"
                      className={`${inputClass}`}
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="entryRemark"
                      className="leading-7 text-base text-gray-600"
                    >
                      Remark<span className="text-sm">*</span>
                    </label>

                    <select
                      name="entryRemark"
                      onChange={onChange}
                      value={complaint.entryRemark}
                      required
                      className={`${selectClass}`}
                      aria-label=".form-select-lg example"
                    >
                      <option className="p-2 text-xl">Select Remark</option>
                      {remarks.map((item) => (
                        <option key={item._id} className="p-2 text-xl">
                          {item.remark}
                        </option>
                      ))}
                      <option className="p-2 text-xl">Other</option>
                    </select>
                  </div>
                </div>

                {complaint.entryRemark === "Other" && (
                  <div className="p-2 w-1/2 ">
                    <div className="relative">
                      <label
                        htmlFor="entryRemark1"
                        className="leading-7 text-base text-gray-600"
                      >
                        Remark<span className="text-sm">*</span>
                      </label>
                      <input
                        name="entryRemark1"
                        type="text"
                        onChange={(e) => setOtherRemark(e.target.value)}
                        required
                        className={`${inputClass}`}
                      />
                    </div>
                  </div>
                )}

                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="plumbingNo"
                      className="leading-7 text-base text-gray-600"
                    >
                      Products<span className="text-sm">*</span>
                    </label>

                    <select
                      name="product"
                      onChange={onChange}
                      value={complaint.product}
                      required
                      className={`${selectClass}`}
                      aria-label=".form-select-lg example"
                    >
                      <option className="p-2 text-xl">Select Product</option>
                      {products.map((item) => (
                        <option key={item._id} className="p-2 text-xl">
                          {item.product}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="purchaseTime"
                      className="leading-7 text-base text-gray-600"
                    >
                      Purchase Time
                    </label>

                    <input
                      name="purchaseTime"
                      onChange={onChange}
                      value={complaint.purchaseTime}
                      className={`${inputClass}`}
                      aria-label=".form-select-lg example"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="serviceType"
                      className="leading-7 text-base text-gray-600"
                    >
                      Service Type
                    </label>

                    <input
                      name="serviceType"
                      onChange={onChange}
                      value={complaint.serviceType}
                      className={`${inputClass}`}
                      aria-label=".form-select-lg example"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="note"
                      className="leading-7 text-base text-gray-600"
                    >
                      Note
                    </label>

                    <input
                      name="note"
                      onChange={onChange}
                      value={complaint.note}
                      className={`${inputClass}`}
                      aria-label=".form-select-lg example"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="problem"
                      className="leading-7 text-base text-gray-600"
                    >
                      Total Products
                    </label>
                    <input
                      name="problem"
                      onChange={onChange}
                      value={complaint.problem}
                      className={`${inputClass}`}
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2 ">
                  <div className="relative">
                    <label
                      htmlFor="date"
                      className="leading-7 text-base text-gray-600"
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      onChange={onChange}
                      value={dateX}
                      name="date"
                      className={`${inputClass}`}
                    />
                  </div>
                </div>
                <div className="p-2 mt-5 w-full">
                  <button className="flex mx-auto text-white bg-[#717984] border-0 py-2 px-8 focus:outline-none hover:bg-[#717984] rounded text-lg">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>

          {/* complaints details */}
          <div className="w-3/12 ">
            <div className="relative overflow-x-auto w-72 text-white shadow-md sm:rounded-lg">
              <table className=" table-fixed w-full">
                <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="pl-3 py-3 ">
                      Complaints
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Count
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Export
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                    <th
                      scope="row"
                      className="px-6 py-4  font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      Total
                    </th>
                    <td className=" text-black ml-10 dark:text-white px-6 py-3 text-right">
                      {totalComplaintsCount}
                    </td>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      <button
                        onClick={() => {
                          setTotalComplaints(addSerialNo(totalComplaints));
                        }}
                      >
                        <CSVLink data={totalComplaints} headers={headers}>
                          <BsDownload />
                        </CSVLink>
                      </button>
                    </td>
                  </tr>
                  <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      Today's
                    </th>
                    <td className="px-6 py-3 ml-10 text-black dark:text-white text-right ">
                      {todaysTotalComplaintsCount}
                    </td>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      <button
                        onClick={() => {
                          setTodaysTotalComplaints(
                            addSerialNo(todaysTotalComplaints)
                          );
                        }}
                      >
                        <CSVLink data={todaysTotalComplaints} headers={headers}>
                          <BsDownload />
                        </CSVLink>
                      </button>
                    </td>
                  </tr>
                  <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      Today's Close
                    </th>
                    <td className="px-6 py-3 ml-10 text-black dark:text-white text-right ">
                      {todaysCloseComplaintsCount}
                    </td>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      <button
                        onClick={() => {
                          setTodaysTotalComplaints(
                            addSerialNo(todaysCloseComplaints)
                          );
                        }}
                      >
                        <CSVLink data={todaysCloseComplaints} headers={headers}>
                          <BsDownload />
                        </CSVLink>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="relative mt-7 overflow-x-auto w-72 text-white shadow-md sm:rounded-lg">
              <table className=" table-fixed w-full">
                <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Count
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Export
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      Open
                    </th>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      {pending}
                    </td>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      <button
                        onClick={() => {
                          setPartPendingComplaints(
                            addSerialNo(pendingComplaints)
                          );
                        }}
                      >
                        <CSVLink
                          data={pendingComplaints}
                          headers={headerPending}
                        >
                          <BsDownload />
                        </CSVLink>
                      </button>
                    </td>
                  </tr>
                  <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      Ceramic Issue
                    </th>
                    <td className="px-6 py-3 text-black dark:text-white text-right ">
                      {ceramicIssue}
                    </td>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      <button
                        onClick={() => {
                          setCeramicIssueComplaints(
                            addSerialNo(ceramicIssueComplaints)
                          );
                        }}
                      >
                        <CSVLink
                          data={ceramicIssueComplaints}
                          headers={headers}
                        >
                          <BsDownload />
                        </CSVLink>
                      </button>
                    </td>
                  </tr>
                  <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      Other Issue
                    </th>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      {otherIssue}
                    </td>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      <button
                        onClick={() => {
                          setOtherIssueComplaints(
                            addSerialNo(otherIssueComplaints)
                          );
                        }}
                      >
                        <CSVLink data={otherIssueComplaints} headers={headers}>
                          <BsDownload />
                        </CSVLink>
                      </button>
                    </td>
                  </tr>
                  <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      Part Pending
                    </th>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      {partPending}
                    </td>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      <button
                        onClick={() => {
                          setPartPendingComplaints(
                            addSerialNo(partPendingComplaints)
                          );
                        }}
                      >
                        <CSVLink data={partPendingComplaints} headers={headers}>
                          <BsDownload />
                        </CSVLink>
                      </button>
                    </td>
                  </tr>
                  <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      Seat Change
                    </th>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      {seatChange}
                    </td>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      <button
                        onClick={() => {
                          setSeatChangeComplaints(
                            addSerialNo(seatChangeComplaints)
                          );
                        }}
                      >
                        <CSVLink data={seatChangeComplaints} headers={headers}>
                          <BsDownload />
                        </CSVLink>
                      </button>
                    </td>
                  </tr>
                  <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      Closed
                    </th>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      {closed}
                    </td>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      <button
                        onClick={() => {
                          setClosedComplaints(addSerialNo(closedComplaints));
                        }}
                      >
                        <CSVLink data={closedComplaints} headers={headers}>
                          <BsDownload />
                        </CSVLink>
                      </button>
                    </td>
                  </tr>

                  <tr className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      Canceled
                    </th>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      {cancel}
                    </td>
                    <td className="px-6 py-3 text-black dark:text-white text-right">
                      <button
                        onClick={() => {
                          setCancelComplaints(addSerialNo(cancelComplaints));
                        }}
                      >
                        <CSVLink data={cancelComplaints} headers={headers}>
                          <BsDownload />
                        </CSVLink>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ComplaintForm;
