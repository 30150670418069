import { Line } from "react-chartjs-2";
import "chart.js/auto";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import FetchList from "../FetchList";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getRandomLightColors,
  listOfTypesOfComplaints,
} from "../Helper/Helper";

const LineChart = (props) => {
  const {
    districtList,
    totalComplaintList,
    pendingComplaintList,
    closeComplaintList,
  } = props;
  const classes = props.className;

  const [smooth, setSmooth] = useState(true);
  const [labels, setLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    setLabels(districtList);
    setChartData(totalComplaintList);
  }, [isLoading]);

  const chartDataHandler = (value) => {
    if (value === "total") setChartData(totalComplaintList);
    else if (value === "pending") setChartData(pendingComplaintList);
    else if (value === "close") setChartData(closeComplaintList);
  };

  const options = {
    plugins: {
      filler: {
        propagate: false,
      },
      title: {
        display: true,
        text: (ctx) => "Fill: " + ctx.chart.data.datasets[0].fill,
      },
    },
    interaction: {
      intersect: false,
    },
    elements: {
      line: {
        tension: smooth ? 0.4 : 0,
      },
    },
    plugins: {
      title: {
        display: true,
        text: (ctx) => "Point Style: " + ctx.chart.data.datasets[0].pointStyle,
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "No. of Complaints",
        data: chartData,
        borderColor: getRandomLightColors(1),
        fill: "start",
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };

  if (isLoading) {
    return (
      <div className="text-center mt-10">
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    );
  }

  return (
    <div className={classes}>
      <div className="flex justify-end gap-x-2 p-1">
        <Button
          onClick={() => setSmooth((prevState) => !prevState)}
          className="text-blue-900 bg-blue-50 border-none font-semibold px-5"
        >
          {smooth ? "Linear" : "Smooth"}
        </Button>
        <div className="w-36">
          <FetchList
            list={listOfTypesOfComplaints}
            placeholder="status"
            OnChangeHandler={chartDataHandler}
          />
        </div>
      </div>
      {/*60%*/}
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
