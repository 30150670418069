import member from "./member";

export const fetchAllRemarks = async () => {
  try {
    const { data } = await member(`/remark/fetchAllRemarks`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const deleteRemark = async (remarkId) => {
  try {
    const { data } = await member.delete(`/remark/deleteRemark/${remarkId}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
