import React, { useState, useEffect } from "react";
import { Table, Button, DatePicker } from "antd";
import {
  fetchTopComplaints,
  fetchTopComplaintsBrandWise,
} from "../../api/complaint";
import DataTableModal from "../../GlobalComponents/DataTableModal";

const { RangePicker } = DatePicker;
const TopComplaints = () => {
  const [dataSource, setDataSource] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const columns = [
    {
      title: "Mobile No.",
      dataIndex: "mobileNo",
      key: "mobileNo",
    },
    {
      title: "Pending",
      dataIndex: "pending",
      key: "pending",
      sorter: (a, b) => a.pending - b.pending,
      sortOrder: sortedInfo.columnKey === "pending" && sortedInfo.order,
    },
    {
      title: "Close",
      dataIndex: "close",
      key: "close",
      sorter: (a, b) => a.close - b.close,
      sortOrder: sortedInfo.columnKey === "close" && sortedInfo.order,
    },
    {
      title: "Cancel",
      dataIndex: "cancel",
      key: "cancel",
      sorter: (a, b) => a.cancel.localeCompare(b.cancel),
      sortOrder: sortedInfo.columnKey === "cancel" && sortedInfo.order,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      sorter: (a, b) => a.total.localeCompare(b.total),
      sortOrder: sortedInfo.columnKey === "total" && sortedInfo.order,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            showModal(record);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const showModal = (record) => {
    setSelectedComplaint(record);
    setVisible(true);
  };

  const handleCancel = () => {
    setSelectedComplaint(null);
    setVisible(false);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
  };

  useEffect(() => {
    const fetchTop50Complaints = async () => {
      try {
        const data = await fetchTopComplaints();
        setDataSource(data);
      } catch (error) {
        console.error("Error fetching top complaints:", error);
      }
    };

    fetchTop50Complaints();
  }, []);

  useEffect(() => {
    const handleSearch = async () => {
      try {
        // Make API call to fetch data based on selected date range and brand name
        const data = await fetchTopComplaintsBrandWise({
          dateRange,
        });
        setDataSource(data);
      } catch (error) {
        console.error("Error fetching top complaints:", error);
      }
    };
    // Fetch data initially
    handleSearch();
  }, [dateRange, selectedBrand]); // Fetch data when date range or brand name changes

  return (
    <div className="w-[76vw] p-5 nunito-font rounded-md h-full bg-[#F1F5F9]">
      <div className="mt-5">
        <RangePicker onChange={handleDateChange} />
        <div className="mt-3"></div>
        <Table
          dataSource={dataSource}
          columns={columns}
          onChange={handleChange}
        />
        <DataTableModal
          title={`View Complaints for Mobile No. ${
            selectedComplaint?.mobileNo || ""
          }`}
          visible={visible}
          onCancel={handleCancel}
          dataSource={selectedComplaint?.complaints || []}
        />
      </div>
    </div>
  );
};

export default TopComplaints;
