import React, { useState, useEffect } from "react";
import { Table, Select, Button, Menu, Dropdown, notification } from "antd";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import {
  fetchAllTechnician,
  fetchCitiesAsPerState,
  fetchMember,
} from "../api/TechnicianApi";
import TechnicianViewModal from "../components/TechnicianViewModal";
import member from "../api/member";
import { DownloadOutlined } from "@ant-design/icons";

const stateList = [
  "ANDHRA PRADESH",
  "ARUNACHAL PRADESH",
  "ASSAM",
  "BIHAR",
  "CHATTISGARH",
  "CHANDIGARH",
  "DELHI",
  "GOA",
  "GUJARAT",
  "HARYANA",
  "HIMACHAL PRADESH",
  "JAMMU & KASHMIR",
  "JHARKHAND",
  "KARNATAKA",
  "KERALA",
  "MADHYA PRADESH",
  "MAHARASHTRA",
  "MANIPUR",
  "MEGHALAYA",
  "MIZORAM",
  "ODISHA",
  "PUNJAB",
  "PONDICHERRY",
  "RAJASTHAN",
  "SIKKIM",
  "TAMIL NADU",
  "TELANGANA",
  "TRIPURA",
  "UTTARAKHAND",
  "UTTAR PRADESH",
  "WEST BENGAL",
  "ANDAMAN & NICOBAR ISLANDS",
];

const TechnicianDetails = () => {
  const [technicians, setTechnicians] = useState([]);
  const [filteredTechnicians, setFilteredTechnicians] = useState([]);
  const [selectedAddedBy, setSelectedAddedBy] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [memberList, setMemberList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedTechnician, setSelectedTechnician] = useState(null); // New state for selected technician
  const [modalVisible, setModalVisible] = useState(false);
  const [transactions, setTransactions] = useState();

  useEffect(() => {
    fetchTechnicians();
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    const members = await fetchMember();
    const memberNames = members.map((member) => member.name); // Extract only the names
    setMemberList(memberNames);
  };
  const fetchTechnicians = async () => {
    const fetchedTechnicians = await fetchAllTechnician();
    setTechnicians(fetchedTechnicians);
    setFilteredTechnicians(fetchedTechnicians);
  };

  const fetchCityList = async (state) => {
    const formattedState = state
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    const cities = await fetchCitiesAsPerState(formattedState);
    setCityList(cities);
    setSelectedCity(null);
  };

  const handleAddedByChange = (value) => {
    setSelectedAddedBy(value);
    filterTechnicians(value, selectedState, selectedCity, dateRange);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    fetchCityList(value);
    filterTechnicians(selectedAddedBy, value, null, dateRange);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    filterTechnicians(selectedAddedBy, selectedState, value, dateRange);
  };

  const handleDateRangeChange = (newValue) => {
    setDateRange(newValue);
    filterTechnicians(selectedAddedBy, selectedState, selectedCity, newValue);
  };

  const clearFilters = () => {
    setSelectedAddedBy(null);
    setSelectedState(null);
    setCityList([]);
    setSelectedCity(null);
    setDateRange([null, null]);
    setFilteredTechnicians(technicians); // Reset to all technicians
  };

  const filterTechnicians = (addedBy, state, city, dateRange) => {
    let filtered = technicians;

    if (addedBy) {
      filtered = filtered.filter(
        (tech) =>
          tech.addedby &&
          tech.addedby.name?.toLowerCase() === addedBy.toLowerCase()
      );
    }
    if (state) {
      filtered = filtered.filter(
        (tech) => tech.state?.toLowerCase() === state.toLowerCase()
      );
    }
    if (city) {
      filtered = filtered.filter(
        (tech) => tech.city?.toLowerCase() === city.toLowerCase()
      );
    }
    if (dateRange && dateRange[0] && dateRange[1]) {
      const [start, end] = dateRange;
      filtered = filtered.filter((tech) => {
        const createdDate = moment(tech.date, "DD-MM-YYYY");
        return createdDate.isBetween(start, end, undefined, "[]");
      });
    }

    setFilteredTechnicians(filtered);
  };

  const handleView = async (technician) => {
    setSelectedTechnician(technician);
    fetchDetails(technician._id);
    setModalVisible(true);

    // console.log("selected handle view", technician);
  };
  const isClearButtonActive = !!(
    selectedState ||
    selectedCity ||
    selectedAddedBy ||
    dateRange[0] ||
    dateRange[1]
  );

  const fetchDetails = async (technicianId) => {
    // console.log("fatch details", technicianId);
    if (technicianId) {
      try {
        const transactionsResponse = await member.get(
          `/techniciantransection/getTransectionByName/${technicianId}`
        );

        // Access the `transections` array from the response data
        const transactionsData = Array.isArray(transactionsResponse.data)
          ? transactionsResponse.data
          : [transactionsResponse.data];

        setTransactions(transactionsData);
        // console.log("transactionsData", transactionsData);
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    }
  };

  const handleDownloadPDF = () => {
    if (filteredTechnicians.length > 0) {
      const doc = new jsPDF();

      doc.setFontSize(12);

      let reportTitle = "";

      const startDate =
        dateRange && dateRange[0] ? dateRange[0].format("DD-MM-YYYY") : null;
      const endDate =
        dateRange && dateRange[1] ? dateRange[1].format("DD-MM-YYYY") : null;

      // Condition: All filters applied (State, City, AddedBy, Date Range)
      if (selectedState && selectedCity && selectedAddedBy && dateRange) {
        // First line: AddedBy, State, Date Range
        const firstLine = `Report for ${selectedAddedBy}, State: ${selectedState}`;
        const dateRangeText = `As per date range:${startDate} to ${endDate}`;

        // Second line: City, State
        const secondLine = `City: ${selectedCity}, State: ${selectedState}`;

        // Add the first line to the PDF
        doc.text(firstLine, 10, 10);
        doc.text(dateRangeText, 10, 20); // Position it below the first line

        // Add the second line to the PDF
        doc.text(secondLine, 10, 30);
      }
      // Condition: State, City, and AddedBy filters applied
      else if (selectedState && selectedCity && selectedAddedBy) {
        const firstLine = `Technician Details for ${selectedAddedBy}, State: ${selectedState}`;
        const secondLine = `City: ${selectedCity}`;
        doc.text(firstLine, 10, 10);
        doc.text(secondLine, 10, 20);
      } else if (selectedState && selectedCity && dateRange) {
        const firstLine = `Technician Details for State: ${selectedState}, City: ${selectedCity}`;
        const secondLine = `As per date range:${startDate} to ${endDate}`;
        doc.text(firstLine, 10, 10);
        doc.text(secondLine, 10, 20);
      } else if (selectedState && selectedAddedBy && dateRange) {
        const firstLine = `Technician Details for  ${selectedAddedBy},State: ${selectedState}`;
        const secondLine = `As per date range:${startDate} to ${endDate}`;
        doc.text(firstLine, 10, 10);
        doc.text(secondLine, 10, 20);
      }
      // Condition: State and City filters applied
      else if (selectedState && selectedCity) {
        reportTitle += `Technician Details for State: ${selectedState}, City: ${selectedCity}`;
      }
      // Condition: State and AddedBy filters applied
      else if (selectedState && selectedAddedBy) {
        reportTitle += `Technician Details for ${selectedAddedBy}, State: ${selectedState}`;
      }
      // Condition: City and AddedBy filters applied
      else if (selectedCity && selectedAddedBy) {
        reportTitle += `Technician Details for ${selectedAddedBy}, City: ${selectedCity}`;
      }
      // Condition: State and Date Range filters applied
      else if (selectedState && dateRange) {
        reportTitle += `Technician Details for State: ${selectedState} as per date range:  ${startDate} to ${endDate}`;
      }
      // Condition: City and Date Range filters applied
      else if (selectedCity && dateRange) {
        reportTitle += `Technician Details for City: ${selectedCity} as per date range: ${startDate} to ${endDate}`;
      }
      // Condition: AddedBy and Date Range filters applied
      else if (selectedAddedBy && dateRange) {
        reportTitle += `Technician Details for ${selectedAddedBy} as per date range:  $${startDate} to ${endDate}`;
      }
      // Condition: Only State filter applied
      else if (selectedState) {
        reportTitle += `Technician Details for State: ${selectedState}`;
      }
      // Condition: Only City filter applied
      else if (selectedCity) {
        reportTitle += `Technician Details for City: ${selectedCity}`;
      }
      // Condition: Only AddedBy filter applied
      else if (selectedAddedBy) {
        reportTitle += `Technician Details for ${selectedAddedBy}`;
      }
      // Condition: Only Date Range filter applied
      else if (dateRange) {
        console.log(startDate, endDate);
        reportTitle += `Technician Details as per date range: ${startDate} to ${endDate}`;
      }

      // Add the final title to the PDF document
      doc.text(reportTitle, 10, 10);

      doc.autoTable({
        head: [
          [
            "Sr.No",
            "Technician Name",
            "Mobile No.",
            "State",
            "City",
            "Added By",
            "Created Date",
            "modified",
          ],
        ],
        body: filteredTechnicians.map((tech, index) => [
          index + 1,
          tech.technicianName,
          tech.mobileNo,
          tech.state,
          tech.city,
          tech.addedby.name, // Include the name from the addedBy field
          new Date(tech.createdAt).toLocaleDateString(),
          new Date(tech.modified).toLocaleDateString(),
        ]),
        margin: { top: 35 },
        styles: {
          fontSize: 10,
          cellPadding: 2,
          headHeight: 10,
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
        },
        columnStyles: {
          0: { halign: "center", valign: "center", cellWidth: 12 },
          1: {
            halign: "center",
            valign: "center",
            cellWidth: 23,
            cellWidth: "wrap",
          },
          2: {
            halign: "center",
            valign: "center",
            cellWidth: 25,
            cellWidth: "wrap",
          },
          3: {
            halign: "center",
            valign: "center",
            cellWidth: 30,
            cellWidth: "wrap",
          },
          4: {
            halign: "center",
            valign: "center",
            cellWidth: 30,
            cellWidth: "wrap",
          },
          5: {
            halign: "left",
            valign: "center",
            cellWidth: 30,
            cellWidth: "wrap",
          },
          6: {
            halign: "center",
            valign: "center",
            cellWidth: 30,
            cellWidth: "wrap",
          },
          7: {
            halign: "center",
            valign: "center",
            cellWidth: 30,
            cellWidth: "wrap",
          },
        },
      });

      doc.save(`technicians_${moment().format("DDMMYYYY")}.pdf`);
    } else {
      notification.warning({
        message: "No Data",
        description: "No technicians available for download.",
      });
    }
  };

  const handleDownloadCSV = () => {
    if (filteredTechnicians.length > 0) {
      const csv = Papa.unparse(
        filteredTechnicians.map(({ _id, __v, addedby, ...rest }) => ({
          ...rest,
          addedByName: addedby?.name || "N/A", // Include the addedBy name
        }))
      );
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `technicians_${moment().format("DDMMYYYY")}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      notification.warning({
        message: "No Data",
        description: "No technicians available for download.",
      });
    }
  };

  const downloadMenu = (
    <Menu>
      <Menu.Item key="pdf" onClick={handleDownloadPDF}>
        Download PDF
      </Menu.Item>
      <Menu.Item key="csv" onClick={handleDownloadCSV}>
        Download CSV
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <div style={{ marginLeft: "10px" }} className="flex">
        <Dropdown overlay={downloadMenu}>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            style={{
              marginLeft: "15px",
              backgroundColor: "rgb(22 78 99 / var(--tw-bg-opacity))",
              color: "white",
            }}
            className="mr-5"
          >
            Download
          </Button>
        </Dropdown>
        <Select
          placeholder="Select Added By"
          style={{ width: 200, marginRight: 10 }}
          onChange={handleAddedByChange}
          value={selectedAddedBy || undefined}
        >
          <Select.Option key="no-filter" value="">
            ---Select Member---
          </Select.Option>
          {/* Replace with your list of addedBy options */}
          {memberList.map((member) => (
            <Select.Option key={member} value={member}>
              {member}
            </Select.Option>
          ))}
        </Select>

        <Select
          placeholder="Select State"
          style={{ width: 200, marginRight: 10 }}
          onChange={handleStateChange}
          value={selectedState || undefined}
        >
          <Select.Option key="no-filter" value="">
            ---Select State---
          </Select.Option>
          {stateList.map((state) => (
            <Select.Option key={state} value={state}>
              {state}
            </Select.Option>
          ))}
        </Select>

        <Select
          placeholder="Select City"
          style={{ width: 200, marginRight: 10 }}
          onChange={handleCityChange}
          value={selectedCity || undefined}
          disabled={!cityList.length}
        >
          <Select.Option key="no-filter" value="">
            ---Select City---
          </Select.Option>
          {cityList.map((city) => (
            <Select.Option key={city} value={city}>
              {city}
            </Select.Option>
          ))}
        </Select>

        <div className="w-full flex  items-center ">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={dateRange}
              onChange={handleDateRangeChange}
              renderInput={(startProps, endProps) => (
                <>
                  <input
                    {...startProps.inputProps}
                    placeholder="DD-MM-YYYY"
                    className="MuiInputBase-input"
                    value={startProps.inputProps.value || ""}
                    onChange={startProps.inputProps.onChange}
                  />
                  <input
                    {...endProps.inputProps}
                    placeholder="DD-MM-YYYY"
                    className="MuiInputBase-input"
                    value={endProps.inputProps.value || ""}
                    onChange={endProps.inputProps.onChange}
                  />
                </>
              )}
              format="DD-MM-YYYY"
            />
          </LocalizationProvider>
        </div>
        <Button
          onClick={clearFilters}
          style={{
            marginLeft: 10,
            backgroundColor: isClearButtonActive
              ? "rgb(22 78 99 )"
              : "lightblue",
            color: isClearButtonActive ? "white" : "black",
          }}
          type="primary"
        >
          Clear Filters
        </Button>
      </div>

      <Table
        dataSource={filteredTechnicians}
        scroll={{
          x: "max-content",
        }}
        style={{
          marginTop: "20px",
          width: "1180px",
          marginLeft: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          overflow: "hidden",
          maxHeight: "600px",
          overflowX: "scroll",
          overflowY: "scroll",
          scrollbarWidth: "none",
        }}
        className="custom-table"
        columns={[
          {
            title: "Sr.No",
            dataIndex: "srNo",
            key: "srNo",
            align: "center",
            render: (text, record, index) => index + 1,
          },

          {
            title: "Technician Name",
            dataIndex: "technicianName",
            key: "technicianName",
            align: "center",
          },
          {
            title: "Mobile No.",
            dataIndex: "mobileNo",
            align: "center",
            key: "mobileNo",
          },
          { title: "State", dataIndex: "state", align: "center", key: "state" },
          { title: "City", dataIndex: "city", align: "center", key: "city" },
          {
            title: "Added By",
            dataIndex: ["addedby", "name"],
            key: "addedby",
            align: "center",
            render: (text, record) =>
              record.addedby ? record.addedby.name : "N/A",
          },
          {
            title: "Created Date",
            dataIndex: "date",
            key: "date",
            align: "center",
          },
          {
            title: "Actions",
            key: "actions",
            align: "center",
            render: (text, record) => (
              <Button onClick={() => handleView(record)}>View</Button>
            ),
          },
        ]}
        rowKey="_id"
      />
      {selectedTechnician && (
        <TechnicianViewModal
          visible={modalVisible}
          onClose={() => {
            setModalVisible(false);
            setTransactions(null);
          }}
          technician={selectedTechnician}
          transactions={transactions}
          technicianId={selectedTechnician._id}
        />
      )}
      <style jsx>{`
        .MuiInputBase-input {
          height: 25.5px !important;
          padding: 4px;
          width: 120px;
          text-align: center;
        }
        .custom-table .ant-table-body::-webkit-scrollbar {
          width: 0;
          height: 8px;
        }

        .custom-table .ant-table-body {
          -ms-overflow-style: none;
          scrollbar-width: none;
          overflow-y: scroll;
        }

        .ant-table-thead > tr > th {
          background-color: #fafafa;
          font-weight: bold;
          text-align: center;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .ant-table-tbody > tr:hover > td {
          background-color: #f5f5f5;
        }

        .ant-table-tbody > tr > td {
          padding: 12px 8px;
          text-align: center;
        }

        .ant-table-footer {
          background-color: #fafafa;
          font-weight: bold;
          text-align: center;
          position: sticky;
          z-index: 1;
        }
      `}</style>
    </div>
  );
};

export default TechnicianDetails;
