import React, { useState } from "react";
import ComplaintsCounter from "./ComplaintsCounter";
import ComplaintList from "./ComplaintList";
import {
  fetchRecentcomplaints,
  fetchComplaintsForStatus,
} from "../api/complaint";
import { useEffect } from "react";
import loadingImg from "../images/loading.gif";
import dotsLoading from "../images/dots-loading.gif";

const Dashboard = (props) => {
  const { month, year } = props;
  const [openCount, setOpenCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);
  const [visitOkCount, setVisitOkCount] = useState(0);
  const [canceledCount, setCanceledCount] = useState(0);
  const [repeatCount, setRepeatCount] = useState(0);
  const [otherIssueCount, setOtherIssueCount] = useState(0);
  const [allComplaints, setAllComplaints] = useState([]);
  const [todaysTotalCount, setTodaysTotalCount] = useState(0);

  const labels = [
    {
      lab: "Pending",
      color: "orange",
      count:
        openCount > 0 ? (
          openCount
        ) : (
          <img src={dotsLoading} style={{ width: 30 }} alt="Loading..." />
        ),
    },
    {
      lab: "Visit Ok",
      color: "blue",
      count:
        visitOkCount > 0 ? (
          visitOkCount
        ) : (
          <img src={dotsLoading} style={{ width: 30 }} alt="Loading..." />
        ),
    },

    {
      lab: "Other",
      color: "green",
      count:
        otherIssueCount > 0 ? (
          otherIssueCount
        ) : (
          <img src={dotsLoading} style={{ width: 30 }} alt="Loading..." />
        ),
    },
    {
      lab: "Repeat",
      color: "green",
      count:
        repeatCount > 0 ? (
          repeatCount
        ) : (
          <img src={dotsLoading} style={{ width: 30 }} alt="Loading..." />
        ),
    },
    {
      lab: "Canceled",
      color: "green",
      count:
        canceledCount > 0 ? (
          canceledCount
        ) : (
          <img src={dotsLoading} style={{ width: 30 }} alt="Loading..." />
        ),
    },
    {
      lab: "Today",
      color: "green",
      count:
        todaysTotalCount?.length > 0 ? (
          todaysTotalCount?.length
        ) : (
          <img src={dotsLoading} style={{ width: 30 }} alt="Loading..." />
        ),
    },
    {
      lab: "Total",
      color: "green",
      count:
        allComplaints.length > 0 ? (
          allComplaints.length
        ) : (
          <img src={dotsLoading} style={{ width: 30 }} alt="Loading..." />
        ),
    },
  ];

  const getAllComplaintStatus = async () => {
    const data = await fetchComplaintsForStatus(month, year);
    setAllComplaints(data.complaints);
    setTodaysTotalCount(data?.todayComplaintsCount);
  };

  const getAllComplaintsStatusCount = async () => {
    let ope = 0,
      clo = 0,
      rep = 0,
      vis = 0,
      can = 0,
      oth = 0;

    allComplaints.map((complaint) => {
      if (complaint.workDone === "Yes") {
        clo++;
        setClosedCount(clo);
      } else if (complaint.repeat === "Yes" && complaint.workDone === "No") {
        rep++;
        setRepeatCount(rep);
        ope++;
        setOpenCount(ope);
      } else if (complaint.workDone === "No") {
        ope++;
        setOpenCount(ope);
      } else if (complaint.workDone === "Visit Ok") {
        vis++;
        setVisitOkCount(vis);
        ope++;
        setOpenCount(ope);
      } else if (complaint.workDone === "Cancel") {
        can++;
        setCanceledCount(can);
      } else if (complaint.workDone === "Other Issue") {
        oth++;
        setOtherIssueCount(oth);
      }
    });
  };

  useEffect(() => {
    getAllComplaintStatus();
  }, []);

  useEffect(() => {
    setOpenCount(0);
    setVisitOkCount(0);
    setOtherIssueCount(0);
    setRepeatCount(0);
    setTodaysTotalCount(0);
    setCanceledCount(0);
    getAllComplaintStatus();
  }, [month, year]);

  useEffect(() => {
    getAllComplaintsStatusCount();
  }, [allComplaints]);

  return (
    <div className="w-full" >
      <div className="container mx-auto py-6 h-full md:w-full w-11/12 px-6" style={{height:"740px"}}>
        <div className="w-full h-full rounded">
          <h1 className="text-xl w-44 font-medium border-b-2 border-gray-300">
            General Report
          </h1>

          <div className="grid grid-cols-12 gap-5 mt-5 transition duration-150 ease-out hover:ease-in-out">
            {labels.map((label, index) => (
              <ComplaintsCounter
                key={index}
                label={label.lab}
                color={label.color}
                count={label.count}
              />
            ))}
          </div>
          <div className="flex"></div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
