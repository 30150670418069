// DataTableModal.js
import React from "react";
import { Table, Modal } from "antd";

const DataTableModal = ({ title, visible, onCancel, dataSource }) => {
  const columns = [
    {
      title: "Complaint ID",
      dataIndex: "complaintId",
      key: "complaintId",
      sorter: (a, b) => a.complaintId.localeCompare(b.complaintId),
    },
    {
      title: "Party Name",
      dataIndex: "partyName",
      key: "partyName",
      sorter: (a, b) => a.partyName.localeCompare(b.partyName),
    },
    {
      title: "Mobile No.",
      dataIndex: "mobileNo",
      key: "mobileNo",
      sorter: (a, b) => a.mobileNo - b.mobileNo,
    },
    {
      title: "Brand Name",
      dataIndex: "brandName",
      key: "brandName",
      sorter: (a, b) => a.brandName.localeCompare(b.brandName),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      sorter: (a, b) => a.state.localeCompare(b.state),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      title: "Status",
      dataIndex: "workDone",
      key: "workDone",
      sorter: (a, b) => a.workDone.localeCompare(b.workDone),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      sorter: (a, b) => a.payment.localeCompare(b.payment),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
      title: "Closing Date",
      dataIndex: "closingDate",
      key: "closingDate",
    },
  ];

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={"auto"}
      styles={{ padding: "20px", background: "#fff" }}
    >
      <Table dataSource={dataSource} columns={columns} />
    </Modal>
  );
};

export default DataTableModal;
