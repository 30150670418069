import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ViewComplaintInDetailByUser = ({ complaintInfo }) => {
  let navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/signin");
    }
  }, []);

  const infoStyle = `text-base rounded-md border border-gray-300  p-2 mt-1 bg-white w-80`;
  const detailClass = `flex flex-col`;
  const gridStyle = `grid grid-cols-1 md:grid-cols-3 gap-4 mx-auto`;

  return (
    <div className="w-full  nunito-font h-full bg-[#F1F5F9] font-bold">
      <div className="w-full p-8">
        <div className="flex justify-between">
          <h2 className="mb-10 text-xl font-semibold">Complaint Details</h2>
        </div>
        <div className={gridStyle}>
          <div className={detailClass}>
            <span className="text-sm">Complaint Id</span>
            <span className={infoStyle}>{complaintInfo.complaintId}</span>
          </div>
          {complaintInfo.partyName && (
            <div className={detailClass}>
              <span className="text-sm">Customer Name</span>
              <span className={infoStyle}>{complaintInfo.partyName}</span>
            </div>
          )}
          {complaintInfo.brandName && (
            <div className={detailClass}>
              <span className="text-sm">Brand Name</span>
              <span className={infoStyle}>{complaintInfo.brandName}</span>
            </div>
          )}

          {complaintInfo.satisfactionCode && (
            <div className={detailClass}>
              <span className="text-sm">Satisfaction Code</span>
              <span className={infoStyle}>
                {complaintInfo.satisfactionCode}
              </span>
            </div>
          )}
          {complaintInfo.state && (
            <div className={detailClass}>
              <span className="text-sm">State</span>
              <span className={infoStyle}>{complaintInfo.state}</span>
            </div>
          )}
          <div className={detailClass}>
            <span className="text-sm">City</span>
            <span className={infoStyle}>{complaintInfo.city}</span>
          </div>

          <div className={detailClass}>
            <span className="text-sm">Address</span>
            <span className={infoStyle}>{complaintInfo.address}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Pincode</span>
            <span className={infoStyle}>{complaintInfo.pincode}</span>
          </div>
          <div className={detailClass}>
            <span className="text-sm">Mobile No.</span>
            <span className={infoStyle}>{complaintInfo.mobileNo}</span>
          </div>

          <div className={detailClass}>
            <span className="text-sm">Office No.</span>
            <span className={infoStyle}>{complaintInfo.plumbingNo}</span>
          </div>
          {complaintInfo.syphoneColor && (
            <div className={detailClass}>
              <span className="text-sm">Syphone Color</span>
              <span className={infoStyle}>{complaintInfo.syphoneColor}</span>
            </div>
          )}
          {complaintInfo.code && (
            <div className={detailClass}>
              <span className="text-sm">Code</span>
              <span className={infoStyle}>{complaintInfo.code}</span>
            </div>
          )}

          {complaintInfo.workDone && (
            <div className={detailClass}>
              <span className="text-sm">Work Done</span>
              <span className={infoStyle}>{complaintInfo.workDone}</span>
            </div>
          )}
          {complaintInfo.repeat && (
            <div className={detailClass}>
              <span className="text-sm">Repeat</span>
              <span className={infoStyle}>{complaintInfo.repeat}</span>
            </div>
          )}
          {complaintInfo.entryRemark && (
            <div className={detailClass}>
              <span className="text-sm">Entry Remark</span>
              <span className={infoStyle}>{complaintInfo.entryRemark}</span>
            </div>
          )}

          {complaintInfo.remark && (
            <div className={detailClass}>
              <span className="text-sm">Forwarding Remark</span>
              <span className={infoStyle}>{complaintInfo.remark}</span>
            </div>
          )}
          {complaintInfo.product && (
            <div className={detailClass}>
              <span className="text-sm">Product</span>
              <span className={infoStyle}>{complaintInfo.product}</span>
            </div>
          )}
          {complaintInfo.problem && (
            <div className={detailClass}>
              <span className="text-sm">Total Products</span>
              <span className={infoStyle}>{complaintInfo.problem}</span>
            </div>
          )}

          {complaintInfo.solution && (
            <div className={detailClass}>
              <span className="text-sm">Technician Work</span>
              <span className={infoStyle}>{complaintInfo.solution}</span>
            </div>
          )}
          {complaintInfo?.purchaseTime && (
            <div className={detailClass}>
              <span className="text-sm">Purchase Time</span>
              <span className={infoStyle}>{complaintInfo?.purchaseTime}</span>
            </div>
          )}
          {complaintInfo?.serviceType && (
            <div className={detailClass}>
              <span className="text-sm">Service Type</span>
              <span className={infoStyle}>{complaintInfo?.serviceType}</span>
            </div>
          )}
          {complaintInfo?.note && (
            <div className={detailClass}>
              <span className="text-sm">Note</span>
              <span className={infoStyle}>{complaintInfo?.note}</span>
            </div>
          )}
          {complaintInfo.closingDate && (
            <div className={detailClass}>
              <span className="text-sm">Closing Date</span>
              <span className={infoStyle}>{complaintInfo.closingDate}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewComplaintInDetailByUser;
